import store from '@/store';
import request from '@/utils/request';
import textHelpers from '@/utils/textHelpers';
import { parsePhoneNumberFromString} from "libphonenumber-js/mobile";
import validationUtil from "@/utils/validationUtil";

const membersService = {
  async getMembersList() {
    const path = '/notification/GetMembers';
    const result = await request.get(process.env.VUE_APP_REVENUE_API_URL + path);
    store.commit('setMembersList', {
      membersList: result,
    });
    return result;
  },
  async postMember(body) {
    const path = '/auth/AddOrUpdateMember';
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    return result;
  },
  validateMemberData(member,fields) {
    const errors = [];
    const fieldsValidationResult = validationUtil.checkFieldValidity(fields);

    const { phoneNumber, email, notificationStrategy, groupId,allowPhone, allowSms, allowEmail } = member
    const mailFormatValid = textHelpers.emailValidator(email);
    var validPhone = '';
    if (phoneNumber && phoneNumber > 0){
      validPhone = parsePhoneNumberFromString(phoneNumber.toString(), "IL").isValid();
    }

    if(!fieldsValidationResult){
      errors.push('FormInvalid');
    }
    if (phoneNumber <= 0 && !email) {
      errors.push('MissingEmailAndPhone');
    }
    if (phoneNumber > 0 && !validPhone) {
      errors.push('MemberPhoneNumberInvalid');
    }
    if (email && !mailFormatValid) {
      errors.push('EmailInvalid');
    }
    if (!groupId){
      errors.push('MissingNotificationGroup');
    }
    if (!notificationStrategy) {
      errors.push('MissingNotificationStrategy');
    }
    if(!email && allowEmail) {
      errors.push('MissingEmailForNotifyEmail');
    }
    if(phoneNumber <= 0 && allowPhone || phoneNumber <= 0 && allowSms){
      errors.push('MissingPhoneForNotifyPhone');
    }

    return errors;
  },
  prepareMembersDetails(membersList, notificationGroupList, strategiesList) {
    const distinctMembersList = [];
    if (membersList) {
      membersList.forEach((element) => {
        const memberExists = distinctMembersList.find(
          (g) => g.groupId === element.groupId
        );
        const relatedNotificationGroup = notificationGroupList?.filter(
          (g) => g.groupId == element.groupId
        );
        const relatedStrategy = strategiesList?.filter(
          (g) => g.id == element.notificationStrategy
        );
        const existingNotificationGroup = relatedNotificationGroup ? relatedNotificationGroup[0] : null;
        const existingStrategy = relatedStrategy ? relatedStrategy[0] : null;
        if (memberExists === undefined) {
          distinctMembersList.push({
            memberId: element.memberId,
            memberFirstName: element.memberFirstName,
            memberEmail: element.email,
            memberPhoneNumber: element.phoneNumber,
            memberPositionName: element.positionName,
            memberComment: element.comment,
            memberGroupName: existingNotificationGroup?.groupName ?? "-",
            memberGroupId: element.groupId,
            shopsRelated: existingNotificationGroup?.shopIds ?? [],
            memberNotificationStrategy: element.notificationStrategy,
            memberNotificationStrategyName: existingStrategy?.name ?? "-",
            canUpdateMember: element.canUpdateMember
          });
        }
      });
    }
    return distinctMembersList;
  },
  canCreateMember(membersList) {
    return membersList?.some(member => member.canUpdateMember)
  },
};

export default membersService;
