import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './locales'

Vue.use(VueI18n);

class i18nInstance extends VueI18n {
  constructor() {
    super({
      locale: 'he',
      fallbackLocale: 'en',
      messages,
    });
    this.setLocale();
  }

  setLocale() {
    let storageLocale = localStorage.getItem('locale');
    let supportedLocales = ['en', 'he'];
    let browserLocaleSupported = supportedLocales.some((val) => navigator.language.includes(val));
    let validBrowserValue = browserLocaleSupported ? navigator.language.split('-')[0] : 'en';
    super.locale = storageLocale || validBrowserValue;
  }
  switchLocale(clickedValue) {
    super.locale = clickedValue;
    localStorage.setItem("locale", super.locale);
  }
  isLocale(locale){
    return super.locale  === locale;
  }
}
const i18n = new i18nInstance();
export default i18n;
