<template>
  <div class="container">
    <div class="column is-two-fifths">
      <notificationGroupsEdit
        :id="this.id"
        :canEditNotificationGroup="this.canEditNotificationGroup"
        @group-update-complete="goBack"
        @cancel-update="goBack"
      />
    </div>
  </div>
</template>
<script>
import notificationGroupsEdit from "@/components/notifications/notificationGroupsEdit.vue";
import { mapGetters } from "vuex";
import routerUtils from "@/utils/routerUtils";
export default {
  props: {
    id: Number,
    returnTo: {
      type: String,
      default: "/notificationGroup/admin",
    },
  },
  components: {
    notificationGroupsEdit,
  },
  computed: {
    ...mapGetters({
      notificationGroupList: "getNotificationGroups",
    }),
    canEditNotificationGroup() {
      let selectedGroup = this.notificationGroupList
        ? this.notificationGroupList.find((x) => x.groupId == this.id)
        : [];
      return selectedGroup?.canUpdateNotificationGroup;
    },
  },
  methods: {
    goBack() {
      routerUtils.moveTo(this.returnTo);
    },
  },
};
</script>