<template>
  <section class="section">
    <chainEdit
      @chain-update-complete="goBack"
      @chain-update-cancelled="goBack"
      :id="this.id"
    />
  </section>
</template>
<script>
import routerUtils from "@/utils/routerUtils";
import chainEdit from "@/components/chainEdit.vue";

export default {
  props: {
    id: Number,
    returnTo: {
      type: String,
      default: "/chains/admin",
    },
  },
  components: {
    chainEdit,
  },

  methods: {
    goBack() {
      routerUtils.moveTo(this.returnTo);
    },
  },
};
</script>