<template>
  <section>
    <div class="hero has-background-white is-small">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-align-items-center">
            <section
              class="column is-two-fifths revenue_search"
              v-if="fileContent == false"
            >
              <b-field :label="$t('SelectMonth')" horizontal type="is-blue">
                <b-datepicker
                  @input="datePickerChanged($event)"
                  :month-names="$t('MonthNames')"
                  v-model="selectedDate"
                  type="month"
                  :loading="this.shouldDisplayLoading"
                  :disabled="this.shouldDisplayLoading"
                  :max-date="maxRevenueDate"
                  :min-date="minRevenueDate"
                  icon-pack="fas"
                  icon="calendar-day"
                  :trap-focus="true"
                  :mobile-native="false"
                />
              </b-field>
              <b-field horizontal :label="$t('Search')" type="is-blue">
                <b-input
                  :disabled="this.shouldDisplayLoading"
                  :loading="this.shouldDisplayLoading"
                  v-model="searchText"
                />
              </b-field>
              <b-field horizontal>
                <b-checkbox
                  data-test-id="showShopsWithDataButton"
                  v-model="checkboxGroup"
                  :disabled="this.shouldDisplayLoading"
                  native-value="showShopsWithData"
                >
                  <span>{{ $t("ShowShopsWithData") }}</span>
                </b-checkbox>
              </b-field>
            </section>

            <div class="column is-one-fifth">
              <b-field>
                <b-button
                  data-test-id="submitButton"
                  expanded
                  :loading="this.shouldDisplayLoading"
                  class="is-primary submitButton has-text-red"
                  v-on:keyup.enter="sendRevenue"
                  @click="sendRevenue"
                  :disabled="submitButtonDisabled"
                  size="is-large"
                  >{{ $t("SendRevenueWithoutVat") }}</b-button
                >
              </b-field>
              <b-message size="is-small" type="is-warning" has-icon>{{
                $t("InsertRevenueWithoutVat")
              }}</b-message>
            </div>

            <div class="column is-hidden-touch is-invisible">
              <figure v-if="$t('CompaniesLogo')" class="image is-2by1">
                <img :src="$t('CompaniesLogo')" />
              </figure>
            </div>

            <div class="column is-hidden-touch revenue_controls">
              <FileInput
                @file-uploaded="fileUploaded"
                @error="fileError"
                :shopsList="this.shopList"
                :disabled="this.shouldDisplayLoading"
              />
              <FileExporter
                class="fileExporter"
                :disabled="this.shouldDisplayLoading"
                :content="this.flattenedShopList"
                :fileName="
                  'MallShopLinkSample' +
                    new Date().getDate() +
                    (new Date().getMonth() + 1) +
                    new Date().getFullYear() +
                    '.xlsx'
                "
              />
              <b-field>
                <b-button type="is-danger" @click="openHelpPage" expanded>
                  {{ $t("RevenueInputHelp") }}
                </b-button>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section v-if="fileContent == false">
      <div class="container">
        <RevenueInputDetails
          ref="revenueInputs"
          class="revenueInput"
          :value="this.shopList"
          :loading="this.shouldDisplayLoading"
          :filterPhrase="this.searchText"
          :utcSelectedDate="this.utcSelectedDate"
          :showDisabledShops="
            this.checkboxGroup.some(item => item === 'showDisabledShops')
          "
          :showShopsWithData="
            this.checkboxGroup.some(item => item === 'showShopsWithData')
          "
          @shop-list-filtered="value => (this.filteredShopList = value)"
        />
      </div>
    </section>
  </section>
</template>

<script>
import revenueService from "@/services/revenueService";
import * as Sentry from "@sentry/browser";
import FileInput from "@/components/RevenueFileInput.vue";
import FileExporter from "@/components/FileExporter";
import shopsService from "@/services/shopsService";
import RevenueInputDetails from "@/views/RevenueInputDetails.vue";
import { mapGetters } from "vuex";

export default {
  name: "ShopRevenueInput",
  components: {
    FileInput,
    FileExporter,
    RevenueInputDetails
  },
  methods: {
    async sendRevenue() {
      const inputComponent = this.$refs.revenueInputs;
      if (inputComponent) {
        const inputValidation = inputComponent.validateRevenueInput();
        if (inputValidation.isValid === false) {
          this.openErrorNotification(
            `${inputValidation.shopName} ${inputValidation.validationMessage}`
          );
          return;
        }
      }
      this.processing = true;
      let data = this.fileContent;
      if (this.fileContent == false) {
        data = {
          revenues: [],
          revenueDate: this.utcSelectedDate,
          revenueType: 2
        };
        data.revenues = revenueService.prepareRevenueData(this.shopList);
      }

      try {
        const response = await revenueService.postRevenue(data);
        this.handlePostRevenueResponse(response);
      } catch (err) {
        this.handlePostRevenueResponse(err.response, err);
      }
    },
    fileUploaded(data) {
      const revenues = data.map(d => ({
        shopId: shopsService.getShopFromCollection(this.shopList, d).shopId,
        sectionId: d.SectionId,
        revenueValue: d.RevenueInteger
      }));
      const revenueList = {
        revenueDate: new Date(Date.UTC(data[0].Year, data[0].Month - 1, 1)),
        revenueType: 2
      };
      revenueList.revenues = revenues;
      this.fileContent = revenueList;
    },
    fileError(errors) {
      errors
        .reduce((conflicts, conflictItem) => conflicts.concat(conflictItem), [])
        .forEach(error => {
          this.openErrorNotification(
            this.$t(error.failureReason, {
              relatedFailureParameter: error.relatedFailureParameter,
              identifierDescription: error.identifierDescription
            })
          );
        });
    },
    handlePostRevenueResponse(response, err) {
      if (response && (response.status < 300 || response.status === 409)) {
        const enrichedResponse = shopsService.addDetailsToShopSection(
          this.shopList,
          response.data,
          this.fileContent
        );

        this.$router.push({
          name: "submitSummary",
          params: {
            shoppingCentersList: enrichedResponse,
            date: this.utcSelectedDate
          }
        });
      } else if(err) {
        this.openErrorNotification(this.$t("ErrorContactUs") + err);
        Sentry.configureScope(scope => {
          scope.setExtra("responseData", err.response?.data ?? err.response);
          scope.setExtra("requestData", err.config?.data ?? err.config);
          Sentry.captureException(err);
        });
      } else {
        revenueService.postRevenueDone();
      }
      this.processing = false;
    },
    datePickerChanged() {
      revenueService.getRevenueIndication(
        this.utcSelectedDate.getMonth() + 1,
        this.utcSelectedDate.getFullYear()
      );
      shopsService.getAllowedShopsList(
        this.utcSelectedDate.getMonth() + 1,
        this.utcSelectedDate.getFullYear()
      );
    },
    openErrorNotification(message) {
      this.$buefy.notification.open({
        message,
        type: "is-danger",
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        "data-test-id": "shopRevenueInputErrorMessage"
      });
    },
    openHelpPage() {
      window.open("https://www.mallshoplink.com/help?lang=he", "_blank");
    }
  },
  computed: {
    submitButtonDisabled() {
      return (
        this.fileContent == false &&
        this.shopList?.every(
          shop => shop.sections.some(section => section.revenueValue?.toString()) === false
        )
      );
    },
    utcSelectedDate() {
      return new Date(
        Date.UTC(
          this.selectedDate.getFullYear(),
          this.selectedDate.getMonth(),
          this.selectedDate.getDate()
        )
      );
    },
    maxRevenueDate() {
      let now = new Date();
      now.setMonth(now.getMonth() === 0 ? 11 : now.getMonth() - 1);
      let maxDate = now;
      return maxDate;
    },
    minRevenueDate() {
      let now = new Date();
      now.setFullYear(now.getFullYear() - 2, 0, 1);
      now.setHours(0, 0, 0, 0);
      let minDate = now;
      return minDate;
    },
    shouldDisplayLoading() {
      return !Array.isArray(this.shopList) || this.processing;
    },
    flattenedShopList() {
      if (this.filteredShopList?.length > 0) {
        const flattenedList = revenueService.prepareDataForFileSample(
          this.filteredShopList,
          this.utcSelectedDate
        );
        return flattenedList;
      }
      return null;
    },
    isResponseModalActive() {
      if (this.responseShopsList) {
        return this.responseShopsList.length > 0;
      }
      return false;
    },
    ...mapGetters({
      shopList: "getAllowedShopsList",
      revenueIndication: "getRevenueIndication"
    })
  },
  mounted() {
    const session = sessionStorage.getItem("dataFromToken");
    if (session) {
      this.selectedDate = new Date(
        session.substr(0, 4),
        Number(session.substr(4, 2)) - 1
      );
    }
    sessionStorage.removeItem("dataFromToken");
    this.datePickerChanged();
  },
  data() {
    return {
      selectedDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        15
      ),
      searchText: "",
      fileContent: [],
      responseShopsList: [],
      checkboxGroup: [],
      processing: false,
      filteredShopList: null
    };
  }
};
</script>

<style lang="scss">
.hero {
  width: 100%;
}
.submitButton {
  white-space: pre-line;
  line-height: 1.2em;
}
.centeredButton {
  margin-right: 15%;
  margin-left: 25%;
  width: 100%;
}
.revenueInput {
  margin-bottom: 35px;
}
.fixed {
  position: fixed;
  top: calc(100vh - 3rem);
}
.fileExporter {
  margin-bottom: 0.6em;
}
.fileExporter .button > span:not(.icon) {
  display: flex;
}
.revenue_controls {
  .button.is-small {
    width: 100%;
  }
}
.revenue_search {
  .field.is-horizontal {
    justify-content: space-between;
    align-content: center;
    .field-label {
      margin: 0;
      text-align: inherit;
      padding-left: 0.6em;
      padding-right: 0.6em;
      flex-basis: 30%;
      flex-grow: 1;
    }
    .field-body {
      flex-basis: 70%;
      flex-grow: 1;
    }
    .b-checkbox.checkbox:not(.button) {
      margin: 0;
    }
    .b-checkbox.checkbox .control-label {
      padding: 0 0.25em;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@media screen and (min-width: 600px) {
  .half-width {
    width: 50%;
  }
}

.shopRevenueForm > * {
  width: 100%;
}

.clearfix {
  clear: both;
}
</style>
