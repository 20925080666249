export default {
    '-': '-',
    AdditionalContactDetails:'פרטי קשר',
    All: 'הכל',
    AllShopsReported: 'כל החנויות דווחו החודש',
    AppleFixAndTrain: 'מוצרי אפל תיקונים והדרכות',
    AppleSmartphones: 'סמארטפונים אפל',
    AreYouSure: 'האם את/ה בטוח/ה?',
    Attention: 'תשומת ליבך נדרשת',
    AuthGeneralError: 'הכניסה נכשלה, נא לנסות שנית. אם הבעיה נמשכת נא לפנות למשרד',
    BasicValidator: 'אימות נכשל',
    Cancel: 'ביטול',
    CanEditChainGroupValidator: 'חסרה הרשאה לעריכת קבוצת רשת',
    CanEditChainValidator: 'חסרה הרשאה לעריכת רשת',
    CanEditMemberValidator: 'חסרה הרשאה לעריכת מדווח',
    CanEditNotificationGroupValidator: 'חסרה הרשאה לעריכת קבוצת דיווח',
    CanEditRole: 'לא ניתן לערוך קבוצה זו',
    CanEditSectionValidator: 'חסרה הרשאה לעריכת קטגוריה',
    CanEditShoppingCenterValidator: 'חסרה הרשאה לעריכת המרכז המסחרי שנבחר',
    CanEditShopValidator: 'חסרה הרשאה לעריכת החנות שנבחרה',
    CannotOverwrite: 'לא ניתן לעדכן פדיון לחנות {identifierDescription} מאחר והוזן לפני יותר מ{relatedFailureParameter} ימים',
    CannotRemoveGeneralSection: 'לא ניתן להסיר את קבוצת הפדיון "כללי"',
    Cellular: 'סלולרי',
    Chain: 'רשת',
    ChainExternalId: 'מזהה רשת חיצוני',
    ChainGroupId: 'קוד קבוצת רשת',
    ChainGroupName: 'שם קבוצת רשת',
    ChainId: 'מספר רשת',
    ChainName: 'שם רשת',
    ChainShopId: 'מספר חנות ברשת',
    ChainShopIdInput: 'נא להכניס מספר חנות ברשת',
    ChainShopIdMatchShopIdValidator: 'מספר החנות ברשת לא תואם לחנות שנבחרה',
    ChooseChain: 'נא לבחור רשת',
    ChooseChainGroup: 'נא לבחור קבוצת רשת',
    ChooseChainGroupFromList: 'נא לבחור קבוצת רשת מתוך רשימה',
    ChooseChainOrLeaveEmpty: 'נא לבחור רשת או להשאיר ריק לחנות פרטית ',
    ChooseDate: 'נא לבחור תאריך',
    ChooseGroup: 'נא לבחור קבוצה',
    ChooseMember: 'בחר מדווח',
    ChooseNotificationStrategy: 'נא לבחור אסטרטגיית דיווח',
    ChooseSections: 'נא לבחור קטגוריות',
    ChooseShop: 'נא לבחור חנות',
    ChooseShoppingCenter: 'נא לבחור מרכז מסחרי',
    CibusCards: 'כרטיס סיבוס',
    Cigarettes: 'סיגריות',
    Clear: 'נקה',
    ClickToUpload: 'להעלאת קובץ xlsx לקליטת פדיונות',
    Close: 'סגור',
    CloseNotification: 'סגירת הודעה',
    Comment: 'הערה',
    CompaniesLogo: '/assets/partners_logo_flat.png',
    Computers: 'מחשבים',
    ComputersAndAppleCellular: 'מחשבים וסלולר אפל',
    ComputersAndCellular: 'מחשבים וסלולר',
    ConflictedRevenueHeader: 'פדיונות שלא עודכנו',
    ContactForm:'טופס קבלת שירות',
    ContactFormsubmitSuccess:'הטופס נשלח בהצלחה',
    ContactFormSubTitle:'נא למלא את כל הפרטים ונחזור אליך בהקדם האפשרי',
    ContactUs: 'צור קשר',
    ContractId: 'מספר חוזה',
    ContractInput: 'נא להכניס מספר חוזה',
    CreateChain: 'יצירת רשת',
    CreateGroup: 'יצירת קבוצת דיווח',
    CreateSection: 'יצירת קטגוריה',
    CreationDateIsBiggerThanDeactivationDate: 'תאריך פתיחה גדול מתאריך סגירה',
    Deliveries: 'משלוחים',
    DetailedDescription:'תיאור מפורט',
    DialCards: 'כרטיסי חיוג',
    DicountedProducts: 'מוצרים בהנחה',
    DiscountedProducts: 'מוצרים בהנחה',
    DIY: 'DIY',
    DoesNotBelongToGroup: 'ללא קבוצה',
    DownloadTemplate: 'להורדת דוגמא לקובץ xlsx לקליטת פדיונות',
    Drinks: 'משקאות',
    Duplicate: 'חנות קיימת. נא לבדוק שם חנות או מספר חוזה',
    Edit: 'עריכה',
    EditGroup: 'עריכת קבוצה',
    Electronics: 'ציוד הקפי ואלקטרוני',
    Email: 'אימייל',
    EmailInvalid: 'אימייל לא חוקי',
    EmailOrPhone: 'אימייל או מספר טלפון',
    EmailOrPhoneValidationMessage: 'יש להכניס אימייל או מספר סלולרי',
    EmailValidationMessage: 'נא להכניס מייל בלבד',
    EnterShopRevenue: 'נא להכניס פדיון לחנות',
    EnterYourCode: 'נא להכניס את הקוד',
    ErrorContactUs: 'ארעה שגיאה, נא לשלוח את הפרטים דרך "צור קשר" בתפריט',
    ErrorContactUsPage:'ארועה שגיאה, אתם יכולים ליצור איתנו קשר במייל או טלפון שמוצגים בפרטי הקשר',
    ErrorEmptyFile: 'הקובץ ריק',
    ErrorMixedDates: 'לא ניתן לשלוח תאריכים שונים בפעם אחת. התאריכים הם {relatedFailureParameter}. בחנויות {identifierDescription}',
    ErrorMonthOutOfRange: 'חנות {identifierDescription} מכילה חודש לא תקין {relatedFailureParameter}',
    ErrorNegativeRevenueIntegerValue: 'חנות {relatedFailureParameter} מכילה פדיון שלילי. לא ניתן להכניס ערכים שליליים',
    ErrorNoMonth: 'חסר חודש',
    ErrorNoRevenueIntegerColumn: 'חסרה עמודת פדיון במספרים שלמים',
    ErrorNoShopName: 'חסר שם חנות',
    ErrorNoYear: 'חסרה שנה',
    ErrorNullRevenueIntegerValue: 'חנות {relatedFailureParameter} לא מכילה פדיון. נא להכניס פדיון ולנסות שוב',
    ErrorShopNotFound: 'חנות {relatedFailureParameter} לא נמצאה. נא ליצור קשר דרך תפריט צור',
    ErrorYearOutOfRange: 'חנות {identifierDescription} מכילה שנה לא תקינה {relatedFailureParameter}',
    false: 'לא',
    Fashion: 'אופנה',
    Feedback:'משוב',
    FieldIsInvalid: 'שדה {fieldName}  אינו חוקי',
    FieldIsRequired: 'שדה {fieldName} הינו שדה חובה',
    FoodProducts: 'מוצרי מזון',
    FormInvalid:'נא לתקן את השדות בטופס',
    FranchiseSales: 'מכירות זכיינים',
    Gambling: 'לוטו טוטו',
    GeneralQuestion:'שאלה כללית',
    GeneralSection: 'כללי',
    Gifts: 'מתנות',
    Grocery: 'מרכולים',
    GroupDescription: 'תאור הקבוצה',
    GroupDescriptionInput: 'נא להכניס תאור קבוצה',
    GroupId: 'קוד קבוצה',
    GroupName:'שם קבוצה',
    GroupNameInput: 'נא להכניס שם קבוצה',
    GroupRelatedMembers: 'מדווחים',
    GroupRelatedMembersDisclaimer: 'לא נספרים נתונים שאין לך הרשאת צפייה לגביהם',
    GroupRelatedShops: 'חנויות',
    GroupRelatedShopsDisclaimer: 'לא נספרים נתונים שאין לך הרשאת צפייה לגביהם',
    ImportRevenues: 'קליטת פדיונות',
    InsertRevenue: 'הכנס פדיון',
    InsertRevenueWithoutVat: 'הכנס פדיון ללא מע"מ',
    IsDisabled: 'חנות נסגרה',
    Issue:'בעיה',
    Level1: 'רשתות',
    Level1MailAndPhone: 'רמה ראשונה התראות למייל ולטלפון',
    Level1MailOnly: 'רמה ראשונה התראות למייל בלבד',
    Level1PhoneOnly: 'רמה ראשונה התראות לטלפון בלבד',
    Level2: 'פרטיים',
    Level2MailAndPhone: 'רמה שנייה התראות למייל ולטלפון',
    Level2MailOnly: 'רמה שנייה התראות למייל בלבד',
    Level2PhoneOnly: 'רמה שנייה התראות לטלפון בלבד',
    Level3: 'מנהלים',
    Level3MailAndPhone: 'רמה שלישית התראות למייל ולטלפון',
    Level3MailOnly: 'רמה שלישית התראות למייל בלבד',
    Level3PhoneOnly: 'רמה שלישית התראות לטלפון בלבד',
    Level4: 'רמה רביעית',
    Level5: 'רמה חמישית',
    Level5MailAndPhone: 'רמה חמישית התראות למייל ולטלפון',
    Level5MailOnly: 'רמה חמישית התראות למייל בלבד',
    Level5PhoneOnly: 'רמה חמישית התראות לטלפון בלבד',
    Lights: 'תאורה',
    Loading: 'טעינה',
    Login: 'כניסה',
    LogOut: 'יציאה',
    LogoutWarningAction: 'הבנתי',
    LogoutWarningText: 'לשמירה על אבטחת האתר, המערכת תתנתק בעוד חמש דקות. נא להיכנס מחדש',
    Machines: 'מכונות',
    Main: 'ראשי',
    Medicine: 'תרופות',
    Member:'מדווח',
    MemberCommentInput: 'נא להכניס הערה',
    MemberEmail: 'דוא"ל',
    MemberEmailInput: 'נא להכניס כתובת תקנית',
    MemberId: 'מספר מדווח',
    MemberName: 'שם מדווח',
    MemberNameInput: 'הכנס שם מדווח',
    MemberPhoneNumber: 'מספר נייד',
    MemberPhoneNumberInput: 'נא להכניס מספר נייד',
    MemberPhoneNumberInvalid: 'מספר נייד לא חוקי',
    MemberPosition: 'תפקיד מדווח',
    MemberPositionInput: 'נא להכניס תפקיד',
    Members: 'מדווחים',
    MembersPerPage: 'מספר פריטים בעמוד',
    MezuzahCards: 'קלפים למזוזה',
    MissingChainId: 'חסרה רשת',
    MissingChainName: 'נא להזין שם רשת',
    MissingContractId: 'חסר מספר חוזה',
    MissingDataValidator: 'לא מולאו כל השדות הדרושים',
    MissingEmailAndPhone: 'נא להזין טלפון או מייל',
    MissingEmailForNotifyEmail:'חסר אימייל להטרעה באימייל',
    MissingGeneralSection: 'חסרה קטגוריה כללי',
    MissingGroupName: 'נא להזין שם קבוצת דיווח',
    MissingMemberName: 'נא להזין שם',
    MissingNotificationGroup: 'נא להזין קבוצת דיווח',
    MissingNotificationStrategy: 'נא להזין אסטרטגייט התראות',
    MissingPhoneForNotifyPhone:'חסר מספר טלפון להתרעה בטלפון',
    MissingRevenueHeader: 'פדיונות חסרים',
    MissingSection: 'חסרה קטגוריה',
    MissingSectionExternalId:'נא להזין מזהה חיצוני לקטגוריה',
    MissingSectionName:'נא להזין שם קטגוריה',
    MissingShopName: 'חסר שם חנות',
    MissingShoppingCenterGroupName: 'קבוצת קניונים ללא שם',
    MissingShoppingCenterId: 'חסר מרכז',
    Month: 'חודש',
    MonthNames: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
    Moses1: 'מוזס 1',
    Moses2: 'מוזס 2',
    MosesDelta: 'מוזס דלתא',
    NewGroup: 'קבוצה חדשה',
    No:'לא',
    NoDataToShow: 'אין מידע להציג',
    NoItemsToShow: 'אין פריטים לתצוגה',
    NoNotifications: 'ללא התראות',
    NoPhonenumber: 'ללא טלפון',
    NormalUser: 'משתמש רגיל',
    NoShops: 'אין חנויות להצגה',
    NotAppleProducts: 'לא מוצרי אפל',
    NotEmptyValidator: 'שדה ריק, ודא שכל השדות מלאים',
    NotificationGroup: 'קבוצת דיווח',
    NotificationGroupDoesNotBelongToShoppingCenterValidator: 'קבוצת הדיווח שנבחרה לא שייכת למרכז המסחרי',
    NotificationGroupId: 'מספר קבוצת דיווח',
    NotificationGroups: 'קבוצות דיווח',
    NotificationGroupsPerPage: 'מספר קבוצות דיווח בעמוד',
    NotificationGroupTypeColorLegend: 'הצבעים מייצגים את סוג הקבוצה. כחול לרמת חנות, אדום לרמת מרכז מסחרי',
    NotificationStrategy: 'אסטרטגיית התראות',
    NotificationStrategyInvalid: 'אסטרטגיית התראות לא חוקית',
    NotifyOn:"שליחת התראה ב",
    NotMandatory: 'לא חובה',
    Only4DecimalAllowed: 'נא להכניס מספרים בלבד ועד 4 ספרות אחרי הנקודה',
    OnlyIntegerAllowed: 'ניתן להכניס מספרים שלמים בלבד',
    OperationSucceded: 'הפעולה הסתיימה בהצלחה',
    Paper: 'נייר',
    PaperAndCellular: 'נייר וסלולרי',
    Password: 'סיסמא',
    PermissionValidator: 'חסרה הרשאה לביצוע הפעולה',
    Pharm: 'פארם',
    Phone:"טלפון",
    PhoneNumber: 'מספר נייד',
    PhoneNumberInput: 'נא להכניס מספר נייד',
    PhoneNumberInvalid: 'מספר נייד לא חוקי',
    PostRevenueSucceed: 'פדיון התקבל בהצלחה',
    PropertyIdInput: 'נא להכניס מספר נכס',
    PropertyNumber: 'מספר נכס',
    Reason: 'סיבה',
    RelevantGroups: 'קבוצות מקושרות ל{callerEntityDetails} - ייתכן וישנן קבוצות אשר אינך רשאי לצפות בהן',
    RelevantMembers: 'מדווחים מקושרים ל{callerEntityDetails} - ייתכן וישנם מדווחים אשר אינך רשאי לצפות בהם',
    RelevantShops: 'חנויות מקושרות ל{callerEntityDetails} - ייתכן וישנן חנויות אשר אינך רשאי לצפות בהן',
    Reports: 'דוחות',
    Request:'בקשה',
    Retailers: 'קמעונאים',
    Return: 'חזרה',
    Revenue: 'פדיון',
    RevenueExists: 'קיים פדיון במערכת',
    RevenueInputHelp: 'לעזרה לחץ כאן',
    RevenueInteger: 'פדיון במספרים שלמים',
    RevenueMenuRoot: 'פדיונות',
    RevenueRecieved: 'פדיון התקבל',
    RevenueResponseSummaryHeader: 'סיכום העלאת פדיונות',
    RevenueWithoutVat: 'פדיון ללא מע"מ',
    Search: 'חיפוש',
    SectionBelongToShoppingCenterGroupValidator: 'קטגוריה נבחרת לא ניתנת לשיוך לקבוצת הקניונים שנבחרה',
    SectionExistsValidator: 'קטגוריה זהה כבר קיימת במערכת',
    SectionExternalId:'מזהה קטגוריה חיצוני',
    SectionExternalIdInput:'נא להכניס מזהה חיצוני לקטגוריה',
    SectionId: 'מספר קטגוריה',
    SectionName: 'שם קטגוריה',
    SectionNameInput: 'נא להכניס שם קטגוריה',
    Sections: 'קטגוריות',
    SelectMonth: 'בחר חודש',
    SelectName: 'נא לבחור',
    SelectNotificationGroup: 'נא לבחור קבוצת דיווח',
    SelectShop: 'נא לבחור חנות',
    SelectShoppingCenter: 'נא לבחור מרכז מסחרי',
    SelectShoppingGroup: 'נא לבחור קבוצת מרכזים',
    SelectSubject:'נא לבחור נושא פנייה',
    SelectYear: 'בחר שנה',
    Send: 'שליחה',
    SendEmailLoginSucceed: 'נא לבדוק את תיבת המייל',
    SendRevenueWithoutVat: 'שלח פדיון ללא מע"מ',
    Shop: 'חנות',
    ShopCreationDate: 'תאריך פתיחה',
    ShopDeactivated: 'חנות דווחה כסגורה',
    ShopDeactivationDate: 'תאריך סגירה',
    ShopDisabled: 'חנות סומנה כסגורה',
    ShopId: 'מספר חנות',
    ShopIsNotClosed: 'החנות לא סגורה',
    ShopIsNotPartOfChain: 'החנות לא חלק מרשת',
    ShopName: 'שם חנות',
    ShopNameInput: 'נא להכניס שם לחנות',
    ShoppingCenter: 'מרכז מסחרי',
    ShoppingCenterGroup: 'קבוצת קניונים',
    ShoppingCenterGroupName: 'שם קבוצה',
    ShoppingCenterName: 'שם מרכז',
    ShoppingCenters: 'קניונים',
    ShopRelatedGroups: 'קבוצות דיווח',
    ShopRelatedMembers: 'מדווחים',
    Shops: 'חנויות',
    ShopsPerPage: 'מספר חנויות בעמוד',
    ShowDisabledShops: 'הצג חנויות שסומנו כסגורות',
    ShowLess:'הצג פחות',
    ShowMore:'הצג עוד',
    ShowShopsWithData: 'הצג חנויות שכבר דווחו',
    SignIn: 'כניסה',
    SignUpSignIn: 'רישום/כניסה',
    Sms:"הודעת SMS",
    Subject:'נושא הפנייה',
    Submit: 'שלח',
    SuccessChainUpdated: 'רשת עודכנה בהצלחה',
    SuccessGroupUpdated: 'קבוצת הדיווח עודכנה בהצלחה',
    SuccessMemberUpdated: 'פרטי משתמש עודכנו בהצלחה',
    SuccessRevenueHeader: 'פדיונות שהועלו בהצלחה',
    SuccessSectionUpdated:'קטגוריה נוצרה בהצלחה',
    SuccessShopUpdated: 'החנות עודכנה בהצלחה',
    SystemGenerated: 'ערך שנוצר על ידי המערכת',
    Technology: 'טכנולוגיה',
    TermsAndConditionPath:"/assets/tc/TChe.jpg",
    Title:'כותרת',
    Toys: 'צעצועים',
    true: 'כן',
    UnAuthorizeNotificationGroup: 'פעולה לא חוקית לקבוצה {groupName}',
    UniqueChainGroupNameValidator: 'שם קבוצת רשת זהה כבר קיים במערכת',
    UniqueChainNameValidator: 'שם רשת זהה כבר קיים במערכת',
    UniqueChainShopIdValidator: 'מספר חנות ברשת זהה כבר קיים במערכת',
    UniqueEmailValidator: 'כתובת דואר אלקטרוני זהה כבר קיימת במערכת',
    UniqueNotificationGroupNameValidator: 'קבוצת דיווח זהה כבר קיימת במערכת',
    UniquePhoneNumberValidator: 'מספר טלפון זהה כבר קיים במערכת',
    UniqueRoleName: 'שם הקבוצה כבר קיים במערכת. נסה שם אחר',
    UniqueSectionValidator: 'קטגוריה לא קיימת במערכת',
    UniqueShopNameValidator: 'שם חנות זהה כבר קיים במערכת',
    UniqueShoppingCenterShopIdValidator: 'מספר חנות במרכז המסחרי זהה כבר קיים במערכת',
    Unknown: 'לא ידוע',
    UploadFileToolTip: 'לחץ להסבר על העלאת קובץ',
    Username: 'שם משתמש',
    Validator: 'ארעה שגיאה. נא ליצור קשר',
    WoltApplication: 'אפליקציית וולט',
    Year: 'שנה',
    Yes: 'כן',
  }
