<template>
  <b-modal :active="active" can-cancel :on-cancel="() => this.action=cancel">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{$t('Attention')}}</p>
      </header>
      <section class="modal-card-body">
        <p>{{$t('AreYouSure')}}</p>
      </section>
      <footer class="modal-card-foot">
        <b-button type="is-primary"	data-test-id="areYouSureAccept" @click="action='yes'">{{$t('Yes')}}</b-button>
        <b-button @click="action='cancel'">{{$t('Cancel')}}</b-button>
      </footer>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ['active'],
  data() {
    return {
      action: '',
    };
  },
  watch: {
    action: {
      handler() {
        if (this.action !== '') {
          this.$emit('response', this.action);
        }
        this.action = '';
      },
    },
  },
};
</script>
