<template>
		<b-table
			:data="this.notificationGroups"
			:striped="true"
			:loading="loading"
			:paginated="showPagination"
			pagination-position="both"
			:per-page="rowsPerPage"
			default-sort="groupName"
			default-sort-direction="asc"
			height=65vh
			focusable
			scrollable
			sticky-header
		>
			<b-table-column
				v-slot="props"
				sortable
				field="groupId"
				:label="$t('GroupId')"
				centered
			>
				{{ props.row.groupId }}
			</b-table-column>
			<b-table-column
				header-class="has-text-right"
				v-slot="props"
				sortable
				field="groupName"
				:label="$t('NotificationGroup')"
			>
				{{ props.row.groupName }}
			</b-table-column>
			<b-table-column
				header-class="has-text-right"
				v-slot="props"
				sortable
				field="groupDescription"
				:label="$t('GroupDescription')"
			>
				{{ props.row.groupDescription }}
			</b-table-column>
			<b-table-column
				sortable
				field="members"
				:label="$t('GroupRelatedMembers')"
				:visible="this.showRelatedItems"
				centered
			>
				<template v-slot:header="{ column }">
					<b-tooltip
						:label="$t('GroupRelatedMembersDisclaimer')"
						append-to-body
						dashed
					>
						{{ column.label }}
					</b-tooltip>
				</template>
				<template v-slot="props">
				<b-button @click="showRelatedMembers(props.row.groupId)" type="is-text">{{ props.row.members.length}}</b-button>
				</template>
			</b-table-column>
			<b-table-column
			sortable field="shopIds"
			:label="$t('GroupRelatedShops')"
			:visible="this.showRelatedItems"
			centered
			>
				<template v-slot:header="{ column }">
					<b-tooltip
						:label="$t('GroupRelatedShopsDisclaimer')"
						append-to-body
						dashed
					>
						{{ column.label }}
					</b-tooltip>
				</template>
				<template v-slot="props">
				<b-button @click="showRelatedShops(props.row.groupId)" type="is-text">{{ props.row.shopIds.length }}</b-button>
				</template>
			</b-table-column>
			<b-table-column
				v-slot="props"
				:label="$t('Edit')"
				:visible="true"
				centered
			>
				<b-button
				tag="router-link"
				:disabled="!props.row.canUpdateNotificationGroup"
				type="is-warning"
				icon-pack="fas"
				icon-right="pencil-alt"
				:to="{ name: `notificationGroupAdminEdit`,	params: { id: props.row.groupId, returnTo: $route.path },}"
				data-test-id="notificationGroupsEditButton"
				/>
			</b-table-column>

      <template slot="empty">
        <div
          class="content has-text-grey has-text-centered"
          v-if="this.loading"
        >
          <p>{{ $t("Loading") }}</p>
        </div>
        <section v-else class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon pack="fas" icon="frown-open" size="is-large" />
            </p>
            <p>{{ $t("NoDataToShow") }}</p>
          </div>
        </section>
      </template>
      <template slot="top-left">
        <b-field :label="$t('NotificationGroupsPerPage')" type="is-blue">
          <b-select v-model="rowsPerPage" expanded @input="setPageSize">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </b-select>
        </b-field>
      </template>
    </b-table>
</template>
<script>
import localStorageAccess from "@/resourceAccess/localStorageAccess";

export default {
  props: ["notificationGroups","showRelatedItems"],
  computed: {
    loading() {
      return (
        this.notificationGroups === undefined ||
        this.notificationGroups.length === 0
      );
    },
    showPagination() {
      return this.notificationGroups.length > 10;
    }
  },
  data() {
    return {
      rowsPerPage: 10
    };
  },
  methods: {
    setPageSize() {
      localStorageAccess.saveRowsPerPage(
        "notificationGroups",
        this.rowsPerPage
      );
    },
    showRelatedMembers(groupId) {
      this.$emit("member-count-clicked", groupId);
    },
    showRelatedShops(groupId) {
      this.$emit("shop-count-clicked", groupId);
    }
  },
  mounted() {
    this.rowsPerPage =
      localStorageAccess.getRowsPerPage("notificationGroups") ||
      this.rowsPerPage;
  }
};
</script>
