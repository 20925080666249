<template>
  <div class="container">
    <section class="section">
      <h1 class="title">{{ $t("ContactForm") }}</h1>
      <p class="subtitle">{{ $t("ContactFormSubTitle") }}</p>
      <form ref="contactForm" class="columns is-justify-content-space-between">
        <div class="column is-half is-6">
          <b-field :label="$t('Subject')">
            <b-select
              :placeholder="$t('SelectSubject')"
              expanded
              v-model="formData.subject"
              data-test-id="subject"
            >
              <option value="-">-</option>
              <option :value="$t('GeneralQuestion')">
                {{ $t("GeneralQuestion") }}
              </option>
              <option :value="$t('Issue')">{{ $t("Issue") }}</option>
              <option :value="$t('Request')">{{ $t("Request") }}</option>
              <option :value="$t('Feedback')">{{ $t("Feedback") }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('Title')">
            <b-input v-model="formData.title" data-test-id="title" />
          </b-field>
          <b-field :label="$t('Email')">
            <b-input
              type="email"
              v-model="formData.email"
              :validation-message="
                $t('FieldIsInvalid', { fieldName: $t('Email') })
              "
              name="email"
              data-test-id="email"
            />
          </b-field>
          <phoneNumberInput
            v-model="formData.phoneNumber"
            :canEdit="true"
            :label="$t('PhoneNumber')"
            :placeholder="$t('PhoneNumberInput')"
          />
        </div>
        <div class="column is-half is-5">
          <b-field :label="$t('DetailedDescription')">
            <b-input
              type="textarea"
              v-model="formData.description"
              data-test-id="description"
            />
          </b-field>
          <b-field class="is-grouped is-grouped-right">
            <b-button
              class="is-primary"
              @click="submitFormData"
              data-test-id="submit"
              :loading="processing"
              :disabled="processing"
            >
              {{ $t("Submit") }}
            </b-button>
          </b-field>
          <h2 class="title is-size-5 mt-6 mb-3">
            {{ $t("AdditionalContactDetails") }}
          </h2>
          <div class="level is-justify-content-space-between">
            <div class="is-justify-content-start level-item">
              <b-icon pack="fas" icon="phone" size="is-small" />
              <a
                class="has-text-weight-semibold mr-1 ml-1 bidi-plaintext"
                href="tel:+972776001701"
                >+972776001701</a
              >
            </div>
            <div class="is-justify-content-end level-item">
              <b-icon pack="fas" icon="envelope" size="is-small" />
              <a
                class="has-text-weight-semibold mr-1 ml-1"
                href="mailto:msl@mallshoplink.com"
                >msl@mallshoplink.com</a
              >
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import contactService from "@/services/contactService";
import phoneNumberInput from "@/components/phoneNumberInput";
import * as Sentry from "@sentry/browser";

export default {
  components: {
    phoneNumberInput,
  },
  data() {
    return {
      formData: {
        phoneNumber: 0,
      },
      processing:false,
    };
  },
  methods: {
    openNotification(message, type) {
      let autoCloseProps = {};
      if (type === "is-success") {
        autoCloseProps = {
          autoClose: true,
          duration: 5000,
        };
      }
      this.$buefy.notification.open({
        message,
        type,
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        ...autoCloseProps,
        "data-test-id": "contactUsValidationMessage",
      });
    },

    submitFormData() {
      const validationResults = contactService.validateFormData({ ...this.formData}, this.$refs.contactForm.email);
      if (validationResults.length > 0) {
        const translatedErrors = validationResults
          .map((validator) => this.$t(validator))
          .join(", ");
        this.openNotification(translatedErrors, "is-danger");
        return Promise.resolve("done");
      }
      this.processing = true;
      contactService.postForm({ ...this.formData })
        .then(() => {
          this.openNotification(
            this.$t("ContactFormsubmitSuccess"),
            "is-success"
          );
          this.processing = false;
          return Promise.resolve("done");
        })
        .catch((error) => {
          this.processing = false;
          if (error?.response && error.response.status === 409) {
            Object.getOwnPropertyNames(error.response.data?.errors).forEach(
              (err) => {
                this.openNotification(this.$t(err), "is-danger");
              }
            );
          } else {
            this.openNotification(this.$t("ErrorContactUsPage"), "is-danger");
            Sentry.captureException(error);
          }
          return Promise.resolve("done");
        });
    },
  },
};
</script>

