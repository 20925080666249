<template>
	<div>
		<b-table
			:data="this.membersList"
			:striped="true"
			:loading="loading"
			:paginated="showPagination"
			:per-page="rowsPerPage"
			default-sort="memberFirstName"
			default-sort-direction="asc"
			pagination-position="both"
			height=65vh
			focusable
			scrollable
			sticky-header
		>
			<b-table-column
				v-slot="props"
				header-class="has-text-right"
				sortable
				field="memberId"
				:label="$t('MemberId')"
				centered
			>
				{{ props.row.memberId }}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
			v-slot="props"
			sortable
			field="memberPositionName"
			:label="$t('MemberPosition')"
			>
				{{props.row.memberPositionName}}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
				v-slot="props"
				sortable
				field="memberFirstName"
				:label="$t('MemberName')"
			>
				{{ props.row.memberFirstName }}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
				v-slot="props"
				sortable
				field="memberEmail"
				:label="$t('MemberEmail')"
			>
				{{ props.row.memberEmail }}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
				v-slot="props"
				sortable
				field="memberPhoneNumber"
				:label="$t('MemberPhoneNumber')"
			>
				{{ props.row.memberPhoneNumber }}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
				v-slot="props"
				sortable
				field="memberGroupId"
				:label="$t('NotificationGroupId')"
			>
				{{ props.row.memberGroupId }}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
				v-slot="props"
				sortable
				field="memberGroupName"
				:label="$t('NotificationGroup')"
			>
				{{ props.row.memberGroupName }}
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
				v-slot="props"
				sortable
				field="memberNotificationStrategy"
				:label="$t('NotificationStrategy')"
			>
				{{ $t(props.row.memberNotificationStrategyName) }}
			</b-table-column>
			<b-table-column
				v-slot="props"
				sortable
				:visible="this.showRelatedItems"
				field="shopsRelated"
				:label="$t('Shops')"
				centered
			>
			<b-button @click="showRelatedShops(props.row.memberId)" type="is-text" data-test-id="memberShopsCount">{{ props.row.shopsRelated.length }}</b-button>
			</b-table-column>
			<b-table-column
			header-class="has-text-right"
			v-slot="props"
			sortable
			field="memberComment"
			:label="$t('Comment')"
			>
				<div v-if="props.row.memberComment && props.row.memberComment.length > 40 " data-test-id="memberCommentCollapse">
					{{splitString(props.row.memberComment, 40).part1}}
					<b-collapse :open="false" position="is-bottom">
						<template #trigger="props">
							<a class="is-text has-text-info">
								{{ !props.open ? $t('ShowMore') : $t('ShowLess') }}
							</a>
						</template>
						{{splitString(props.row.memberComment, 40).part2}}
					</b-collapse>
				</div>
				<div v-else data-test-id="memberCommentShort">
				{{props.row.memberComment}}
				</div>
			</b-table-column>
			<b-table-column
			v-slot="props"
			:label="$t('Edit')"
			centered
			>
			<b-button
			tag="router-link"
			type="is-warning"
			icon-pack="fas"
			icon-right="pencil-alt"
			:to="{ name:`memberAdminEdit`, params:{id:props.row.memberId,returnTo:$route.path}}"
			:disabled="!props.row.canUpdateMember"
			/>
			</b-table-column>
			<template slot="top-left">
				<b-field :label="$t('MembersPerPage')" type="is-blue">
					<b-select v-model="rowsPerPage" expanded @input="setPageSize">
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="75">75</option>
						<option value="100">100</option>
					</b-select>
				</b-field>
			</template>
			<template slot="empty">
				<div
					class="content has-text-grey has-text-centered"
					v-if="this.loading"
				>
					<p>{{ $t("Loading") }}</p>
				</div>
				<div v-else class="content has-text-grey has-text-centered">
					<p><b-icon pack="fas" icon="frown-open" size="is-large" /></p>
					<p>{{ $t("NoDataToShow") }}</p>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import localStorageAccess from "@/resourceAccess/localStorageAccess";

export default {
	props: ["membersList","loading","showRelatedItems"],
	data() {
		return {
			rowsPerPage: 10,
		};
	},
	mounted() {
		this.rowsPerPage =
			localStorageAccess.getRowsPerPage("membersList") || this.rowsPerPage;
	},
	computed: {
		showPagination() {
			return this.membersList ? this.membersList.length > 10 : null;
		},
	},
	methods: {
		setPageSize() {
			localStorageAccess.saveRowsPerPage("membersList", this.rowsPerPage);
		},
		showRelatedShops(memberId){
			this.$emit("shop-count-clicked", memberId);
		},

		splitString(value, length) {
			if(value.length > length) {
				let part1 = value.substr(0, length);
				let part2 = value.substr(length);
				return {part1, part2};
			}
		},
	}, 
};
</script>
