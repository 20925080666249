<template>
  <div class="container">
    <areYouSureBox
      has-modal-card
      :active="this.showAreYouSure"
      @response="areYouSureResponseHandler"
    ></areYouSureBox>
    <form class="section columns">
      <div class="column is-two-fifths" v-if="selectedMember.memberId || isNew">
        <memberId :value="selectedMember.memberId" />
        <b-field :label="$t('MemberPosition')" class="hide-inner-invisible">
          <b-input
            expanded
            v-model="selectedMember.positionName"
            :placeholder="canEdit ? $t('MemberPositionInput') : ''"
            data-test-id="memberPositionName"
            :disabled="canEdit === false"
            maxlength="500"
          />
        </b-field>
        <memberName
          v-model="selectedMember.memberFirstName"
          :canEdit="canEdit"
          ref="memberName"
        />
        <memberEmail v-model="selectedMember.email" :canEdit="canEdit" ref="memberEmail"/>
        <phoneNumberInput
          v-model="selectedMember.phoneNumber"
          :canEdit="canEdit"
          :label="$t('MemberPhoneNumber')"
          :placeholder="$t('MemberPhoneNumberInput')"
        />
      </div>
      <div class="column is-two-fifths" v-if="selectedMember.memberId || isNew">
        <notificationGroups
        :canEdit="canEdit"
        v-model="selectedMember.groupId"
        />
        <memberNotificationStrategy
        v-model="selectedMember.notificationStrategy"
        :canEdit="canEdit"
        />
         <b-field :label="$t('NotifyOn')">
          <b-checkbox
          v-model="selectedMember.allowEmail"
          :native-value="selectedMember.allowEmail"
          :true-value="true"
          :false-value="false"
          data-test-id="allowEmail"
          >
          {{$t('Email')}}
          </b-checkbox>
          <b-checkbox 
          v-model="selectedMember.allowSms"
          :native-value="selectedMember.allowSms"
          :true-value="true"
          :false-value="false"
          data-test-id="allowSms"
          >
          {{$t('Sms')}}
          </b-checkbox>
          <b-checkbox
          v-model="selectedMember.allowPhone"
          :native-value="false"
          :true-value="true"
          :false-value="false"
          data-test-id="allowPhone"
          >
          {{$t('Phone')}}
          </b-checkbox>
         </b-field>
        <b-field :label="$t('Comment')">
          <b-input
          expanded
          type="textarea"
          v-model="selectedMember.comment"
          :placeholder="canEdit ? $t('MemberCommentInput') : ''"
          data-test-id="memberComment"
          :disabled="canEdit === false"
          maxlength="500"
          />
        </b-field>
        <b-field grouped position="is-right">
          <b-field>
            <b-button @click="cancelUpdate">
             {{ canEdit ? $t("Cancel") : $t("Return") }}
            </b-button>
          </b-field>
          <b-field v-if="canEdit">
            <b-button
            @click="startProcessing"
            :disabled="isProcessing"
            data-test-id="memberDetailsSubmit"
            >
             {{ $t("Submit") }}
            </b-button>
          </b-field>
        </b-field>
      </div>
    </form>
    <section class="section" v-if="relatedShopsList.length > 0">
      <p class="title">{{ $t("Shops") }}</p>
      <shopAdministrationDetails
      :shopsList="this.shopsAdministrationListDetails"
      :loading="this.isProcessing"
      :showRelatedItems="false"
      />
    </section>
  </div>
</template>
<script>
import * as Sentry from "@sentry/browser";
import { mapGetters } from "vuex";
import shopsService from "@/services/shopsService";
import membersService from "@/services/membersService";
import notificationService from "@/services/notificationService";
import areYouSureBox from "@/components/AreYouSureBox";
import memberId from "@/components/members/MemberId";
import memberName from "@/components/members/MemberName";
import memberEmail from "@/components/members/MemberEmail";
import phoneNumberInput from "@/components/phoneNumberInput";
import notificationGroups from "@/components/notifications/NotificationGroups";
import memberNotificationStrategy from "@/components/members/MemberNotificationStrategy";
import shopAdministrationDetails from "@/components/shopAdministrationDetails";
import routerUtils from "@/utils/routerUtils";

export default {
 props:{
   id: Number,
   returnTo:{
     type: String,
     default: '/members/admin',
   }
 },
  components: {
    areYouSureBox,
    memberId,
    memberName,
    memberEmail,
    phoneNumberInput,
    notificationGroups,
    memberNotificationStrategy,
    shopAdministrationDetails
  },
  data() {
    return {
      selectedMember: {},
      originalMember: {},
      showAreYouSure: false,
      isNew: false,
      processing: false,
    };
  },
  async mounted() {
    if (this.shopList === null) {
      await shopsService.getAllowedShopsList();
    }
    if (this.notificationGroupList === null) {
      await notificationService.getNotificationGroups();
    }
    if (this.membersList === null) {
      await membersService.getMembersList();
    }
    if (!this.strategiesList) {
      await notificationService.getStrategies();
    }
    if (!this.chainsList) {
      shopsService.getChains();
    }
    if (this.id) {
      this.getMemberToEdit();
    } else {
     this.createNewMember();
    }
  },
  computed: {
    isProcessing() {
      return this.processing;
    },
    ...mapGetters({
      shopList: "getAllowedShopsList",
      membersList: "getMemberList",
      strategiesList: "getStrategies",
      chainsList: "getChains",
      notificationGroupList: "getNotificationGroups",
    }),
    canEdit() {
      return this.selectedMember.canUpdateMember
    },
    relatedShopsList(){
      var shopsRelated = [];
      const groupChosen = this.notificationGroupList ?
      this.notificationGroupList.find(group => group.groupId === this.selectedMember.groupId ?? 0)
      : {} ;
      if(groupChosen && groupChosen.shopIds){
         shopsRelated = this.shopList? this.shopList.filter(shop => groupChosen.shopIds.includes(shop.shopId)):[]
      }
      return shopsRelated;
    },
    shopsAdministrationListDetails() {
      let data = shopsService.prepareShopsData(
        this.relatedShopsList,
        this.notificationGroupList,
        this.membersList,
        this.chainsList,
      );
      return data;
    },
  },
  methods: {
    async areYouSureResponseHandler(action) {
      if (this.showAreYouSure === true) {
        this.showAreYouSure = false;
        try {
          if (action === "yes") {
           await this.sendUpdate();
          }
        } finally {
          this.processing = false;
        }
      }
    },
    createNewMember() {
      this.selectedMember = {
        canUpdateMember: true,
        memberFirstName: "",
        email: "",
        phoneNumber: 0,
        notificationStrategyId: 9,
        notificationGroupId: 0,
        positionName: null,
        comment: null,
        allowPhone: false,
        allowEmail: true,
        allowSms: true,
      };
      this.isNew = true;
    },
    startProcessing() {
      if (this.processing === false) {
        this.processing = true;
        const validationResults = membersService.validateMemberData( this.selectedMember, [this.$refs.memberName,this.$refs.memberEmail]);
        if (validationResults.length > 0) {
          const translatedErrors = validationResults
          .map((validator) => this.$t(validator))
          .join(", ");
          this.openNotification(translatedErrors, "is-danger");
          this.processing = false;
        } else {
          this.showAreYouSure = true;
        }
      }
    },
     sendUpdate() {
      const distinctMember = {
        memberId: this.selectedMember.memberId,
        memberFirstName: this.selectedMember.memberFirstName,
        email: this.selectedMember.email,
        phoneNumber: this.selectedMember.phoneNumber,
        notificationGroupId: this.selectedMember.groupId,
        notificationStrategyId: this.selectedMember.notificationStrategy,
        positionName: this.selectedMember.positionName,
        comment: this.selectedMember.comment,
        allowPhone: this.selectedMember.allowPhone,
        allowEmail: this.selectedMember.allowEmail,
        allowSms: this.selectedMember.allowSms,
        
      };
      membersService.postMember(distinctMember).then(() => {
          this.openNotification(this.$t("SuccessMemberUpdated"), "is-success");
          this.resetMember();
          membersService.getMembersList();
          return Promise.resolve("done");
        })
        .then(() => {
          routerUtils.moveTo(this.returnTo);
        })
        .catch((error) => {
          if (error?.response && error.response.status === 409) {
            Object.getOwnPropertyNames(error.response.data?.errors).forEach((err) => {
              this.openNotification(this.$t(err), "is-danger");
            });
          } else {
            this.openNotification(this.$t("ErrorContactUs"), "is-danger");
            Sentry.captureException(error);
          }
          return Promise.resolve("done");
        });
    },
    cacheOriginalMember(selectedMember) {
      if (selectedMember) {
        this.selectedMember = selectedMember;
        this.originalMember = { ...this.selectedMember };
      }
    },
    openNotification(message, type) {
    let autoCloseProps = {};
      if(type === "is-success"){
        autoCloseProps = {
          autoClose: true, 
          duration: 5000,
        }
      }
      this.$buefy.notification.open({
        message,
        type,
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        ...autoCloseProps,
        "data-test-id": "memberValidationMessage",
      });
    },
    revertToOriginalMember() {
      Object.assign(this.selectedMember, this.originalMember);
    },
    resetMember() {
      (this.selectedMember = {}), (this.isNew = false);
    },
    cancelUpdate() {
      routerUtils.moveTo(this.returnTo);
    },
    getMemberToEdit() {
      this.selectedMember = this.membersList ? this.membersList.find((x) => x.memberId == this.id) : [];
      this.cacheOriginalMember(this.selectedMember);
    },
  },
  beforeDestroy() {
    this.revertToOriginalMember();
    this.resetMember();
  },
};
</script>
<style>
.hide-inner-invisible .is-invisible{
    display: none;
}
</style>