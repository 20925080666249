<template>
  <b-table
    :data="revenueDataForTable"
    :striped="true"
    :loading="loading"
    :paginated="showPagination"
    pagination-position="both"
    :per-page="rowsPerPage"
    default-sort="shoppingCenterGroupName"
    default-sort-direction="asc"
  >
    <b-table-column
      v-slot="props"
      header-class="has-text-right"
      sortable
      field="shoppingCenterGroupName"
      :label="$t('ShoppingCenterGroupName')"
      >{{
        props.row.shoppingCenterGroupName === props.row.shoppingCenterName
          ? $t("DoesNotBelongToGroup")
          : props.row.shoppingCenterGroupName
      }}</b-table-column
    >
    <b-table-column
      v-slot="props"
      header-class="has-text-right"
      sortable
      field="shoppingCenterName"
      :label="$t('ShoppingCenterName')"
      >{{ props.row.shoppingCenterName }}</b-table-column
    >
    <b-table-column
      v-slot="props"
      header-class="has-text-right"
      sortable
      field="shopName"
      :label="$t('ShopName')"
      >{{ props.row.shopName }}</b-table-column
    >
    <b-table-column
      v-slot="props"
      header-class="has-text-right"
      sortable
      field="chainShopId"
      :label="$t('ChainShopId')"
      >{{ props.row.chainShopId }}</b-table-column
    >
    <b-table-column
      v-slot="props"
      header-class="has-text-right"
      field="sections"
      :label="$t('RevenueWithoutVat')"
    >
      <span
        v-for="(section, index) in props.row.sections"
        :key="index"
        class="has-text-right"
      >
        <b-field
          :type="
            revenueExistsForShop(props.row.shopId, section.sectionId)
              ? 'is-info'
              : 'is-red'
          "
          :label="props.row.sections.length > 1 ? section.sectionName : ''"
        >
          <b-input
            :data-test-id="
              'revenueInput_'
                .concat(props.row.shopId)
                .concat('_')
                .concat(section.sectionId)
            "
            position="is-right"
            :disabled="props.row.isDisabled"
            v-model="section.revenueValue"
            :placeholder="
              revenueValuePlaceHolder(
                props.row.shopId,
                section.sectionId,
                props.row.isDisabled
              )
            "
            @keydown.up.prevent.native
            @keydown.down.prevent.native
            :validation-message="$t('OnlyIntegerAllowed')"
            :tabindex="props.row.isDisabled ? -1 : 0"
            :ref="
              'revenueInput_'
                .concat(props.row.shopId)
                .concat('_')
                .concat(section.sectionId)
            "
            v-currency="{
              currency: null,
              locale: locale,
              precision: 2,
              distractionFree: false,
              allowNegative: false,
            }"
          />
        </b-field>
      </span>
    </b-table-column>
    <template slot="empty">
      <section class="section" v-if="loading">
        <div class="content has-text-grey has-text-centered">
          <p>{{ $t("Loading") }}</p>
        </div>
      </section>
      <section class="section" v-else-if="value && value.length === 0">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon pack="fas" icon="smile" size="is-large" />
          </p>
          <p>{{ $t("AllShopsReported") }}</p>
        </div>
      </section>
      <section class="section" v-else>
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon pack="fas" icon="frown-open" size="is-large" />
          </p>
          <p>{{ $t("NoShops") }}</p>
        </div>
      </section>
    </template>
    <template slot="top-left">
      <b-field :label="$t('ShopsPerPage')" type="is-blue">
        <b-select v-model="rowsPerPage" expanded @input="setPageSize">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </b-select>
      </b-field>
    </template>
  </b-table>
</template>

<script>
import shopsService from "@/services/shopsService";
import revenueService from "@/services/revenueService";
import localStorageAccess from "@/resourceAccess/localStorageAccess";

export default {
  props: [
    "loading",
    "filterPhrase",
    "showDisabledShops",
    "showShopsWithData",
    "value",
    "utcSelectedDate",
  ],
  methods: {
    async setShopActivation(shop) {
      await shopsService.setShopActivation(shop.shopId, !shop.isDisabled);
    },
    setPageSize() {
      localStorageAccess.saveRowsPerPage("revenueInput", this.rowsPerPage);
    },
    validateRevenueInput() {
      if (this.value) {
        let isValid = { isValid: true };
        this.value.forEach((shop) => {
          shop.sections.forEach((section) => {
            const inputComponent =
              this.$refs[`revenueInput_${shop.shopId}_${section.sectionId}`];
            if (inputComponent && inputComponent.length > 0) {
              if (inputComponent[0].isValid === false) {
                isValid = {
                  isValid: false,
                  shopName: shop.shopName,
                  validationMessage: inputComponent[0].validationMessage,
                };
              }
            }
          });
        });
        return isValid;
      } else {
        return {
          isValid: false,
          shopName: this.$t("Unknown"),
          validationMessage: this.$t("NoShops"),
        };
      }
    },
    revenueExistsForShop(shopId, sectionId) {
      return revenueService.revenueExistsForShop(shopId, sectionId);
    },
    revenueValuePlaceHolder(shopId, sectionId, isDisabled) {
      return isDisabled
        ? this.$t("IsDisabled")
        : this.revenueExistsForShop(shopId, sectionId)
        ? this.$t("RevenueExists")
        : this.$t("InsertRevenueWithoutVat");
    },
  },
  computed: {
    locale() {
      var locale = this.$i18n.locale;
      return locale;
    },
    showPagination() {
      return this.value?.length > 10;
    },
    revenueDataForTable() {
      return this.filteredShopList ? this.filteredShopList : [];
    },
    filteredShopList() {
      if (this.value?.length === 0 || !this.utcSelectedDate) {
        return null;
      }

      let startOfNextMonth = new Date(
        this.utcSelectedDate.getFullYear(),
        this.utcSelectedDate.getMonth() + 1,
        1
      );
      let startOfThisMonth = new Date(
        this.utcSelectedDate.getFullYear(),
        this.utcSelectedDate.getMonth(),
        1
      );
      return this.value
        ?.filter(
          (shop) =>
            (shop.deactivationDate === null ||
              shop.deactivationDate >= startOfThisMonth) &&
            shop.creationDate < startOfNextMonth
        )
        .filter((shop) => shop.isDisabled === false || this.showDisabledShops)
        .filter(
          (shop) =>
            shop.sections.every((section) =>
              revenueService.revenueExistsForShop(
                shop.shopId,
                section.sectionId
              )
            ) === false || this.showShopsWithData
        )
        .filter((shop) =>
          this.filterPhrase
            .toUpperCase()
            .split(/[ ]+/u)
            .filter((el) => el)
            .every((phrase) =>
              shop.shoppingCenterGroupName
                .concat(shop.shoppingCenterName)
                .concat(shop.shopName)
                .concat(shop.shoppingCenterShopId)
                .toUpperCase()
                .includes(phrase)
            )
        );
    },
  },
  data() {
    return {
      rowsPerPage: 10,
    };
  },
  mounted() {
    this.rowsPerPage =
      localStorageAccess.getRowsPerPage("revenueInput") || this.rowsPerPage;
  },
  updated() {
    if (this.value) {
      this.value.forEach((element) => {
        if (this.$refs[`isDisabledCB${element.shopId}`]) {
          this.$refs[`isDisabledCB${element.shopId}`].$el.children[0].tabIndex =
            -1;
        }
      });
    }
  },
  watch: {
    value: {
      deep: true,
      handler(shopList) {
        this.$emit("input", shopList);
      },
    },
    filteredShopList: {
      handler() {
        this.$emit("shop-list-filtered", this.filteredShopList);
      },
    },
  },
  filters: {
    toLocaleString(value) {
      if (value) return `${value.toLocaleString()}`;
    },
  },
};
</script>
