<template>
  <div class="container">
    <areYouSureBox
      has-modal-card
      :active="this.showAreYouSure"
      @response="areYouSureResponseHandler"
    />
    <form class="section columns">
      <div class="column is-two-fifths">
        <section v-if="this.shop.shopId || this.isNew">
          <b-field :label="$t('ShopId')" v-if="this.isNew === false">
            <b-input :value="shop.shopId" disabled></b-input>
          </b-field>
          <b-field :label="$t('ShopName')">
            <b-input
              required
              expanded
              v-model="shop.shopName"
              :placeholder="canEditShop ? $t('ShopNameInput') : ''"
              :validation-message="
                $t('FieldIsRequired', { fieldName: $t('ShopName') })
              "
              data-test-id="shopNameInput"
              :disabled="canEditShop === false"
              ref="shopName"
            ></b-input>
          </b-field>
          <b-field :label="$t('ChainName')">
            <b-autocomplete
              expanded
              ref="chainAC"
              :placeholder="canEditShop ? $t('ChooseChain') : ''"
              v-model="shop.chainName"
              icon-pack="fas"
              icon="search"
              field="chainName"
              :data="filteredChainName"
              :open-on-focus="true"
              @select="selectChain"
              :disabled="canEditShop === false"
              data-test-id="chainName"
            >
              <template slot="header">
                <a
                class="has-text-info is-size-6"
                @click="displayChainCreation"
                data-test-id="chainCreationModalButton"
                >
                 <span>  {{ $t("CreateChain") }}</span>
                </a>
              </template>
            </b-autocomplete>
          </b-field>
          <b-field
            :label="$t('ShoppingCenterGroupName')"
            v-if="
              shop.shoppingCenterGroupName !== shop.shoppingCenterName &&
                this.isNew === false
            "
          >
            <b-input expanded :value="shop.shoppingCenterGroupName" disabled />
          </b-field>
          <b-field :label="$t('ShoppingCenterName')">
            <b-autocomplete
              expanded
              v-model="shoppingCenterName"
              :placeholder="canEditShop ? $t('ChooseShoppingCenter') : ''"
              icon-pack="fas"
              icon="search"
              :data="shoppingCenterList"
              :custom-formatter="shoppingCenterSelectLabel"
              :open-on-focus="true"
              @select="onShoppingCenterSelected"
              :disabled="canEditShop === false"
              data-test-id="shoppingCenterName"
            ></b-autocomplete>
          </b-field>
          <b-field :label="$t('ContractId')">
            <b-input
              expanded
              required
              v-model="shop.shoppingCenterShopId"
              :placeholder="canEditShop ? $t('ContractInput') : ''"
              :disabled="canEditShop === false"
              data-test-id="shopContractIdInput"
              :validation-message="
                $t('FieldIsRequired', { fieldName: $t('ContractId') })
              "
              ref="shoppingCenterShopId"
            ></b-input>
          </b-field>
        </section>
      </div>
      <div class="column is-two-fifths">
        <section v-if="this.shop.shopId || this.isNew">
          <b-field :label="$t('ChainShopId')">
            <b-input
              expanded
              v-model="shop.chainShopId"
              :placeholder="canEditShop ? $t('ChainShopIdInput') : ''"
              :disabled="canEditShop === false"
            ></b-input>
          </b-field>
          <b-field
          :label="$t('Sections')"
          data-test-id="sectionsWrapper"
          >
            <b-taginput
              v-model="sections"
              :data="translatedSections"
              field="translatedName"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              :closable="canEditShop"
              icon-pack="fas"
              icon="search"
              :placeholder="canEditShop ? $t('ChooseSections') : ''"
              :disabled="canEditShop === false"
              @typing="getTranslatedSections"
              @input="initializeSections"
              data-test-id="sections"
            >
              <template slot="header">
                <a
                class="has-text-info is-size-6"
                @click="displaySectionCreation"
                data-test-id="sectionCreationButton"
                >
                 {{ $t("CreateSection") }}
                </a>
              </template>
            </b-taginput>
          </b-field>
          <notificationGroups
            :canEdit="canEditShop"
            :editableTypes="['Shop']"
            :filterById="this.id"
            v-model="notificationGroups"
            @unauthorize="onUnAuthorizedNotificationGroupChange"
            @create-new-group="displayGroupCreation"
          />
          <b-field :label="$t('ShopCreationDate')">
            <b-datepicker
              required
              v-model="shop.creationDate"
              :placeholder="canEditShop ? $t('ChooseDate') : ''"
              :date-formatter="dateToDisplay"
              :disabled="canEditShop === false"
              data-test-id="shopCreationDate"
              ref="creationDate"
            ></b-datepicker>
          </b-field>
          <b-field :label="$t('ShopDeactivationDate')">
            <b-datepicker
              v-model="shop.deactivationDate"
              :placeholder="$t('ShopIsNotClosed')"
              :date-formatter="dateToDisplay"
              :disabled="canEditShop === false"
              data-test-id="shopDeactivationDate"
            >
              <b-button
                class="button is-danger"
                @click="shop.deactivationDate = null"
              >
                <span>{{ $t("Clear") }}</span>
                <b-icon pack="fas" icon="times"></b-icon>
              </b-button>
            </b-datepicker>
          </b-field>
          <b-field grouped position="is-right">
            <b-field>
              <b-button @click="cancelUpdate">
                {{ canEditShop ? $t("Cancel") : $t("Return") }}
              </b-button>
            </b-field>
            <b-field v-if="canEditShop">
              <b-button
                data-test-id="shopDetailsSubmit"
                @click="startProcessing"
                :disabled="isProcessing"
              >
                {{ $t("Submit") }}
              </b-button>
            </b-field>
          </b-field>
        </section>
      </div>
    </form>
    <section
      data-test-id="GroupRelatedMembers"
      class="section"
      v-if="relatedMembersList.length > 0"
    >
      <p class="title">{{ $t("Members") }}</p>
      <memberAdministrationDetails
        :membersList="this.membersAdministrationListDetails"
        :isProcessing="this.isProcessing"
        :canEdit="false"
        :showRelatedItems="false"
      />
    </section>
    <b-modal
        data-test-id="GroupCreationModal"
        v-model="createGroup"
        :can-cancel="true"
        has-modal-card aria-modal
      >
      <template>
        <notificationGroupModal :id="this.id" />
      </template>
    </b-modal>
        <b-modal
        v-model="createChain"
        :can-cancel="true"
        has-modal-card aria-modal
      >
      <template>
        <chainCreationModal
        @chain-created="chainCreated"
        />
      </template>
    </b-modal>
      <b-modal
        v-model="createSection"
        :can-cancel="true"
        has-modal-card aria-modal
      >
      <template>
        <sectionCreationModal
        @section-create-completed="sectionCreated"
        :shoppingCenterGroupId="this.shop.shoppingCenterGroupId"
        :canEditShop="this.canEditShop"
        />
      </template>
    </b-modal>
  </div>
</template>
<script>
import shopsService from "@/services/shopsService";
import * as Sentry from "@sentry/browser";
import areYouSureBox from "@/components/AreYouSureBox";
import notificationGroups from "@/components/notifications/NotificationGroupsMulti";
import notificationService from "@/services/notificationService";
import membersService from "@/services/membersService";
import memberAdministrationDetails from "@/components/members/memberAdministrationDetails";
import notificationGroupModal from "@/components/modals/notificationGroupModal";
import chainCreationModal from "@/components/modals/chainCreationModal";
import sectionCreationModal from "@/components/modals/sectionCreationModal";
import routerUtils from "@/utils/routerUtils";
import { mapGetters } from "vuex";

export default {
  props: {
    id: Number,
    returnTo: {
      type: String,
      default: "/Shops/admin"
    }
  },
  components: {
    areYouSureBox,
    notificationGroups,
    memberAdministrationDetails,
    notificationGroupModal,
    chainCreationModal,
    sectionCreationModal
  },
  async mounted() {
    if (this.shopList === null || this.shopList.length === 0) {
      await shopsService.getAllowedShopsList();
    }
    if (
      this.shoppingCenterGroupsList === null ||
      this.shoppingCenterGroupsList.length === 0
    ) {
      shopsService.setShoppingCenterGroupsList();
    }
    if (this.shopList.some(shop => shop.canEditShop === true)) {
      if (this.chainsList === null) {
      await shopsService.getChains();
      }
      if (this.sectionsList === null || this.sectionsList.length === 0) {
        shopsService.getSections().then(() => {
          this.initializeSections();
        });
      }
      if (
        this.notificationGroupList === null ||
        this.notificationGroupList.length === 0
      ) {
        notificationService.getNotificationGroups();
      }
      if (this.membersList === null || this.membersList.length === 0) {
        membersService.getMembersList();
      }
      if (this.strategiesList === null || this.strategiesList.length === 0) {
        notificationService.getStrategies();
      }
    }
    if (this.id) {
      this.getShopToEdit();
      this.instilizeChain();
    } else {
      this.createNewShop();
    }
  },
  data() {
    return {
      shopName: "",
      shoppingCenterName:'',
      isNew: false,
      shop: {},
      sections: [],
      processing: false,
      showAreYouSure: false,
      translatedSections: [],
      originalShop: {},
      notificationGroups: [],
      shopUpdated: false,
      createGroup: false,
      createChain: false,
      createSection: false,
    };
  },
  computed: {
    ...mapGetters({
      shopList: "getAllowedShopsList",
      notificationGroupList: "getNotificationGroups",
      membersList: "getMemberList",
      strategiesList: "getStrategies",
      chainsList: "getChains",
      sectionsList: "getSections",
      shoppingCenterGroupsList: "getShoppingCenters"
    }),
    isProcessing() {
      return this.processing || this.shopList === null;
    },
    canEditShop() {
        return this.shop.canEditShop;
    },
    shoppingCenterList() {
      var list = this.shoppingCenterGroupsList?.filter(option => {
        if (this.shoppingCenterName === undefined) {
          return true;
        }
        return (
          this.shoppingCenterSelectLabel(option)
            .toString()
            .toLowerCase()
            .indexOf(this.shoppingCenterName.toLowerCase()) >= 0
        );
      });
      return this.shoppingCenterGroupsList ? list : [];
    },
    sectionList() {
      const sectionsData = this.sectionsList
        ? this.sectionsList
        : this.shop.sections;
      return sectionsData
        .map(section => ({ ...section, translatedName: section.sectionName }))
        .filter(
          section =>
            this.sections.some(
              selectedSection => selectedSection.sectionId === section.sectionId
            ) === false
        );
    },
    shoppingCenterGroupList() {
      if (this.shopList) {
        return [
          ...new Map(
            this.shopList.map(shop => [shop.shoppingCenterGroupName, shop])
          ).values()
        ];
      }
      return [];
    },
    filteredChainName() {
      var filteredChains = [];
      if (this.shop.chainName) {
        filteredChains = this.chainsList?.filter(option =>
          option.chainName
            .toString()
            .toLowerCase()
            .includes(this.shop.chainName?.toLowerCase())
        );
      }
      return this.shop.chainName ? filteredChains : this.chainsList;
    },
    relatedMembersList() {
      var membersRelated = [];
      if (this.membersList) {
        let relatedGroupIds = this.notificationGroups.map(
          group => group.groupId
        );
        membersRelated = this.membersList
          ? this.membersList.filter(member =>
              relatedGroupIds.includes(member.groupId)
            )
          : [];
      }
      return membersRelated;
    },
    membersAdministrationListDetails() {
      let data = membersService.prepareMembersDetails(
        this.relatedMembersList,
        this.notificationGroups,
        this.strategiesList
      );
      return data;
    }
  },
  methods: {
    selectChain(option) {
      if (option) {
        this.shop.chainId = option.chainId;
      } else {
        this.shop.chainId = null;
      }
    },
    instilizeChain(){
      const chainRelated = this.chainsList ?this.chainsList.find(chain => chain.chainId === this.shop.chainId):{};
      if( this.shop.chainName !== chainRelated.chainName){
       this.shop.chainName = chainRelated.chainName;
      }
    },
    onShoppingCenterSelected(shoppingCenter) {
      this.shop.shoppingCenterId = shoppingCenter
        ? shoppingCenter.shoppingCenterId
        : 0;
      this.getTranslatedSections("");
       let validCenterDetails = shoppingCenter
        ? this.shoppingCenterGroupsList.find(i =>
            i.shoppingCenterId === shoppingCenter.shoppingCenterId
          )
        : "";
      if (validCenterDetails) {
        this.shop.shoppingCenterName = validCenterDetails.shoppingCenterName;
        this.shop.shoppingCenterGroupName = validCenterDetails
          ? validCenterDetails.shoppingCenterGroupName
          : this.$t("MissingShoppingCenterGroupName");
      }
    },
    initializeSections() {
      this.translatedSections = this.sectionList.filter(
        option =>
          this.shop.shoppingCenterId === undefined ||
          this.shopList.find(
            s => s.shoppingCenterId === this.shop.shoppingCenterId
          )?.shoppingCenterGroupId === option.shoppingCenterGroupId
      );
    },
    getTranslatedSections(text) {
      this.translatedSections = this.sectionList.filter(
        option =>
          option.translatedName
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0 &&
          (this.shop.shoppingCenterId === undefined ||
            this.shopList.find(
              s => s.shoppingCenterId === this.shop.shoppingCenterId
            )?.shoppingCenterGroupId === option.shoppingCenterGroupId)
      );
    },
    initializeSectionsAndGroups(){
      const sectionsToSend = this.sections
        .map(section => [section.sectionId])
        .reduce(
          (sectionArr, sectionItem) => sectionArr.concat(sectionItem),
          []
        );
      this.shop.sections = sectionsToSend;
      const notificationGroupsToSend = this.notificationGroups
        .filter(grp => grp.groupType === "Shop")
        .map(grp => [grp.groupId])
        .reduce((grpArr, grpItem) => grpArr.concat(grpItem), []);
      this.shop.notificationGroups = notificationGroupsToSend;
    },
    startProcessing() {
      if (this.processing === false) {
        this.processing = true;
        this.initializeSectionsAndGroups();
        const validationResults = shopsService.validateUpdateShop(this.shop, [this.$refs.shopName,this.$refs.shoppingCenterShopId, this.$refs.creationDate]);
        if (validationResults.length > 0) {
          this.processing = false;
          const translatedErrors = validationResults
          .map(validator => this.$t(validator))
          .join(", ");
          this.openNotification(translatedErrors, "is-danger");
        }else{
         this.showAreYouSure = true;
        }
      }
    },
    async areYouSureResponseHandler(action) {
      if (this.showAreYouSure === true) {
        this.showAreYouSure = false;
        try {
          if (action === "yes") {
            await this.sendUpdate();
          }
        } finally {
          this.processing = false;
        }
      }
    },
    shopSelectLabel(shop) {
      if (this.shopList) {
        return `${shop.shopName} ${shop.shoppingCenterName} ${shop.shoppingCenterShopId}`;
      }
      return "";
    },
    dateToDisplay(date) {
      if (date) {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      }
    },
    shoppingCenterSelectLabel(shop) {
      if (this.shopList) {
        if (shop.shoppingCenterGroupName === shop.shoppingCenterName) {
          return shop.shoppingCenterName;
        }
        return `${shop.shoppingCenterGroupName} ${shop.shoppingCenterName}`;
      }
      return "";
    },
    cancelUpdate() {
      routerUtils.moveTo(this.returnTo);
    },
    sendUpdate() {
      shopsService
        .postShop(this.shop)
        .then(result => {
          this.shop.sections = this.sections;
          this.openNotification(this.$t("SuccessShopUpdated"), "is-success");
          notificationService.getNotificationGroups();
          if (this.isNew) {
            let shopId = result.data.shopId;
            this.shop.shopId = shopId;
            shopsService.addNewShop(this.shop);
          }
          this.shopUpdated = true;
          return Promise.resolve("done");
        })
        .then(() => {
          routerUtils.moveTo(this.returnTo);
        })
        .catch(error => {
          if (error?.response && error.response.status === 409) {
            Object.getOwnPropertyNames(error.response.data?.errors).forEach(err => {
              this.openNotification(this.$t(err), "is-danger");
            });
          } else {
            this.openNotification(this.$t("ErrorContactUs"), "is-danger");
            Sentry.captureException(error);
          }
          return Promise.resolve("done");
        });
    },
    createNewShop() {
      this.shop = {
        canEditShop: true,
        shopName: "",
        chainId: 0,
        chainShopId: "",
        creationDate: new Date(),
        deactivationDate: null,
        isDisabled: false,
        sections: [],
        shoppingCenterShopId: "",
        shoppingCenterId: 0
      };
      this.sections = this.shop.sections;
      this.isNew = true;
      this.initializeSections();
    },
    openNotification(message, type) {
     let autoCloseProps = {};
      if(type === "is-success"){
        autoCloseProps = {
          autoClose: true, 
          duration: 5000,
        }
      }
      this.$buefy.notification.open({
        message,
        type,
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        ...autoCloseProps,
        "data-test-id": "shopValidationMessage"
      });
    },
    resetShop() {
      this.shop = {};
      this.isNew = false;
      this.shopUpdated = false;
      (this.sections = []),
        (this.notificationGroups = []),
        (this.shopName = "");
    },
    onUnAuthorizedNotificationGroupChange(groupDetails) {
      this.openNotification(
        this.$t("UnAuthorizeNotificationGroup", {
          groupName: groupDetails.groupName
        }),
        "is-danger"
      );
    },
    revertToOriginalShop() {
      Object.assign(this.shop, this.originalShop);
    },
    getShopToEdit() {
      this.shop = this.shopList
        ? this.shopList.find(x => x.shopId == this.id)
        : [];
      this.onShopSelected(this.shop);
    },
    mapSections(){
        this.sections = this.shop.sections
          ? this.shop.sections.map(section => ({
              ...section,
              translatedName: section.sectionName
            }))
          : [];
        this.getTranslatedSections("");
    },
    onShopSelected(shop) {
      if (this.shopList) {
        this.shop = shop;
        this.originalShop = { ...this.shop };
        this.mapSections();
        this.shoppingCenterName = this.shop.shoppingCenterName;
      }
    },
    displayGroupCreation() {
      this.createGroup = true;
    },
    displayChainCreation(){
      this.createChain = true;
    },
    displaySectionCreation(){
      this.createSection = true;
    },
    chainCreated(selectedChain){
        this.shop.chainId = selectedChain.chainId;
        this.shop.chainName = selectedChain.chainName;
    },
    sectionCreated(newSection){
      this.shop.sections.push(newSection);
      this.mapSections();
    },
  },
  beforeDestroy() {
    if (!this.shopUpdated) {
      this.revertToOriginalShop();
    }
    this.resetShop();
  },

  watch: {
    notificationGroupList: {
      immediate: true,
      handler() {
        this.notificationGroups = this.notificationGroupList
          ? this.notificationGroupList.filter(group =>
              group.shopIds.includes(this.id)
            )
          : [];
      }
    }
  }
};
</script>
