export default {
    '-': '-',
    AdditionalContactDetails:'Additional Contact Details',
    All: 'All',
    AllShopsReported: 'All the shops has been reported this mounth',
    AppleFixAndTrain: 'Repair and tutorials for Apple devices',
    AppleSmartphones: 'Apple smartphones',
    AreYouSure: 'Are You Sure?',
    Attention: 'Attention',
    AuthGeneralError: 'Could not login. Please try again, if the problem continues please contact the office',
    BasicValidator: 'Validation failed',
    Cancel: 'Cancel',
    CanEditChainGroupValidator: 'Insufficient permissions to edit chain group',
    CanEditChainValidator: 'Insufficient permissions to edit chain',
    CanEditNotificationGroupValidator: 'Insufficient permissions to edit notification group',
    CanEditRole: 'Cannot edit this notification group',
    CanEditSectionValidator: 'Insufficient permissions to edit section',
    CanEditShoppingCenterValidator: 'Insufficient permissions to edit Shopping center',
    CanEditShopValidator: 'Insufficient permissions to edit shop',
    CanEdittenantValidator: 'Insufficient permissions to edit tenant',
    CannotOverwrite: 'You cannot overwrite revenue of shop {identifierDescription} after {relatedFailureParameter} days',
    CannotRemoveGeneralSection: 'You cannot remove the general section',
    Cellular: 'Cellular',
    Chain: 'Chain',
    ChainExternalId: 'Chain External Id',
    ChainGroupId: 'Chain Group Id',
    ChainGroupName: 'Chain Group Name',
    ChainId: 'Chain No.',
    ChainName: 'Chain Name',
    ChainShopId: 'Chain Shop Id',
    ChainShopIdInput: 'Insert Shop Number',
    ChainShopIdMatchShopIdValidator: 'Chain shop Id does not match shop',
    ChooseChain: 'Choose a chain',
    ChooseChainGroup: 'Choose chain group',
    ChooseChainGroupFromList: 'Please choose the chain group from the list',
    ChooseChainOrLeaveEmpty: 'Choose a chain or leave empty',
    ChooseDate: 'Choose a Date',
    ChooseGroup: 'Choose a Group',
    ChooseMember: 'Choose a tenant',
    ChooseNotificationStrategy: 'Choose notification strategy',
    ChooseSections: 'Choose Sections',
    ChooseShop: 'Choose a shop',
    ChooseShoppingCenter: 'Choose a shopping center',
    CibusCards: 'Cibus Card',
    Cigarettes: 'Cigarettes',
    Clear: 'Clear',
    ClickToUpload: 'Click To Upload',
    Close: 'Close',
    CloseNotification: 'Close Notification',
    Comment: 'Comment',
    CompaniesLogo: '',
    Computers: 'Computers',
    ComputersAndAppleCellular: 'Apple Computers and phones',
    ComputersAndCellular: 'Computers and Phones',
    ConflictedRevenueHeader: 'Conflicting Revenues',
    ContactForm:'Contact Form',
    ContactFormsubmitSuccess:'Form submitted successfully',
    ContactFormSubTitle:'Please fill up the details and we will back to you as soon as possible',
    ContactUs: 'Contact us',
    ContractId: 'Contract Id',
    ContractInput: 'Insert contract number',
    CreateChain: 'Add New Chain',
    CreateGroup: 'Add New Group',
    CreateSection: 'Add New Section',
    CreationDateIsBiggerThanDeactivationDate: 'Creation Date Is Bigger Than Deactivation Date',
    Deliveries: 'Deliveries',
    DetailedDescription:'Detailed Description',
    DialCards: 'Dial Cards',
    DicountedProducts: 'Discounted Products',
    DiscountedProducts: 'Discounted Products',
    DIY: 'DIY',
    DoesNotBelongToGroup: 'Not Grouped',
    DownloadTemplate: 'Download XLSX Template',
    Drinks: 'Drinks',
    Duplicate: 'Shop exists, Please check shop name or contract number',
    Edit: 'Edit',
    EditGroup: 'Edit group',
    Electronics: 'Electronics',
    Email: 'Email',
    EmailInvalid: 'Invalid Email',
    EmailOrPhone: 'Email or Phone number',
    EmailOrPhoneValidationMessage: 'Please insert mail or Mobile phone number',
    EmailValidationMessage: 'Please enter a valid email',
    EnterShopRevenue: 'Enter Shop Revenue',
    EnterYourCode: 'Please enter the code here',
    ErrorContactUs: 'Error occured - please contact us',
    ErrorContactUsPage:'Error occured - You can alternatively contact us at email or phone that appears on additional contact details section',
    ErrorEmptyFile: 'File is empty',
    ErrorMixedDates: 'Sending multiple dates is not possible. The dates are {relatedFailureParameter}. For shops  {identifierDescription}',
    ErrorMonthOutOfRange: 'Shop {identifierDescription} contain revenue for invalid Month {relatedFailureParameter}',
    ErrorNegativeRevenueIntegerValue: 'Shop {relatedFailureParameter} contain negative revenue. You cannot upload negative values',
    ErrorNoMonth: 'Month column does not exists',
    ErrorNoRevenue: 'Revenue column does not exists',
    ErrorNoShopName: 'Shop Name column does not exists',
    ErrorNoYear: 'Year column does not exists',
    ErrorNullRevenueIntegerValue: 'Shop {relatedFailureParameter} does not contain revenue value',
    ErrorShopNotFound: 'Shop {relatedFailureParameter} cannot be found',
    ErrorYearOutOfRange: 'Shop {identifierDescription} contain revenue for invalid Year {relatedFailureParameter}',
    false: 'False',
    Fashion: 'Fashion',
    Feedback:'Feedback',
    FieldIsInvalid: 'Field {fieldName} is invalid',
    FieldIsRequired: 'Field {fieldName} is required',
    FoodProducts: 'Food Products',
    FormInvalid:'Please fix issues in the form',
    FranchiseSales: 'Franchise sales',
    Gambling: 'Lottery',
    GeneralQuestion:'General Question',
    GeneralSection: 'General',
    Gifts: 'Gifts',
    Grocery: 'Grocery',
    GroupDescription: 'Group description',
    GroupDescriptionInput: 'Please fill the group description',
    GroupId: 'Group Id',
    GroupName:'Group Name',
    GroupNameInput: 'Please fill the group name',
    GroupRelatedMembers: 'Number of related tenants',
    GroupRelatedMembersDisclaimer: 'The system is counting only the tenants you are permitted to see.',
    GroupRelatedShops: 'Number of related shops',
    GroupRelatedShopsDisclaimer: 'The system is counting only the shops you are permitted to see.',
    ImportRevenues: 'Revenues',
    InsertRevenue: 'Insert Revenue',
    InsertRevenueWithoutVat: 'Insert revenue without VAT',
    IsDisabled: 'Shop Closed',
    Issue:'Issue',
    Level1: 'Chains',
    Level1MailAndPhone: 'First level mail and phone notifications',
    Level1MailOnly: 'First level only mail notifications',
    Level1PhoneOnly: 'First level only phone notifications',
    Level2: 'Private Shops',
    Level2MailAndPhone: 'Second level mail and phone notifications',
    Level2MailOnly: 'Second level only mail notifications',
    Level2PhoneOnly: 'Second level only phone notifications',
    Level3: 'Managers',
    Level3MailAndPhone: 'Third level mail and phone notifications',
    Level3MailOnly: 'Third level only mail notifications',
    Level3PhoneOnly: 'Third level only phone notifications',
    Level4: 'Fourth level',
    Level5: 'Fifth level',
    Level5MailAndPhone: 'Fifth level mail and phone notifications',
    Level5MailOnly: 'Fifth level only mail notifications',
    Level5PhoneOnly: 'Fifth level only phone notifications',
    Lights: 'Lights',
    Loading: 'Loading',
    Login: 'Log In',
    LogOut: 'Log Out',
    LogoutWarningAction: 'ok',
    LogoutWarningText: 'You are about to be logged out in less than 5 minutes. To keep using the system please login again',
    Machines: 'Machines',
    Main: 'Home',
    Medicine: 'Medicine',
    Member: 'Tenant',
    MemberCommentInput: 'Please insert comment',
    MemberEmail: 'Email',
    MemberEmailInput: 'Please insert email',
    MemberId: 'Tenant No.',
    MemberName: 'Tenant Name',
    MemberNameInput: 'Insert tenant Name',
    MemberPhoneNumber: 'Cell phone number',
    MemberPhoneNumberInput: 'Please insert cell phone number',
    MemberPhoneNumberInvalid: 'Phone number is not valid',
    MemberPosition: 'Tenant Role',
    MemberPositionInput: 'Please insert tenant role',
    Members: 'Tenants',
    MembersPerPage: 'Number of tenants per page',
    MezuzahCards: 'Mezuzah Cards',
    MissingChainId: 'Missing chain Id',
    MissingChainName: 'Missing chain Name',
    MissingContractId: 'Missing contract Id',
    MissingDataValidator: 'Missing data',
    MissingEmailAndPhone: 'Fill out phone or email',
    MissingEmailForNotifyEmail:'Missin email for notification on email',
    MissingGeneralSection: 'Missing general section',
    MissingGroupName: 'Missing notification Group Name',
    MissingMemberName: 'Missing tenant name',
    MissingNotificationGroup:'Missing notification Group',
    MissingNotificationStrategy: 'Missing notification Strategy',
    MissingPhoneForNotifyPhone:'Missing phone number for notification on phone',
    MissingRevenueHeader: 'Missing Revenues',
    MissingSection: 'Missing Section',
    MissingSectionExternalId:'Missing section external id',
    MissingSectionName:'Missing section name',
    MissingShopName: 'Missing shop name',
    MissingShoppingCenterGroupName: 'No Shopping center group name',
    MissingShoppingCenterId: 'Missing shopping center',
    Month: 'Month',
    MonthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    Moses1: 'Moses 1',
    Moses2: 'Moses 2',
    MosesDelta: 'Moses Delta',
    NewGroup:'New group',
    No:'No',
    NoDataToShow: 'No data to show',
    NoItemsToShow: 'No items to show',
    NoNotifications: 'No notifications',
    NoPhonenumber: 'No phone number',
    NormalUser: 'Normal User',
    NoShops: 'No shops to show',
    NotAppleProducts: 'Not apple products',
    NotEmptyValidator: 'Field should not be empty',
    NotificationGroup: 'Notification Group',
    NotificationGroupDoesNotBelongToShoppingCenterValidator: 'Notification group cannot be attached to shop as it already attached to shopping center',
    NotificationGroupId: 'Notification Group Id',
    NotificationGroups: 'Notification Groups',
    NotificationGroupsPerPage: 'Number of groups per page',
    NotificationGroupTypeColorLegend: 'The Colors indicate the group type. Blue is for shop level, Red is for shopping center level',
    NotificationStrategy: 'Notification strategy',
    NotificationStrategyInvalid: 'Notification strategy invalid',
    NotifyOn:"Notify On",
    NotMandatory: 'Not Mandatory',
    Only4DecimalAllowed: 'Please insert a number with up to 4 decimal point',
    OnlyIntegerAllowed: 'Please insert an Integer',
    OperationSucceded: 'Action succeeded',
    Paper: 'Paper',
    PaperAndCellular: 'Paper and Phones',
    Password: 'Password',
    PermissionValidator: 'You do not have sufficient permissions for this action',
    Pharm: 'Pharm',
    Phone:"Phone",
    PhoneNumber: 'Cell phone number',
    PhoneNumberInput: 'Please insert cell phone number',
    PhoneNumberInvalid: 'Phone number is not valid',
    PostRevenueSucceed: 'Revenue uploaded successfully',
    PropertyIdInput: 'Insert property Number',
    PropertyNumber: 'property Number',
    Reason: 'Reason',
    RelevantGroups: 'Relevant Groups for {callerEntityDetails} - There might be other groups you are not permitted to see', 
    RelevantMembers: 'Relevant tenants for {callerEntityDetails} - There might be other tenants you are not permitted to see',
    RelevantShops: 'Relevant Shops for {callerEntityDetails} - There might be other shops you are not permitted to see', 
    Reports: 'Reports',
    Request:'Request',
    Retailers: 'Retailers',
    Return: 'Return',
    Revenue: 'Revenue',
    RevenueExists: 'Revenue exists in the system',
    RevenueInputHelp: 'For Help Press Here',
    RevenueInteger: 'Revenue in Integers',
    RevenueMenuRoot: 'Revenue',
    RevenueRecieved: 'Revenue Recieved',
    RevenueResponseSummaryHeader: 'Sent Revenues Status',
    RevenueWithoutVat: 'Revenue Without Vat',
    Search: 'Search',
    SectionBelongToShoppingCenterGroupValidator: 'Section does not belong to the selected shopping center group',
    SectionExistsValidator: 'Section does not exists',
    SectionExternalId:'Section External Id',
    SectionExternalIdInput:'Insert section external id',
    SectionId: 'Section Id',
    SectionName: 'Section Name',
    SectionNameInput:'Insert section name',
    Sections: 'Sections',
    SelectMonth: 'Select a month',
    SelectName: 'Please Choose',
    SelectNotificationGroup: 'Select notification group',
    SelectShop: 'Select shop',
    SelectShoppingCenter: 'Select shopping center',
    SelectShoppingGroup: 'Select shopping centers group',
    SelectSubject:'Please select a subject',
    SelectYear: 'Select a year',
    Send: 'Send',
    SendEmailLoginSucceed: 'Please check your mail !',
    SendRevenueWithoutVat: 'Send revenue without VAT',
    Shop: 'Shop',
    ShopCreationDate: 'Shop Creation Date',
    ShopDeactivated: 'Shop is reported as closed',
    ShopDeactivationDate: 'Shop Deactivation Date',
    ShopDisabled: 'Shop marked as closed',
    ShopId: 'Shop number',
    ShopIsNotClosed: 'Shop is not closed',
    ShopIsNotPartOfChain: 'Shop is not part of a chain',
    ShopName: 'Shop Name',
    ShopNameInput: 'Insert shop name',
    ShoppingCenter: 'Shopping Center',
    ShoppingCenterGroup: 'Shopping Center Group',
    ShoppingCenterGroupName: 'Shopping Center Group Name',
    ShoppingCenterName: 'Shopping Center Name',
    ShoppingCenters: 'ShoppingCenters',
    ShopRelatedGroups: 'Notification Groups',
    ShopRelatedMembers: 'Tenants',
    Shops: 'Shops',
    ShopsPerPage: 'Number of shops in a page',
    ShowDisabledShops: 'Show Disabled Shops',
    ShowLess:'Show Less',
    ShowMore:'Show More',
    ShowShopsWithData: 'Show Shops With Data',
    SignIn: 'Sign In',
    SignUpSignIn: 'Sign up/in',
    Sms:"SMS",
    Subject:'Subject',
    Submit: 'Submit',
    SuccessChainUpdated: 'Chain updated successfuly',
    SuccessGroupUpdated: 'Group updated successfuly',
    SuccessMemberUpdated: 'Tenant updated successfuly',
    SuccessRevenueHeader: 'Succeeded Revenues',
    SuccessSectionUpdated: 'Section created successfuly',
    SuccessShopUpdated: 'Shop updated successfuly',
    SystemGenerated: 'System generated',
    Technology: 'Technology',
    TermsAndConditionPath: '/assets/tc/TChe.jpg',
    Title:'Title',
    Toys: 'Toys',
    true: 'True',
    UnAuthorizeNotificationGroup: 'UnAuthorized action for group {groupName}',
    UniqueChainGroupNameValidator: 'Chain group already exists',
    UniqueChainNameValidator: 'Chain name already exists',
    UniqueChainShopIdValidator: 'Chain shop Id already exists',
    UniqueEmailValidator: 'Email already exists',
    UniqueNotificationGroupNameValidator: 'Notification Group already exists',
    UniquePhoneNumberValidator: 'Phone number already exists',
    UniqueRoleName: 'The Notification group name must be unique',
    UniqueSectionValidator: 'Section already exists',
    UniqueShopNameValidator: 'Shop name already exists',
    UniqueShoppingCenterShopIdValidator: 'Shopping center shop Id already exists',
    Unknown: 'Unknown',
    UploadFileToolTip: 'Press for upload file instructions',
    Username: 'User Name',
    Validator: 'Error occured - please contact us',
    WoltApplication: 'Wolt Application',
    Year: 'Year',
    Yes: 'Yes',
  };