<template>
	<b-field :label="$t('NotificationStrategy')">
		<b-select
			:placeholder="$t('ChooseNotificationStrategy')"
			v-model="selectedStrategy"
			expanded
			:disabled="canEdit === false"
			data-test-id="notificationStrategy"
		>
			<option
				v-for="strategy in strategiesList"
				:key="strategy.id"
				:value="strategy.id"
			>
				{{ `${$t(strategy.name)}` }}
			</option>
		</b-select>
	</b-field>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: ["value", "canEdit"],
	data() {
		return {
			selectedStrategy: 2,
		};
	},
	mounted() {
		if (this.value) {
			this.selectedStrategy = this.value;
		}
	},
	computed: {
		...mapGetters({
			strategiesList: "getStrategies",
		}),
	},
	watch: {
		selectedStrategy: {
			immediate:true,
			handler() {
					this.$emit("input", this.selectedStrategy);
			},
		},
	},
};
</script>
