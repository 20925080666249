import request from '@/utils/request';
import store from '@/store';
import validationUtil from "@/utils/validationUtil";

const shopsService = {
  async getAllowedShopsList(month, year) {
    const path = '/shops/GetShops';

    const result = await request.get(process.env.VUE_APP_REVENUE_API_URL + path, month ? { month, year } : undefined);
    store.commit('allowedShopsList', {
      shopsList: result,
    });
    return result;
  },
  async postShop(body) {
    const path = '/shops/UpdateShop';
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    return result;
  },
  async getChains() {
    const path = '/shops/GetChains';
    const result = await request.get(process.env.VUE_APP_REVENUE_API_URL + path);
    store.commit('setChains', {
      chainsList: result,
    });
    return result;
  },
  async getSections() {
    const path = '/shops/GetSections';
    const result = await request.get(process.env.VUE_APP_REVENUE_API_URL + path);

    store.commit('setSections', {
      sectionsList: result,
    });
    return result;
  },
  getShopFromCollection(shopsCollection, shopToFind) {
    const ShopFromCollection = shopsCollection.find((shopItem) => shopItem.shopName === shopToFind.ShopName
      && shopItem.shoppingCenterName === shopToFind.ShoppingCenterName
      && (shopItem.chainShopId === '' || shopItem.chainShopId === null || shopItem.chainShopId.toString() === shopToFind.ChainShopId?.toString())
      && shopItem.sections.find((section) => section.sectionId.toString() === shopToFind.SectionId.toString()));
    return ShopFromCollection;
  },
  async setShopActivation(shopId, isActive) {
    const path = '/shops/SetShopActivation';
    const body = { shopId, isActive };
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    if (result.status >= 200 && result.status < 300) {
      return true;
    }

    return false;
  },
  addDetailsToShopSection(shopList, shopSection, overrideInfo) {
    shopList.forEach((shop) => {
      shop.sections.forEach((section) => {
        const shopDetails = shopSection.find((sd) => sd.shopId === shop.shopId && sd.sectionId === section.sectionId);

        if (shopDetails) {
          section.updateSucceed = shopDetails.updateSucceed;
          section.relatedFailureParameter = shopDetails.relatedFailureParameter;
          section.failureReason = shopDetails.failureReason;
        }
        if (overrideInfo.length !== 0) {
          const extraDetails = overrideInfo.revenues.find((sd) => sd.shopId === shop.shopId && sd.sectionId === section.sectionId);
          section.revenueValue = extraDetails?.revenueValue;
        }
      });
    });
    return shopList;
  },
  validateUpdateShop(shop, fields) {
    const errors = [];
    const fieldsValidationResult = validationUtil.checkFieldValidity(fields);

    if(!fieldsValidationResult){
      errors.push('FormInvalid');
    }
    if (shop.chainId === 0) {
      errors.push('MissingChainId');
    }
    if (shop.deactivationDate !== null && shop.creationDate > shop.deactivationDate) {
      errors.push('CreationDateIsBiggerThanDeactivationDate');
    }
    if (shop.sections === null || shop.sections.length === 0) {
      errors.push('MissingSection');
    }
    if (shop.shoppingCenterId === 0) {
      errors.push('MissingShoppingCenterId');
    }
    return errors;
  },
  prepareShopsData(shopsList, groupList, memberList, chainsList) {
    const distincShopsList = [];
    if (shopsList ) {
      shopsList.forEach((element) => {
        const shopExists = distincShopsList.find(
          (g) => g.shopId === element.shopId
        );
        const relatedGroups = groupList?.filter(group => group.shopIds.includes(element.shopId))?.map(item => ({ groupId: item.groupId }))
          ?? []
        const relatedMembers = memberList?.filter(member => relatedGroups.some(g => g.groupId === member.groupId))?.map(item => ({ memberId: item.memberId }))
          ?? []
        const creationDate = element.creationDate ?
          new Date(element.creationDate).toLocaleDateString()
          : ""
        const deactivationDate = element.deactivationDate ?
          new Date(element.deactivationDate).toLocaleDateString()
          : "-"
        const chainRelated = chainsList ? chainsList.find(chain => chain.chainId === element.chainId): null;
        if (shopExists === undefined) {
          distincShopsList.push({
            shopId: element.shopId,
            shopName: element.shopName,
            chainName: chainRelated?.chainName,
            shoppingCenterGroupName: element.shoppingCenterGroupName,
            shoppingCenterName: element.shoppingCenterName,
            sections: element.sections,
            creationDate: creationDate,
            deactivationDate: deactivationDate,
            chainShopId: element.chainShopId,
            shoppingCenterShopId: element.shoppingCenterShopId,
            groupsRelated: relatedGroups,
            membersRelated: relatedMembers,
            canEditShop: element.canEditShop
          });
        }
      });
    }
    return distincShopsList;
  },
  canCreateShops(shopList) {
    return shopList?.some(shop => shop.canEditShop);
  },
  addNewShop(shopToUpdate) {
    store.commit('addOrUpdateAllowedShopsList', { updatedShop: shopToUpdate });
  },
  setShoppingCenterGroupsList() {
    const shopsList = store.getters.getAllowedShopsList;
    const filterdedShoppingCenterInfo = [
      ...new Map(
        shopsList.map((item) => {
          return {
            shoppingCenterId: item.shoppingCenterId,
            shoppingCenterName: item.shoppingCenterName,
            shoppingCenterGroupName: item.shoppingCenterGroupName,
          };
        })
          .map((item) => [item.shoppingCenterName, item])
      ).values(),
    ];
    store.commit('setShopingCenters', { shoppingCenters: filterdedShoppingCenterInfo });
  },
  prepareChainDetails(chainsList, shopList, groupList) {
    const distinctChainsList = [];
    if (chainsList) {
      chainsList.forEach((element) => {
        const chainExists = distinctChainsList.find(
          (g) => g.chainId === element.chainId
        );
        const relatedShops = shopList?.filter(i => i.chainId === element.chainId)
          ?.map(item => ({ shopId: item.shopId })) ?? [];
        const relatedGroups =  groupList?.filter(group => group.shopIds.some(shop=> relatedShops.some(i=> i.shopId === shop)))
          ?? [];
        if (chainExists === undefined) {
          distinctChainsList.push({
            chainId: element.chainId,
            chainName: element.chainName,
            chainExternalId: element.chainExternalId,
            chainGroupId: element.chainGroupId,
            chainGroupName: element.chainGroupName,
            shopsRelated: relatedShops,
            groupsRelated: relatedGroups,
          });
        }
      });
    }
    return distinctChainsList;
  },
  validateChainData(fields) {
    const erorrs = [];
    const checkFieldValidity = validationUtil.checkFieldValidity(fields);

    if(!checkFieldValidity){
      erorrs.push('FormInvalid');
    }
    return erorrs;
  },
  async postChain(body) {
    const path = '/shops/UpdateChain';
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    return result;
  },
  createChain(chain) {
    store.commit('extendChainsList', { newChain: chain });
  },
  validateSectionData(fields){
    const errors = [];
    const checkFieldValidity = validationUtil.checkFieldValidity(fields);
    if(!checkFieldValidity){
      errors.push('FormInvalid');
    }
    return errors
  },
  async postSection(body) {
    const path = '/shops/UpdateSection';
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    return result;
  },
  createSection(section){
    store.commit('extendSectionsList', { newSection: section });
  },
  prepareSectionDetails(sectionsList, shoppingCentersList) {
    const distinctSectionsList = [];
    if (sectionsList) {
      sectionsList.forEach((section) => {
        const sectionExists = distinctSectionsList.find(
          (g) => g.sectionId === section.sectionId
        );
        const match = shoppingCentersList ? shoppingCentersList.find(shoppingCenter => shoppingCenter.shoppingCenterGroupId === section.shoppingCenterGroupId) : {};
        if (sectionExists === undefined) {
          distinctSectionsList.push({
            sectionId: section.sectionId,
            sectionName: section.sectionName,
            sectionExternalId: section.sectionExternalId,
            shoppingCenterGroupId: section.shoppingCenterGroupId,
            shoppingCenterGroupName: match?.shoppingCenterGroupName,
          });
        }
      });
    }
    return distinctSectionsList;
  },
};

export default shopsService;
