import request from "@/utils/request";
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";
import textHelpers from "@/utils/textHelpers";

const contactService = {
    async postForm(body) {
        const path = "/ContactUs";
        const result = await request.post(
            process.env.VUE_APP_ALTERNATE_API_URL + path,
            body
        );
        return result;
    },
    validateFormData(formData, email) {
        const errors = [];
        const mailFormatValid = textHelpers.emailValidator(formData.email);
        const validEmail = email.checkValidity();
        let validPhone;
        if (formData.phoneNumber && formData.phoneNumber > 0) {
            validPhone = parsePhoneNumberFromString(
                formData.phoneNumber.toString(),
                "IL"
            ).isValid();
        }
        if (!formData.email && !formData.phoneNumber) {
            errors.push("MissingEmailAndPhone");
        }
        if (formData.phoneNumber > 0 && !validPhone) {
            errors.push("PhoneNumberInvalid");
        }
        if (
            (formData.email && !mailFormatValid) ||
            (formData.email && !validEmail)
        ) {
            errors.push("EmailInvalid");
        }
        return errors;
    },
}

export default contactService;
