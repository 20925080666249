<template>
  <b-table
    :data="this.shopsList"
    :striped="true"
    :loading="loading"
    :paginated="showPagination"
    :per-page="rowsPerPage"
    default-sort="shopName"
    default-sort-direction="asc"
    pagination-position="both"
    height=65vh
    focusable
    scrollable
    sticky-header
  >
    <b-table-column
      v-slot="props"
      sortable
      field="shopId"
      :label="$t('ShopId')"
      centered
    >
      {{ props.row.shopId }}
    </b-table-column>
    <b-table-column
      header-class="has-text-right"
      v-slot="props"
      sortable
      field="shopName"
      :label="$t('Shop')"
    >
      {{ props.row.shopName }}
    </b-table-column>
    <b-table-column
      header-class="has-text-right"
      v-slot="props"
      sortable
      field="chainName"
      :label="$t('Chain')"
    >
      {{ props.row.chainName }}
    </b-table-column>
    <b-table-column
      header-class="has-text-right"
      v-slot="props"
      sortable
      field="shoppingCenterGroupName"
      :label="$t('ShoppingCenterGroup')"
    >
      {{ props.row.shoppingCenterGroupName }}
    </b-table-column>
    <b-table-column
    header-class="has-text-right"
      v-slot="props"
      sortable
      field="shoppingCenterName"
      :label="$t('ShoppingCenter')"
    >
      {{ props.row.shoppingCenterName }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="shoppingCenterShopId"
      :label="$t('ContractId')"
      centered
    >
      {{ props.row.shoppingCenterShopId }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="chainShopId"
      :label="$t('ChainShopId')"
      centered
    >
      {{ props.row.chainShopId }}
    </b-table-column>
    <b-table-column
    header-class="has-text-right"
      v-slot="props"
      sortable
      field="sections"
      :label="$t('Sections')"
    >
      <span v-for="section in props.row.sections" :key="section.sectionId">
        {{ section.sectionName }}
      </span>
    </b-table-column>
    <b-table-column
    header-class="has-text-right"
      v-slot="props"
      sortable
      field="creationDate"
      :label="$t('ShopCreationDate')"
    >
      {{ props.row.creationDate }}
    </b-table-column>
    <b-table-column
    header-class="has-text-right"
      v-slot="props"
      sortable
      field="deactivationDate"
      :label="$t('ShopDeactivationDate')"
    >
      {{ props.row.deactivationDate }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="membersRelated"
      :label="$t('ShopRelatedMembers')"
      :visible="this.showRelatedItems"
      centered
    >
      <b-button type="is-text"  @click="showRelatedMembers(props.row.shopId)">
        {{ props.row.membersRelated.length }}
      </b-button>
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="groupsRelated"
      :label="$t('ShopRelatedGroups')"
      :visible="this.showRelatedItems"
      centered
    >
    <b-button type="is-text"  @click="showRelatedGroups(props.row.shopId)">
       {{ props.row.groupsRelated.length }}
    </b-button>
    </b-table-column>
      <b-table-column
        v-slot="props"
        :label="$t('Edit')"
        centered
      >
    <b-button
    tag="router-link"
    type="is-warning"
    icon-pack="fas"
    icon-right="pencil-alt"
    :to="{name: `shopAdminEdit`,params: { id: props.row.shopId.toString(), returnTo: $route.path }}"
    data-test-id="shopEditButton"
    :disabled="!props.row.canEditShop"
    />
    </b-table-column>
    <template slot="top-left">
      <b-field :label="$t('ShopsPerPage')" type="is-blue">
        <b-select v-model="rowsPerPage" expanded @input="setPageSize">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </b-select>
      </b-field>
    </template>
    <template slot="empty">
      <div v-if="this.loading" class="content has-text-grey has-text-centered">
        <p>{{ $t("Loading") }}</p>
      </div>
      <div v-else class="content has-text-grey has-text-centered">
        <p><b-icon pack="fas" icon="frown-open" size="is-large" /></p>
        <p>{{ $t("NoDataToShow") }}</p>
      </div>
    </template>
  </b-table>
</template>

<script>
import localStorageAccess from "@/resourceAccess/localStorageAccess";
export default {
  props: ["shopsList", "loading", "showRelatedItems"],
  data() {
    return {
      rowsPerPage: 10
    };
  },
  mounted() {
    this.rowsPerPage =
      localStorageAccess.getRowsPerPage("shopsList") || this.rowsPerPage;
  },
  computed: {
    showPagination() {
      return this.shopsList ? this.shopsList.length > 10 : null;
    }
  },
  methods: {
    setPageSize() {
      localStorageAccess.saveRowsPerPage("shopsList", this.rowsPerPage);
    },
    showRelatedMembers(shopId) {
      this.$emit("member-count-clicked", shopId);
    },
    showRelatedGroups(shopId) {
      this.$emit("notification-group-count-clicked", shopId);
    }
  }
};
</script>
