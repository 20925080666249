<template>
  <b-field :label="$t('NotificationGroup')" >
    <b-autocomplete
      expanded
      :placeholder="canEdit === true ? $t('SelectNotificationGroup') : ''"
      v-model="groupText"
      icon-pack="fas"
      icon="search"
      :data="filteredGroupList"
      :custom-formatter="formatGroup"
      :open-on-focus="true"
      @select="selectGroup"
      @typing="setFilteredGroupList"
      :disabled="canEdit === false "
      data-test-id="selectNotificationGroup"
      ref="groupAutocomplete"
    ></b-autocomplete>
  </b-field>
</template>
<script>
import notificationService from '@/services/notificationService';
import { mapGetters } from 'vuex';

export default {
  props: ['value', 'canEdit'],
  data() {
    return {
      selectedNotificationGroupId: this.value,
      groupText: '',
      filteredGroupList: [],
    };
  },
  async mounted() {
    if (this.groupList === null) {
      await notificationService.getNotificationGroups();
    }
    this.groupText = this.selectedValue ? this.formatGroup(this.selectedValue) : '';
    if(this.selectedValue){
    this.$refs.groupAutocomplete.setSelected(this.selectedValue);
    }
    this.filteredGroupList = this.groupList;
  },
  computed: {
    ...mapGetters({
      groupList: 'getNotificationGroups',
    }),
    filterGroupList() {
      if (this.groupList) {
        this.groupList.forEach((element) => {
          const groupExists = this.filteredGroupList.find(
            (g) => g.groupId === element.groupId,
          );
          if (groupExists === undefined) {
            this.filteredGroupList.push({
              groupId: element.groupId,
              groupName: element.groupName,
              groupDisplay: element.groupId.toString().concat(' ').concat(element.groupName)
            });
          }
        });
        return  this.filteredGroupList.filter(
          (group) => group
            .toString()
            .toLowerCase()
            .indexOf(this.groupText.toLowerCase()) >= 0,
        );
      }
      return [];
    },
    selectedValue() {
      if (this.groupList) {
        return this.value? this.groupList.find((grp) => grp.groupId === this.value): 0;
      }
      return {};
    },
  },
  methods: {
    // Need to enforce that user cannot change RoleId = 1
    formatGroup(group) {
      if (this.groupList) {
        const groupDetails = this.groupList.find(
          (grp) => grp.groupId === group.groupId,
        );
        return `${groupDetails.groupId} ${groupDetails.groupName}`;
      }
      return '';
    },
    setFilteredGroupList(text) {
      this.filteredGroupList = this.groupList.filter(
        option =>
          option.groupName
           .concat(option.groupId)
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      );
    },
    selectGroup(option){
      this.selectedNotificationGroupId  = option ? option.groupId : 0;
    },
  },
  watch: {
    selectedNotificationGroupId: {
      handler() {
         this.$emit('input', this.selectedNotificationGroupId);
      },
    },
  },
};
</script>
