// responsibility - handle logout expiration events
import AuthEngine from '@/plugins/msal';
import * as Sentry from '@sentry/browser';

const identityStatusMonitor = store => {

    var logoutCheckInterval = null;
    store.watch(state => state.user, (userState) => {
        if (userState !== null) {
            let msToExpire = userState.msToExpire;
            if (msToExpire === undefined) {
                startMonitoring();
            } else if (msToExpire !== undefined && msToExpire <= 0) {
                stopMonitoring();
                AuthEngine.logout();
            }
        } else {
            stopMonitoring();
        }
    }, { deep: true })

    async function setMsToExpire() {
        try {
            let loginExpiration = await AuthEngine.loginExpiration();
            let msToExpire = loginExpiration - new Date(Date.now());
            store.commit('setUserMsToExpire', { msToExpire: msToExpire });

        } catch (e) {
            Sentry.captureException(e);
        }
    }

    function startMonitoring() {
        setMsToExpire()
        clearInterval(logoutCheckInterval);
        logoutCheckInterval = setInterval(() => { setMsToExpire() }, 10000);
    }

    function stopMonitoring() { clearInterval(logoutCheckInterval); }

}

export default identityStatusMonitor;
