const validationUtil = {
    checkFieldValidity(fields){
        const elements =  fields;
        const validationResults = [];
        elements.forEach( element => {
         validationResults.push(element.checkHtml5Validity());
        })
        const isValid = validationResults.some(result => !result) ? false : true;
        return isValid;
      },
}
export default validationUtil
