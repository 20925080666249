export default {
  setAuthenticatedUser(state, { user }) {
    state.user = user;
  },
  allowedShopsList(state, { shopsList }) {
    state.allowedShopsList = shopsList;
  },
  setRevenueIndication(state, { revenueIndication }) {
    state.revenueIndications = revenueIndication;
  },
  setRevenueInputResponse(state, { revenueInputResponse }) {
    state.revenueInputResponse = revenueInputResponse;
  },
  setChains(state, { chainsList }) {
    state.chainsList = chainsList;
  },
  setSections(state, { sectionsList }) {
    state.sectionsList = sectionsList;
  },
  setNotificationGroups(state, { notificationGroups }) {
    state.notificationGroups = notificationGroups;
  },
  setMembersList(state, { membersList }) {
    state.membersList = membersList;
  },
  resetState(state) {
    Object.keys(state).forEach(key => {
      state[key] = null;
    });
  },
  setUserMsToExpire(state, { msToExpire }) {
      state.user = { ...state.user, msToExpire: msToExpire };
  },
  setStrategies(state, { strategies }) {
    state.strategies = strategies;
  },
  setShopingCenters(state, { shoppingCenters }){
    state.shoppingCenters = shoppingCenters;
  },
  addOrUpdateAllowedShopsList(state, { updatedShop }) {
    let index = state.allowedShopsList.findIndex(shop => shop.shopId === updatedShop.shopId);
    if (index > 0){
      Object.assign(state.allowedShopsList[index] , updatedShop);
    } else {
      state.allowedShopsList.push(updatedShop);
   }
  },
  createGroup(state, { newGroup }) {
    let index = state.notificationGroups.findIndex(group => group.groupId === newGroup.groupId);
    if (index <= 0) {
      state.notificationGroups.push(newGroup);
    }
  },
  extendChainsList(state, { newChain }) {
    let index = state.chainsList.findIndex(chain => chain.chainId === newChain.chainId);
    if (index <= 0) {
      state.chainsList.push(newChain);
    }
  },
  extendSectionsList(state, { newSection }) {
    let index = state.sectionsList.findIndex(section => section.sectionId === newSection.sectionId);
    if (index <= 0) {
      state.sectionsList.push(newSection);
    }
  },
};
