<template>
  <section class="section">
    <div class="columns">
      <div class="column is-half container">
        <b-field type="is-blue" expanded grouped>
          <b-input
            data-test-id="chainAdministrationSearchInput"
            expanded
            v-model="searchText"
            icon-pack="fas"
            icon="search"
            :placeholder="$t('Search')"
          />
          <p class="control">
            <b-button
              type="is-primary"
              icon-pack="fas"
              icon-right="plus-square"
              tag="router-link"
              :loading="this.shouldDisplayLoading"
              :to="{ path: `/chains/admin/create/`}"
            />
          </p>
        </b-field>
      </div>
    </div>
    <div class="container">
      <chainAdministrationDetails
        :chainsList="this.filteredChainsList"
        :loading="this.shouldDisplayLoading"
        @shop-count-clicked="shopSelected"
        @notification-group-count-clicked="groupSelected"
        :canEdit="this.canCreateShops"
        :showRelatedItems="true"
      />
    </div>
    <b-modal
      v-model="isVisibleRelatedShops"
      :can-cancel="true"
      has-modal-card
      width="auto"
      aria-modal
    >
      <template>
        <shopDetailsModal
          :shopsList="this.shopsAdministrationListDetails"
          :loading="this.shouldDisplayLoading"
          :titleString="this.titleString"
        />
      </template>
    </b-modal>
    <b-modal
      v-model="isVisibleRelatedGroups"
      :can-cancel="true"
      has-modal-card
      width="auto"
      aria-modal
    >
      <template>
        <groupDetailsModal
          :groupsList="this.groupsAdministrationListDetails"
          :showRelatedItems="false"
          :titleString="this.titleString"
        />
      </template>
    </b-modal>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import shopsService from "@/services/shopsService";
import membersService from "@/services/membersService";
import notificationService from "@/services/notificationService";
import shopDetailsModal from "@/components/modals/shopDetailsModal";
import chainAdministrationDetails from "@/components/chainAdministrationDetails";
import groupDetailsModal from "@/components/modals/groupDetailsModal";

export default {
  components: {
    chainAdministrationDetails,
    shopDetailsModal,
    groupDetailsModal
  },
  mounted() {
    if (!this.chainsList) {
      shopsService.getChains();
    }
    if (!this.shopList) {
      shopsService.getAllowedShopsList();
    }
    if (!this.notificationGroupList) {
      notificationService.getNotificationGroups();
    }
    if (!this.memberList) {
      membersService.getMembersList();
    }
  },
  data() {
    return {
      searchText: "",
      selectedChain: "",
      relatedShops: [],
      relatedGroups: [],
      isVisibleRelatedShops: false,
      isVisibleRelatedGroups: false,
    };
  },
  computed: {
    ...mapGetters({
      chainsList: "getChains",
      memberList: "getMemberList",
      shopList: "getAllowedShopsList",
      notificationGroupList: "getNotificationGroups",
    }),
    shouldDisplayLoading() {
      return !Array.isArray(this.chainsList) || !this.chainsList.length;
    },
    canCreateShops() {
      return shopsService.canCreateShops(this.shopList);
    },
    titleString() {
      return this.selectedChain
        ? `${this.$t("Chain")} ${this.selectedChain.chainName} ${
            this.selectedChain.chainId
          }`
        : "";
    },
    chainsAdministrationListDetails() {
      let data = shopsService.prepareChainDetails(
        this.chainsList,
        this.shopList,
        this.notificationGroupList
      );
      return data;
    },
    filteredChainsList() {
      let filteredChains = this.chainsAdministrationListDetails.filter(
        (chain) =>
          this.searchText
            .toUpperCase()
            .split(/[ ]+/u)
            .filter((el) => el)
            .every((phrase) =>
              chain.chainName
                .concat(chain.chainId)
                .concat(chain.chainGroupId)
                .concat(chain.chainGroupName)
                .concat(chain.chainExternalId)
                .toUpperCase()
                .includes(phrase)
            )
      );
      return filteredChains;
    },
    shopsAdministrationListDetails() {
      let data = shopsService.prepareShopsData(
        this.relatedShops,
        this.notificationGroupList,
        this.membersList,
        this.chainsList,
      );
      return data;
    },
    groupsAdministrationListDetails(){
      const data = notificationService.prepareNotificationDetails(
        this.relatedGroups,
        this.membersList
      );
      return data;
    },
  },
  methods: {
    chainSelected(chainId) {
      this.selectedChain = this.chainsList.find(
        (chain) => chain.chainId === chainId
      );
      this.relatedShops = this.shopList.filter(
        (shop) => shop.chainId === chainId
      );
    },
    shopSelected(chainId){
      this.chainSelected(chainId);
      if (this.relatedShops.length > 0) {
        this.isVisibleRelatedShops = true;
      }
    },
    groupSelected(chainId){
      this.chainSelected(chainId);
      this.relatedGroups = this.notificationGroupList?.filter(group => group.shopIds.some(shop=> this.relatedShops?.some(i=> i.shopId === shop)))
      ?? [];
      if(this.relatedGroups.length > 0){
       this.isVisibleRelatedGroups = true
      }
    }
  },
};
</script>
