<template>
  <section>
    <b-table
      :data="revenuesData"
      :columns="revenuesColumns"
      default-sort="shoppingCenterGroupName"
      default-sort-direction="asc"

    ></b-table>
  </section>
</template>
<script>
import revenueService from '@/services/revenueService';

export default {
  props: ['missingRevenues'],
  data() {
    return {
      revenuesColumns: [
        {
          field: 'shoppingCenterGroupName',
          label: this.$t('ShoppingCenterGroupName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'shoppingCenterName',
          label: this.$t('ShoppingCenterName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'shopName',
          label: this.$t('ShopName'),
          cellClass: 'has-text-right',
          sortable: true,
        },

        {
          field: 'sectionName',
          label: this.$t('SectionName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'shopDisabled',
          label: this.$t('ShopDisabled'),
          cellClass: 'has-text-right',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    revenuesData() {
      const flattenRevenues = this.missingRevenues
        .map((shop) => shop.sections
          .filter(
            (section) => revenueService.revenueExistsForShop(shop.shopId, section.sectionId)
                 === false
                && section.updateSucceed !== true,
          )
          .map((shopSection) => ({
            shoppingCenterGroupName: shop.shoppingCenterGroupName,
            shoppingCenterName: shop.shoppingCenterName,
            shopName: shop.shopName,
            sectionName: shopSection.sectionName,
            shopDisabled: this.$t(shop.isDisabled.toString()),
          })))
        .reduce((acc, val) => acc.concat(val), []);

      return flattenRevenues;
    },
  },
};
</script>
