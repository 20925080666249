<template>
  <b-field :label="$t('MemberName')">
    <b-input
      required
      expanded
      v-model="memberName"
      :placeholder="canEdit ? $t('MemberNameInput') : ''"
      :validation-message="$t('FieldIsRequired',{fieldName: $t('MemberName')})"
      :disabled="canEdit === false"
      data-test-id="memberName"
      ref="memberName"
    ></b-input>
  </b-field>
</template>
<script>
export default {
  props: ['value', 'canEdit'],
  data() {
    return {
      memberName: this.value,
    };
  },
  watch: {
    memberName: {
      handler() {
        this.$emit('input', this.memberName);
      },
    },
  },
	methods:{
		checkHtml5Validity(){
			return this.$refs.memberName.checkHtml5Validity();
		}
	},
};
</script>
