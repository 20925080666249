export default {
  getAuthenticatedUser({ user }) {
    return user;
  },
  getAllowedShopsList({ allowedShopsList }) {
    if (allowedShopsList) {
      const allowedShops = allowedShopsList.map((shop) => ({
        ...shop,
        creationDate: new Date(`${shop.creationDate}Z`),
        deactivationDate: shop.deactivationDate === null ? shop.deactivationDate : new Date(`${shop.deactivationDate}Z`),
      }));
      return allowedShops;
    }
    return allowedShopsList;
  },
  getMemberList({ membersList }) {
    return membersList;
  },
  getChains({ chainsList }) {
    return chainsList;
  },
  getSections({ sectionsList }) {
    return sectionsList;
  },
  getRevenueIndication({ revenueIndications }) {
    return revenueIndications;
  },
  getRevenueInputResponse({ revenueInputResponse }) {
    return revenueInputResponse;
  },
  getNotificationGroups({ notificationGroups }) {
    return notificationGroups;
  },
  getMembers({ membersList }) {
    return membersList;
  },
  getUserMsToExpire({ user }) {
    return user?.msToExpire
  },
  getStrategies({ strategies }) {
    return strategies;
  },
  getShoppingCenters({ shoppingCenters }){
    return shoppingCenters;
  },
};
