import AuthEngine from '@/plugins/msal';
import Axios from 'axios';

const request = {
  async get(url, urlSearchParams) {
    let result;
    try {
      urlSearchParams = new URLSearchParams(urlSearchParams);
    const authHeader = await headers();

    result = await axiosInstance.get(url, {
      params: urlSearchParams, headers: authHeader.headers,
    });
    } catch (error) {
return null;
    }

    return result?.data;
  },

  async post(url, body) {
    const authHeader = await headers();
    const result = await axiosInstance.post(url, body, { headers: authHeader.headers });
    return result;
  },
  patch(body) {
    return {
      method: 'PATCH',
      ...headers(),
      data: JSON.stringify(body),
    };
  },
  put(body) {
    return {
      method: 'PUT',
      ...headers(),
      data: JSON.stringify(body),
    };
  },
  delete() {
    return {
      method: 'DELETE',
      ...headers(),
    };
  },
};
async function headers() {
  const isAuthenticated = AuthEngine.isAuthenticated();
  let authHeader = {};
  if(isAuthenticated){
    let userToken = {};
    const alternateLogin = AuthEngine.isAlternateLogin();
    const accessToken = alternateLogin ? '' : await AuthEngine.getAccessToken();
    userToken = accessToken;
    const currentUserToken = userToken;
    authHeader = alternateLogin ? {} : { Authorization: `Bearer ${currentUserToken}` };
  }
  return {
    headers: {
      ...(isAuthenticated) && { ...authHeader},
      'Content-Type': 'application/json',
    },
  };
}

const axiosInstance = Axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && AuthEngine.isAuthenticated()) {
      AuthEngine.logout();
    } else {
      return Promise.reject(error);
    }
  },
);

export default request;
