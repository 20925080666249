<template>
  <div>
    <areYouSureBox
      has-modal-card
      :active="this.showAreYouSure"
      @response="areYouSureResponseHandler"
    />
    <div class="container columns">
      <form class="column is-two-fifths">
        <b-field :label="$t('ChainId')">
          <b-input
            :value="this.isNew ? $t('SystemGenerated') : selectedChain.chainId"
            disabled
          />
        </b-field>
        <b-field :label="$t('ChainName')">
          <b-input
            v-model="selectedChain.chainName"
            :validation-message="
              $t('FieldIsRequired', { fieldName: $t('ChainName') })
            "
            data-test-id="chainNameInput"
            :disabled="canEdit === false"
            ref="chainName"
            required
            expanded
          />
        </b-field>
        <b-field grouped position="is-right">
          <b-field>
            <b-button @click="cancelUpdate">
              {{ canEdit ? $t("Cancel") : $t("Return") }}
            </b-button>
          </b-field>
          <b-field v-if="canEdit">
            <b-button
              @click="startProcessing"
              :disabled="isProcessing"
              data-test-id="chainDetailsSubmit"
            >
              {{ $t("Submit") }}
            </b-button>
          </b-field>
        </b-field>
      </form>
    </div>
  </div>
</template>
<script>
import * as Sentry from "@sentry/browser";
import { mapGetters } from "vuex";
import areYouSureBox from "@/components/AreYouSureBox";
import shopsService from "@/services/shopsService";

export default {
  props: ["id"],
  components: {
    areYouSureBox,
  },
  async mounted() {
    if (!this.chainsList) {
      await shopsService.getChains();
    }
    if (this.id) {
      this.getChainToEdit();
    } else {
      this.createNewChain();
    }
  },
  data() {
    return {
      selectedChain: {},
      originalChain: {},
      showAreYouSure: false,
      isNew: false,
      processing: false,
      chainUpdated: false,
      canEdit: true,
    };
  },
  computed: {
    isProcessing() {
      return this.processing;
    },
    ...mapGetters({
      chainsList: "getChains",
    }),
    chainGroups() {
      return this.chainsList
        ? [
            ...new Map(
              this.chainsList
                .map((group) => ({
                  chainGroupId: group.chainGroupId,
                  chainGroupName: group.chainGroupName,
                }))
                .map((group) => [group.chainGroupId, group])
            ).values(),
          ]
        : [];
    },
  },
  methods: {
    getChainToEdit() {
      this.selectedChain = this.chainsList
        ? this.chainsList.find((x) => x.chainId == this.id)
        : [];
      this.originalChain = { ...this.selectedChain };
    },
    revertToOriginalChain() {
      Object.assign(this.selectedChain, this.originalChain);
    },
    resetChain() {
      this.selectedChain = {};
      this.chainGroupText = "";
      this.chainUpdated = false;
      this.isNew = false;
    },
    cancelUpdate() {
      this.$emit("chain-update-cancelled");
    },
    async areYouSureResponseHandler(action) {
      if (this.showAreYouSure === true) {
        this.showAreYouSure = false;
        try {
          if (action === "yes") {
            await this.sendUpdate();
          }
        } finally {
          this.processing = false;
        }
      }
    },
    createNewChain() {
      this.selectedChain = {
        chainId: 0,
        chainName: "",
      };
      this.isNew = true;
    },
    startProcessing() {
      if (this.processing === false) {
        this.processing = true;
        const validationResults = shopsService.validateChainData([this.$refs.chainName]);
        if (validationResults.length > 0) {
          this.processing = false;
          const translatedErrors = validationResults
          .map((validator) => this.$t(validator))
          .join(", ");
          this.openNotification(translatedErrors, "is-danger");
        } else {
         this.showAreYouSure = true;
        }
      }
    },
    openNotification(message, type) {
      let autoCloseProps = {};
      if(type === "is-success"){
        autoCloseProps = {
          autoClose: true, 
          duration: 5000,
        }
      }
      this.$buefy.notification.open({
        message,
        type,
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        ...autoCloseProps,
        "data-test-id": "chainValidationMessage",
      });
    },
    sendUpdate() {
      shopsService
        .postChain(this.selectedChain)
        .then((result) => {
          this.chainUpdated = true;
          if (this.isNew) {
            let chainId = result.request.response;
            this.selectedChain.chainId = parseInt(chainId);
            shopsService.createChain(this.selectedChain);
          }
          this.openNotification(this.$t("SuccessChainUpdated"), "is-success");
          return Promise.resolve("done");
        })
        .then(() => {
          this.$emit("chain-update-complete", this.selectedChain);
        })
        .catch((error) => {
          if (error?.response && error.response.status === 409) {
            Object.getOwnPropertyNames(error.response.data?.errors).forEach(
              (err) => {
                this.openNotification(this.$t(err), "is-danger");
              }
            );
          } else {
            this.openNotification(this.$t("ErrorContactUs"), "is-danger");
            Sentry.captureException(error);
          }
          return Promise.resolve("done");
        });
    },
  },
  beforeDestroy() {
    if (!this.chainUpdated) {
      this.revertToOriginalChain();
    }
    this.resetChain();
  },
};
</script>
