export default {
  user: null,
  allowedShopsList: null,
  revenueIndications: null,
  revenueInputResponse: null,
  chainsList: null,
  sectionsList: null,
  notificationGroups: null,
  membersList: null,
  strategies: null,
  shoppingCenters: null,
};
