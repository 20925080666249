<template>
  <div class="modal-card" data-test-id="sectionCreationModal">
    <header class="modal-card-head">
      <b-button icon-pack="fas" icon-left="times" @click="$parent.close()" />
    </header>
    <section class="modal-card-body">
      <sectionEdit
        :shoppingCenterGroupId="this.shoppingCenterGroupId"
        :canEditShop="this.canEditShop"
        @section-create-completed="this.sectionUpdateComplete"
        @section-create-canceled="this.cancelUpdate"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t("Close") }}</b-button>
    </footer>
  </div>
</template>
<script>
import sectionEdit from "@/components/sectionEdit";
export default {
  props: ["shoppingCenterGroupId", "canEditShop"],
  components: {
    sectionEdit,
  },
  methods: {
    sectionUpdateComplete(newChain) {
      this.$emit("section-create-completed", newChain);
      this.$parent.close();
    },
    cancelUpdate() {
      this.$parent.close();
    },
  },
};
</script>
