<template>
  <section class="section">
    <sectionEdit
      @section-update-completed="goBack"
      @section-update-canceled="goBack"
      :canEditShop="this.canCreateShops"
      :id="this.id"
    />
  </section>
</template>
<script>
import routerUtils from "@/utils/routerUtils";
import sectionEdit from "@/components/sectionEdit.vue";
import shopsService from "@/services/shopsService";
import { mapGetters } from "vuex";
export default {
  props: {
    id: Number,
    returnTo: {
      type: String,
      default: "/sections/admin",
    },
  },
  components: {
    sectionEdit,
  },
  mounted() {
    if (!this.shopList) {
      shopsService.getAllowedShopsList();
    }
  },
  computed: {
    ...mapGetters({
      shopList: "getAllowedShopsList",
    }),
    canCreateShops() {
      return shopsService.canCreateShops(this.shopList);
    },
  },
  methods: {
    goBack() {
      routerUtils.moveTo(this.returnTo);
    },
  },
};
</script>
