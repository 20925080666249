<template>
    <section class="section">
      <div class="columns">
        <div class="column is-half container">
        <b-field
          type="is-blue"
          expanded
          grouped
        >
          <b-input
          expanded
          :disabled="this.shouldDisplayLoading"
          :loading="this.shouldDisplayLoading"
          v-model="searchText"
          icon-pack="fas"
          icon="search"
          :placeholder="$t('Search')"
          />
          <p class="control">
            <b-button
            :loading="this.shouldDisplayLoading"
            v-if="this.canCreateShops"
            tag="router-link"
            type="is-primary"
            icon-pack="fas"
            icon-right="plus-square"
            :to="{ path: `/shops/admin/create/`}"
            />
          </p>
        </b-field>
      </div>
       </div>
      <b-modal
        v-model="isVisibleRelatedMembers"
        :can-cancel="true"
        has-modal-card
        aria-modal
        width="auto"
      >
        <template>
          <memberDetailsModal
            :membersList="this.membersAdministrationListDetails"
            :loading="this.shouldDisplayLoading"
            :titleString="this.titleString"
          />
        </template>
      </b-modal>
       <b-modal
        v-model="isVisibleRelatedGroups"
        :can-cancel="true"
        has-modal-card
        aria-modal
        width="auto"
      >
        <template>
          <groupDetailsModal
          :groupsList="this.groupsAdministrationListDetails"
          :showRelatedItems="false"
           :titleString="this.titleString"
          />
        </template>
      </b-modal>
     <div class="container">
    <shopAdministrationDetails
      :shopsList="this.filteredShopsList"
      :loading="this.shouldDisplayLoading"
      :showRelatedItems="true"
      @member-count-clicked="memberSelected"
      @notification-group-count-clicked="groupSelected"
    />
     </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import shopsService from "@/services/shopsService";
import membersService from "@/services/membersService";
import notificationService from "@/services/notificationService";
import shopAdministrationDetails from "@/components/shopAdministrationDetails";
import memberDetailsModal from "@/components/modals/memberDetailsModal";
import groupDetailsModal from "@/components/modals/groupDetailsModal";

export default {
  components: {
    shopAdministrationDetails,
    memberDetailsModal,
    groupDetailsModal,
  },
  mounted() {
    if (!this.notificationGroupList) {
      notificationService.getNotificationGroups();
    }
    if (!this.membersList) {
      membersService.getMembersList();
    }
    if (!this.shopList) {
      shopsService.getAllowedShopsList();
    }
    if (!this.strategiesList) {
      notificationService.getStrategies();
    }
    if (!this.chainsList) {
      shopsService.getChains();
    }
  },
  data() {
    return {
      searchText: "",
      isNew: false,
      sections: [],
      processing: false,
      showAreYouSure: false,
      translatedSections: [],
      isVisibleRelatedMembers: false,
      isVisibleRelatedGroups:false,
      relatedMemberList: [],
      relatedGroupsList: [],
      selectedShop: {}
    };
  },
  computed: {
    titleString() {
      return this.selectedShop
        ? `${this.$t("Shop")} ${this.selectedShop.shopId} ${
            this.selectedShop.shopName
          }`
        : {};
    },
    shouldDisplayLoading() {
      return !Array.isArray(this.shopList) || !this.shopList.length 
    },
    canCreateShops() {
      return shopsService.canCreateShops(this.shopList);
    },
    ...mapGetters({
      membersList: "getMemberList",
      notificationGroupList: "getNotificationGroups",
      shopList: "getAllowedShopsList",
      strategiesList: "getStrategies",
      chainsList: "getChains",
    }),
    shopsAdministrationListDetails() {
      let data = shopsService.prepareShopsData(
        this.shopList,
        this.notificationGroupList,
        this.membersList,
        this.chainsList,
      );
      return data;
    },
    filteredShopsList() {
      let filteredShops = this.shopsAdministrationListDetails.filter(shop =>
        this.searchText
          .toUpperCase()
          .split(/[ ]+/u)
          .filter(el => el)
          .every(phrase =>
            shop.shoppingCenterGroupName
              .concat(shop.shoppingCenterName)
              .concat(shop.shopName)
              .concat(shop.shoppingCenterShopId)
              .concat(shop.shopId)
              .toUpperCase()
              .includes(phrase)
          )
      );
      return filteredShops;
    },
    membersAdministrationListDetails() {
      let data = membersService.prepareMembersDetails(
        this.relatedMemberList,
        this.notificationGroupList,
        this.strategiesList
      );
      return data;
    },
      groupsAdministrationListDetails() {
      let data = notificationService.prepareNotificationDetails(
        this.relatedGroupsList,
        this.memberList,
      );
      return data;
    },
  },
  methods: {
    groupSelected(shopId){
    this.selectedShop = this.shopList.find(shop => shop.shopId === shopId);
    this.relatedGroupsList = this.notificationGroupList?.filter(group => group.shopIds.includes(shopId)); 
     this.isVisibleRelatedGroups = true;
    },
    memberSelected(shopId) {
      this.selectedShop = this.shopList.find(shop => shop.shopId === shopId);
      const relatedGroups = this.notificationGroupList
        ? this.notificationGroupList
            .filter(group => group.shopIds.includes(shopId))
            .map(item => item.groupId)
        : [];
      const relatedMembers = this.membersList
        ? this.membersList.filter(member =>
            relatedGroups.includes(member.groupId)
          )
        : [];
      this.relatedMemberList = relatedMembers;
      if (relatedMembers.length > 0) {
        this.isVisibleRelatedMembers = true;
      }
    }
  }
};
</script>
