var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{attrs:{"data":_vm.revenueDataForTable,"striped":true,"loading":_vm.loading,"paginated":_vm.showPagination,"pagination-position":"both","per-page":_vm.rowsPerPage,"default-sort":"shoppingCenterGroupName","default-sort-direction":"asc"}},[_c('b-table-column',{attrs:{"header-class":"has-text-right","sortable":"","field":"shoppingCenterGroupName","label":_vm.$t('ShoppingCenterGroupName')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.shoppingCenterGroupName === props.row.shoppingCenterName ? _vm.$t("DoesNotBelongToGroup") : props.row.shoppingCenterGroupName))]}}])}),_c('b-table-column',{attrs:{"header-class":"has-text-right","sortable":"","field":"shoppingCenterName","label":_vm.$t('ShoppingCenterName')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.shoppingCenterName))]}}])}),_c('b-table-column',{attrs:{"header-class":"has-text-right","sortable":"","field":"shopName","label":_vm.$t('ShopName')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.shopName))]}}])}),_c('b-table-column',{attrs:{"header-class":"has-text-right","sortable":"","field":"chainShopId","label":_vm.$t('ChainShopId')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(_vm._s(props.row.chainShopId))]}}])}),_c('b-table-column',{attrs:{"header-class":"has-text-right","field":"sections","label":_vm.$t('RevenueWithoutVat')},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.row.sections),function(section,index){return _c('span',{key:index,staticClass:"has-text-right"},[_c('b-field',{attrs:{"type":_vm.revenueExistsForShop(props.row.shopId, section.sectionId)
            ? 'is-info'
            : 'is-red',"label":props.row.sections.length > 1 ? section.sectionName : ''}},[_c('b-input',{directives:[{name:"currency",rawName:"v-currency",value:({
            currency: null,
            locale: _vm.locale,
            precision: 2,
            distractionFree: false,
            allowNegative: false,
          }),expression:"{\n            currency: null,\n            locale: locale,\n            precision: 2,\n            distractionFree: false,\n            allowNegative: false,\n          }"}],ref:'revenueInput_'
              .concat(props.row.shopId)
              .concat('_')
              .concat(section.sectionId),refInFor:true,attrs:{"data-test-id":'revenueInput_'
              .concat(props.row.shopId)
              .concat('_')
              .concat(section.sectionId),"position":"is-right","disabled":props.row.isDisabled,"placeholder":_vm.revenueValuePlaceHolder(
              props.row.shopId,
              section.sectionId,
              props.row.isDisabled
            ),"validation-message":_vm.$t('OnlyIntegerAllowed'),"tabindex":props.row.isDisabled ? -1 : 0},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();}]},model:{value:(section.revenueValue),callback:function ($$v) {_vm.$set(section, "revenueValue", $$v)},expression:"section.revenueValue"}})],1)],1)})}}])}),_c('template',{slot:"empty"},[(_vm.loading)?_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v(_vm._s(_vm.$t("Loading")))])])]):(_vm.value && _vm.value.length === 0)?_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"pack":"fas","icon":"smile","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t("AllShopsReported")))])])]):_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"pack":"fas","icon":"frown-open","size":"is-large"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t("NoShops")))])])])]),_c('template',{slot:"top-left"},[_c('b-field',{attrs:{"label":_vm.$t('ShopsPerPage'),"type":"is-blue"}},[_c('b-select',{attrs:{"expanded":""},on:{"input":_vm.setPageSize},model:{value:(_vm.rowsPerPage),callback:function ($$v) {_vm.rowsPerPage=$$v},expression:"rowsPerPage"}},[_c('option',{attrs:{"value":"10"}},[_vm._v("10")]),_c('option',{attrs:{"value":"25"}},[_vm._v("25")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50")]),_c('option',{attrs:{"value":"75"}},[_vm._v("75")]),_c('option',{attrs:{"value":"100"}},[_vm._v("100")])])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }