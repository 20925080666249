<template>
 <section class="section">
      <div class="columns">
        <div class="column is-half container">
        <b-field
          type="is-blue"
          expanded
          grouped
        >
        <b-input
        expanded
        :disabled="this.shouldDisplayLoading"
        :loading="this.shouldDisplayLoading"
        v-model="searchText"
        icon-pack="fas"
        icon="search"
        :placeholder="$t('Search')"
        data-test-id="notificationGroupsSearch"
        />
        </b-field>
         </div>
      </div>
    <div class="container">
      <notificationGroupDetails
        :notificationGroups="this.filteredGroupList"
        :showRelatedItems="true"
        @member-count-clicked="memberSelected"
        @shop-count-clicked="shopSelected"
      />
    </div>
    <b-modal
      v-model="isVisibleRelatedMembers"
      :can-cancel="true"
      has-modal-card
      aria-modal
      width="auto"
    >
      <template>
        <memberDetailsModal
          :membersList="this.membersAdministrationListDetails"
          :loading="this.shouldDisplayLoading"
          :titleString="this.titleString"
        />
      </template>
    </b-modal>
    <b-modal
      v-model="isVisibleRelatedShops"
      :can-cancel="true"
      has-modal-card
      width="auto"
      aria-modal
    >
      <template>
        <shopDetailsModal
          :shopsList="this.shopsAdministrationListDetails"
          :loading="this.shouldDisplayLoading"
          :titleString="this.titleString"
        />
      </template>
    </b-modal>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import shopsService from "@/services/shopsService";
import membersService from "@/services/membersService";
import notificationService from "@/services/notificationService";
import memberDetailsModal from "@/components/modals/memberDetailsModal";
import notificationGroupDetails from "@/components/notifications/notificationGroupDetails";
import shopDetailsModal from "@/components/modals/shopDetailsModal";

export default {
  components: {
    notificationGroupDetails,
    memberDetailsModal,
    shopDetailsModal
  },
  computed: {
    ...mapGetters({
      notificationGroupList: "getNotificationGroups",
      memberList: "getMemberList",
      shopList: "getAllowedShopsList",
      strategiesList: "getStrategies",
      chainsList: "getChains",
    }),
    titleString() {
      return this.selectedGroup
        ? `${this.$t("NotificationGroup")} ${this.selectedGroup.groupId} ${
            this.selectedGroup.groupName
          }`
        : "";
    },
    shouldDisplayLoading() {
      return (
        !Array.isArray(this.notificationGroupList) ||
        !this.notificationGroupList.length
      );
    },
    showEditButton() {
      return Object.entries(this.selectedGroup).length > 0;
    },
  groupsAdministrationListDetails() {
      let data = notificationService.prepareNotificationDetails(
        this.notificationGroupList,
        this.memberList,
      );
      return data;
    },
    filteredGroupList() {
      let filteredGroups = this.groupsAdministrationListDetails.filter(
        group =>
        this.searchText
        .toUpperCase()
        .split(/[ ]+/u)
        .filter(el => el)
        .every(phrase =>
          group.groupName
          .concat(group.groupId)
          .concat(group.groupDescription)
          .toUpperCase()
          .includes(phrase)
        )
      );
      return filteredGroups;
    },
    membersAdministrationListDetails() {
      let data = membersService.prepareMembersDetails(
        this.relatedMemberList,
        this.notificationGroupList,
        this.strategiesList
      );
      return data;
    },
    shopsAdministrationListDetails() {
      let data = shopsService.prepareShopsData(
        this.relatedShops,
        this.notificationGroupList,
        this.membersList,
        this.chainsList,
      );
      return data;
    }
  },
  data() {
    return {
      searchText: "",
      selectedMember: {},
      relatedMemberList: [],
      isVisibleRelatedMembers: false,
      isVisibleRelatedShops: false,
      relatedShops: [],
      selectedGroup: ""
    };
  },
  methods: {
    memberSelected(groupId) {
      this.selectedGroup = this.notificationGroupList.find(
        g => g.groupId == groupId
      );
      this.selectedMember = this.groupsAdministrationListDetails.find(
        x => x.groupId == groupId
      );
      this.relatedMemberList = this.selectedMember.members;
      if (this.relatedMemberList.length > 0) {
        this.isVisibleRelatedMembers = true;
      }
    },
    shopSelected(groupId) {
      this.selectedGroup = this.notificationGroupList.find(
        g => g.groupId == groupId
      );
      const relatedNotificationGroup = this.notificationGroupList
        ? this.notificationGroupList.find(g => g.groupId == groupId)
        : [];
      const relatedShopIds = relatedNotificationGroup.shopIds ?? [];
      this.relatedShops = this.shopList.filter(shop =>
        relatedShopIds.includes(shop.shopId)
      );
      if (this.relatedShops.length > 0) {
        this.isVisibleRelatedShops = true;
      }
    }
  },
  mounted() {
    if (!this.notificationGroupList) {
      notificationService.getNotificationGroups();
    }
    if (!this.memberList) {
      membersService.getMembersList();
    }
    if (!this.shopList) {
      shopsService.getAllowedShopsList();
    }
    if (!this.strategiesList) {
      notificationService.getStrategies();
    }
   if (!this.chainsList) {
      shopsService.getChains();
    }
  }
};
</script>
