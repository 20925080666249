<template>
    <section class="section">
      <div class="columns">
        <div class="column is-half container">
        <b-field
          type="is-blue"
          expanded
          grouped
        >
          <b-input
          data-test-id="memberAdministrationSearchInput"
          expanded
          :disabled="this.shouldDisplayLoading"
          :loading="this.shouldDisplayLoading"
          v-model="searchText"
          icon-pack="fas"
          icon="search"
          :placeholder="$t('Search')"
          />
          <p class="control" v-if="this.canCreateMember">
           <b-button
           :loading="this.shouldDisplayLoading"
            tag="router-link"
            type="is-primary"
            icon-pack="fas"
            icon-right="plus-square"
            :to="{ path: `/members/admin/create/`}"
            />
          </p>
        </b-field>
      </div>
     </div>
    <div class="container">
      <memberAdministrationDetails
        :membersList="this.filteredMemberList"
        :loading="this.shouldDisplayLoading"
        :showRelatedItems="true"
        @shop-count-clicked="shopSelected"
      />
    </div>
    <b-modal
      v-model="isVisibleRelatedShops"
      :can-cancel="true"
      has-modal-card
      width="auto"
    >
      <template>
        <shopDetailsModal
          :shopsList="this.shopsAdministrationListDetails"
          :loading="this.shouldDisplayLoading"
          :titleString="this.titleString"
        />
      </template>
    </b-modal>
   </section>
</template>
<script>
import { mapGetters } from "vuex";
import membersService from "@/services/membersService";
import notificationService from "@/services/notificationService";
import shopsService from "@/services/shopsService";
import memberAdministrationDetails from "@/components/members/memberAdministrationDetails";
import shopDetailsModal from "@/components/modals/shopDetailsModal";

export default {
  // Add notification strategy
  components: {
    memberAdministrationDetails,
    shopDetailsModal
  },
  data() {
    return {
      searchText: "",
      isVisibleRelatedShops: false,
      relatedShops: [],
      selectedMember: {}
    };
  },
  mounted() {
    if (!this.notificationGroupList) {
      notificationService.getNotificationGroups();
    }
    if (!this.membersList) {
      membersService.getMembersList();
    }
    if (!this.shopList) {
      shopsService.getAllowedShopsList();
    }
    if (!this.strategiesList) {
      notificationService.getStrategies();
    }
    if (!this.chainsList) {
      shopsService.getChains();
    }
  },
  computed: {
    ...mapGetters({
      membersList: "getMemberList",
      notificationGroupList: "getNotificationGroups",
      shopList: "getAllowedShopsList",
      strategiesList: "getStrategies",
      chainsList: "getChains",
    }),
    titleString() {
      return this.selectedMember
        ? `${this.$t("Member")} ${this.selectedMember.memberId} ${
            this.selectedMember.memberFirstName
          }`
        : {};
    },
    shouldDisplayLoading() {
      return !Array.isArray(this.membersList) || !this.membersList.length;
    },
    canCreateMember() {
      return membersService.canCreateMember(this.membersList);
    },
    membersAdministrationListDetails() {
      let data = membersService.prepareMembersDetails(
        this.membersList,
        this.notificationGroupList,
        this.strategiesList
      );
      return data;
    },
    filteredMemberList() {
      let filteredMembers = this.membersAdministrationListDetails.filter(
        member =>
          this.searchText
            .toUpperCase()
            .split(/[ ]+/u)
            .filter(el => el)
            .every(phrase =>
              member.memberFirstName
                .concat(member.memberId)
                .concat(member.memberPhoneNumber)
                .concat(member.memberEmail)
                .toUpperCase()
                .includes(phrase)
            )
      );
      return filteredMembers;
    },
    shopsAdministrationListDetails() {
      let data = shopsService.prepareShopsData(
        this.relatedShops,
        this.notificationGroupList,
        this.membersList,
        this.chainsList,
      );
      return data;
    }
  },
  methods: {
    shopSelected(memberId) {
      this.selectedMember = this.membersList.find(x => x.memberId === memberId);
      let memberData = this.membersList ? this.selectedMember : [];
      let groupId = memberData.groupId ?? 0;
      const relatedNotificationGroup = this.notificationGroupList?.find(
        g => g.groupId == groupId
      );
      let relatedShopIds = relatedNotificationGroup.shopIds ?? [];
      this.relatedShops = this.shopList
        ? this.shopList.filter(shop => relatedShopIds.includes(shop.shopId))
        : [];
      if (this.relatedShops.length > 0) {
        this.isVisibleRelatedShops = true;
      }
    },
  }
};
</script>
