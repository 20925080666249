import request from '@/utils/request';
import store from '@/store';
import shopsService from '@/services/shopsService';
import { parseCurrency } from 'vue-currency-input';
import * as XLSX from 'xlsx';
import i18n from '@/utils/i18n';
import textHelpers from "@/utils/textHelpers";


const numberWithSeparatorsRegEx = '^\\d+(,\\d+)*(\\.\\d+)?$';
const revenueService = {

  async getRevenueIndication(month, year) {
    const path = '/revenues/GetPostedShops';
    const result = await request.get(process.env.VUE_APP_REVENUE_API_URL + path, { month, year });
    store.commit('setRevenueIndication', {
      revenueIndication: result,
    });
  },
  async postRevenue(body) {
    const path = '/revenues/postrevenue';
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    return result;
  },
  postRevenueDone(postRevenueResponse) {
    const allowedShops = store.getters.getAllowedShopsList;
    allowedShops.forEach((element) => {
      element.sections.forEach((section) => {
        if (
          postRevenueResponse
          && postRevenueResponse.some(
            (shop) => shop.updateSucceed === true
              && shop.shopId === element.shopId
              && shop.sectionId === section.sectionId,
          )
        ) {
          section.revenueExists = true;
        }
        section.revenueValue = '';
      });
    });
    store.commit('allowedShopsList', {
      shopsList: allowedShops,
    });
  },
  prepareDataForFileSample(revenueList, selectedDate) {
    return revenueList
      .map((revenueInputItem) => revenueInputItem.sections.map((shopSection) => ({

        ShoppingCenterName: revenueInputItem.shoppingCenterName,
        ShopName: revenueInputItem.shopName,
        ChainShopId: revenueInputItem.chainShopId,
        SectionId: shopSection.sectionId,
        SectionName: shopSection.sectionName,
        Month: selectedDate.getMonth() + 1,
        Year: selectedDate.getFullYear(),
        RevenueInteger: shopSection.revenueValue,
        IsShopDisabled: revenueInputItem.isDisabled,
      })))
      .reduce((revenueArr, sectionRevenueItem) => revenueArr.concat(sectionRevenueItem), []);
  },
  prepareRevenueData(revenueList) {
    return revenueList
      .map((revenueInputItem) => revenueInputItem.sections.map((shopSection) => ({
        revenueValue: shopSection.revenueValue ? parseCurrency(shopSection.revenueValue, { locale: i18n.locale }) : undefined,
        shopId: revenueInputItem.shopId,
        sectionId: shopSection.sectionId,
        shoppingCenterGroupId: revenueInputItem.shoppingCenterGroupId,
        chainShopId: revenueInputItem.chainShopId,
      })))
      .reduce((revenueArr, sectionRevenueItem) => revenueArr.concat(sectionRevenueItem), [])
      .filter((revenueInputItem) => (
        new RegExp(numberWithSeparatorsRegEx).test(revenueInputItem.revenueValue)
      ));
  },
  validateRevenueData(revenuesExternalFormat, revenuesInternalFormat) {
    const validationResult = {
      success: false,
      errors: [],
    };
    if (revenuesExternalFormat.length === 0) {
      validationResult.errors.push(this.createError('ErrorEmptyFile'));
    }
    const firstItem = revenuesExternalFormat[0];
    const mixedMonths = revenuesExternalFormat.find((item) => item.Month !== firstItem.Month || item.Year !== firstItem.Year);
    if (mixedMonths) {
      validationResult.errors.push(this.createError('ErrorMixedDates',
        `${mixedMonths.Month}-${mixedMonths.Year} | ${firstItem.Month}-${firstItem.Year}`,
        `${mixedMonths.ShopName},${firstItem.ShopName}`));
    }

    const outOfRangeMonth = revenuesExternalFormat.find((item) => item.Month > 12 || item.Month < 1);
    if (outOfRangeMonth) {
      validationResult.errors.push(this.createError('ErrorMonthOutOfRange', outOfRangeMonth.Month, `${outOfRangeMonth.ShoppingCenterName} ${outOfRangeMonth.ShopName}`));
    }

    const outOfRangeYear = revenuesExternalFormat.find((item) => item.Year < 2019 || item.Year >= 2100);
    if (outOfRangeYear) {
      validationResult.errors.push(this.createError('ErrorYearOutOfRange', outOfRangeYear.Year, `${outOfRangeYear.ShoppingCenterName} ${outOfRangeYear.ShopName}`));
    }
    if (revenuesExternalFormat.some(revenueItem => 'RevenueInteger' in revenueItem === false)) {
      validationResult.errors.push(this.createError('ErrorNoRevenue'));
    }
    if (revenuesExternalFormat.some(revenueItem => 'Year' in revenueItem === false)) {
      validationResult.errors.push(this.createError('ErrorNoYear'));
    }
    if (revenuesExternalFormat.some(revenueItem => 'Month' in revenueItem === false)) {
      validationResult.errors.push(this.createError('ErrorNoMonth'));
    }
    if (revenuesExternalFormat.some(revenueItem => 'ShopName' in revenueItem === false)) {
      validationResult.errors.push(this.createError('ErrorNoShopName'));
    }
    revenuesExternalFormat.forEach((revenueItem) => {
      const ShopFromSystem = shopsService.getShopFromCollection(revenuesInternalFormat, revenueItem);

      if (ShopFromSystem == null) {
        validationResult.errors.push(this.createError('ErrorShopNotFound',
          `${revenueItem.ShoppingCenterName || ''} ${revenueItem.ShopName || ''} ${revenueItem.SectionName || ''}`));
      }
      if (revenueItem.RevenueInteger === null || revenueItem.RevenueInteger === undefined) {
        validationResult.errors.push(this.createError('ErrorNullRevenueIntegerValue',
          `${revenueItem.ShoppingCenterName || ''} ${revenueItem.ShopName || ''} ${revenueItem.SectionName || ''}`));
      }

      if (revenueItem.RevenueInteger < 0) {
        validationResult.errors.push(this.createError('ErrorNegativeRevenueIntegerValue',
          `${revenueItem.ShoppingCenterName || ''} ${revenueItem.ShopName || ''} ${revenueItem.SectionName || ''}`));
      }
    });

    if (validationResult.errors.length === 0) {
      validationResult.success = true;
    }
    return validationResult;
  },
  revenueExistsForShop(shopId, sectionId) {
    const shops = store.getters.getRevenueIndication;
    if (shops && shops.length === 0) { return false; }
    const shop = shops?.data.find(
      (shop) => shop.shopId === shopId
        && shop.sectionIds.some((section) => section == sectionId),
    );
    return !!shop;
  },
  createError(errorTranslationKey, relatedParameterValues, identifierDescription) {
    return {
      failureReason: errorTranslationKey,
      relatedFailureParameter: relatedParameterValues,
      identifierDescription,
    };
  },
  getRevenueFileHeaderTranslation() {
    return [
      {
        key: "ShopName",
        header: i18n.tc("ShopName"),
      },
      {
        key: "ShoppingCenterName",
        header: i18n.tc("ShoppingCenterName"),
      },
      {
        key: "SectionId",
        header: i18n.tc("SectionId"),
      },
      {
        key: "SectionName",
        header: i18n.tc("SectionName"),
      },
      {
        key: "ChainShopId",
        header: `${i18n.tc("ChainShopId")} - ${i18n.tc("NotMandatory")}`,
      },
      {
        key: "Month",
        header: i18n.tc("Month"),
      },
      {
        key: "IsShopDisabled",
        header: i18n.tc("ShopDisabled"),
      },
      {
        key: "Year",
        header: i18n.tc("Year"),
      },
      {
        key: "RevenueInteger",
        header: i18n.tc("RevenueInteger"),
      },
    ];
  },
  isValueString(sheet,colNum,startingRow, endingRow){
    const refData = [];
    for (let row = startingRow + 1; row <= endingRow; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: colNum });
      refData.push(sheet[ref].v);
    }
    const stringValues = refData.map(value => value?.toString());
    const existingValues =  stringValues.filter(value => value);
    return existingValues.some(value => !textHelpers.numericValidator(value))
  },
  setColumnFormatByContent(column, sheet) {
    const stringFmt = '@';
    const totalColumns = sheet['!ref'];
    const colNum = XLSX.utils.decode_col(column);
    const range = XLSX.utils.decode_range(totalColumns);
    const startingRow = range.s.r;
    const endingRow = range.e.r;

    if(this.isValueString(sheet,colNum,startingRow, endingRow )){
      for (let row = startingRow + 1; row <= endingRow; ++row) {
        const ref = XLSX.utils.encode_cell({ r: row, c: colNum });
        if (!sheet[ref]) continue;
        if (sheet[ref].v === null) {
          sheet[ref].v = "";
          sheet[ref].t = "s";
        }
        sheet[ref].z = stringFmt;
      }
    }
    else{
      for (let row = startingRow + 1; row <= endingRow; ++row) {
        const ref = XLSX.utils.encode_cell({ r: row, c: colNum });
        if (!sheet[ref]) continue;
        sheet[ref].t = "n";
      }
    }
  },
  translateColumnValue(column, sheet) {
    const totalColumns = sheet['!ref'];
    const colNum = XLSX.utils.decode_col(column);
    const range = XLSX.utils.decode_range(totalColumns);
    const startingRow = range.s.r;
    const endingRow = range.e.r;
    for (let row = startingRow + 1; row <= endingRow; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: colNum });
      if (!sheet[ref]) continue;
      sheet[ref].t = "s";
      sheet[ref].v = sheet[ref].v == false ? i18n.tc("No") : i18n.tc("Yes")
    }
  },
  getIndexedHeaders(sheet){
    const headers = [];
    const totalColumns = sheet['!ref'];
    const range = XLSX.utils.decode_range(totalColumns);
    let column, row = range.s.r;
    const rangeEndColumnLetter = range.e.c;
    const rangeStartColumnLetter = range.s.c;
    for (column = rangeStartColumnLetter; column <= rangeEndColumnLetter; ++column) {
      const cellref = sheet[XLSX.utils.encode_cell({ c: column, r: row })]
      headers.push(cellref);
    }
    const alphabet = headers.concat().map((q, w) => String.fromCharCode(w + 97).toUpperCase());
    const indexedHeaders = headers.concat().map((element, index) => ({ ...element, adress: alphabet[index] }));
    return indexedHeaders
  },
  getColumnAdressByName(columnName, sheet){
    const translationName = this.getRevenueFileHeaderTranslation().find(translation => translation.key === columnName);
    const matchingHeader =  this.getIndexedHeaders(sheet).find(header => header.v === translationName.header);
    return matchingHeader?.adress;
  },
  generateFile(fileName, content) {
    if (content) {
      let translatedHeaders = this.getRevenueFileHeaderTranslation();
      content.forEach(i => {
        for (const [key] of Object.entries(i)) {
          const match = translatedHeaders.find(header => header.key == key);
          if (match && match.header !== key) {
            delete Object.assign(i, { [match.header]: i[key] })[key];
          }
        }
      })
      const workBook = XLSX.utils.book_new();
      const dataSheet = XLSX.utils.json_to_sheet(content);
      const chainShopIdCell = this.getColumnAdressByName("ChainShopId", dataSheet);
      const isShopDisabledCell = this.getColumnAdressByName("IsShopDisabled", dataSheet);
      this.setColumnFormatByContent(chainShopIdCell, dataSheet);
      this.translateColumnValue(isShopDisabledCell, dataSheet);
      XLSX.utils.book_append_sheet(workBook, dataSheet, "sheet");
      XLSX.writeFile(workBook, fileName);
    }
  },
  convertCellsToRows(sheet) {
    let rows = [];
    let row;
    let rowNum;
    let colNum;
    const range = XLSX.utils.decode_range(sheet['!ref']);
    const startingRow = range.s.r;
    const endingRow = range.e.r;
    const rangeEndColumnLetter = range.e.c;
    const rangeStartColumnLetter = range.s.c;
    for (rowNum = startingRow; rowNum <= endingRow; rowNum++) {
      row = [];
      for (colNum = rangeStartColumnLetter; colNum <= rangeEndColumnLetter; colNum++) {
        let nextCell = sheet[
          XLSX.utils.encode_cell({ r: rowNum, c: colNum })
        ];
        if (typeof nextCell === 'undefined') {
          row.push(void 0);
        } else row.push(nextCell.w);
      }
      rows.push(row);
    }
    return rows;
  },
  adaptFileReaderForIE(){
    if (FileReader.prototype.readAsBinaryString === undefined) {
      FileReader.prototype.readAsBinaryString = function (fileData) {
          var binary = "";
          var pt = this;
          var reader = new FileReader();
          reader.onload = function () {
              var bytes = new Uint8Array(reader.result);
              var length = bytes.byteLength;
              for (var i = 0; i < length; i++) {
                  binary += String.fromCharCode(bytes[i]);
              }
              pt.content = binary;
              pt.onload();
          }
          reader.readAsArrayBuffer(fileData);
      }
    }
  },
 transformFileToObject(file) {
   this.adaptFileReaderForIE();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = function (e) {
            let _data
            if (!e) {
              _data = reader.content;
            }
            else {
              _data = e.target.result;
            }
            resolve(_data)
        };
      reader.readAsBinaryString(file);
    }).then(data => {
      const isEmptyArr = (arr) => arr.filter(x => undefined !== x).length === 0
      const workBook = XLSX.read(data, { type: 'binary' });
      const sheet = workBook.Sheets[Object.keys(workBook.Sheets)[0]]
      const rows = this.convertCellsToRows(sheet).filter(row => !isEmptyArr(row));
      const headers = rows.shift().map((cell) => {
        return {
          heading: cell,
        };
      });
      headers.forEach((heading) => {
        let matchTranslation =  this.getRevenueFileHeaderTranslation().find(
          (i) => i.header === heading.heading?.trim()
        );
        heading.heading = matchTranslation?.key;
      });
      const mappedRows = rows
        .map((row) => {
          return headers.map((_header, index) => {
            const cell = row[index]
            return {
              [headers[index].heading]: cell ?? null,
            };
          });
        })
        .map((row) =>
          row.reduce(
            (obj, item) =>
              Object.assign(obj, {
                [Object.keys(item)[0]]: Object.values(item)[0],
              }),
            {}
          )
        );
      mappedRows.forEach((row) => {
        row.RevenueInteger ? row.RevenueInteger = this.transformRevenueValueToNumber(
          row.RevenueInteger
        ) : null;
      });
      mappedRows.forEach((row) => {
        row.SectionId ? row.SectionId = this.transformSectionToNumber(
          row.SectionId
        ) : null;
      });
      return mappedRows;
    });
  },
  transformRevenueValueToNumber(value) {
    if (value || value === 0) {
      var revenue = parseCurrency(value?.toString(), {
        locale: i18n.locale
      });
      return revenue;
    }
  },
  transformSectionToNumber(value){
    if(value){
      return parseInt(value);
    }
  }
};

export default revenueService;
