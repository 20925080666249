import Vue from 'vue';
import Buefy from 'buefy';
import i18n from '@/utils/i18n';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from "@sentry/browser";
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueCurrencyInput from 'vue-currency-input';

// internal icons
import { faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload } from "@fortawesome/free-solid-svg-icons";
library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle,
    faArrowUp, faAngleRight, faAngleLeft, faAngleDown,
    faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload);
// app icons
import {faFileDownload, faQuestionCircle, faFileUpload, faSearch, faPlusSquare, faPencilAlt, faFrownOpen, faPrint,
  faSmile, faCalendar, faCalendarDay, faTimes,  faEnvelope, faPhone} from  "@fortawesome/free-solid-svg-icons";
library.add(faFileDownload, faQuestionCircle, faFileUpload, faSearch, faPlusSquare, faPencilAlt, faFrownOpen, faPrint,
  faSmile, faCalendar, faCalendarDay, faTimes, faEnvelope, faPhone);

Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(Buefy, {defaultIconComponent: 'vue-fontawesome',
defaultIconPack: 'fas',
});
Sentry.init({
  Vue,
  dsn: 'https://2cc92036b7684d748495c87eeeecc81b@sentry.io/1724086',
  integrations: [
    new BrowserTracing({
      attachProps: true,
      logErrors: true,
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "platform.mallshoplink.com", /^\//]
     })],
  environment: process.env.VUE_APP_ENV,
});
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: 'NIS',
  },
});
let app = new Vue({
  store,
  i18n,
  router,
  VueCurrencyInput,
  render: (h) => h(App),
}).$mount('#app');

if (window.Cypress) {
  window.app = app
}
