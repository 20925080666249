const localStorageAccess = {
  saveRowsPerPage(identifier, value) {
    localStorage.setItem(`${identifier}RowsPerPage`, value);
  },
  getRowsPerPage(identifier) {
    return localStorage.getItem(`${identifier}RowsPerPage`);
  },
};

export default localStorageAccess;
