<template>
  <b-field :label="$t('MemberId')">
    <b-input
      :value="memberId ? memberId : $t('SystemGenerated')"
      disabled
    ></b-input>
  </b-field>
</template>
<script>
export default {
  props: { value: Number },
  data() {
    return {
      memberId: this.value
    };
  },
  watch: {
    memberId: {
      handler() {
        this.$emit("input", this.memberId);
      }
    }
  }
};
</script>
