<template>
  <div
  class="modal-card"
  data-test-id="chainCreationModal"
  >
    <header class="modal-card-head">
      <b-button icon-pack="fas" icon-left="times" @click="$parent.close()" />
    </header>
    <section class="modal-card-body">
      <chainEdit
        @chain-update-complete="chainCreated"
        @chain-update-cancelled="$parent.close"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t("Close") }}</b-button>
    </footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import chainEdit from "@/components/chainEdit.vue";
import shopsService from "@/services/shopsService";

export default {
  components: {
    chainEdit,
  },
  async mounted() {
    if (!this.chainsList) {
      await shopsService.getChains();
    }
  },
  computed: {
    ...mapGetters({
      chainsList: "getChains",
    }),
  },
  methods:{
      chainCreated(selectedChain){
            this.$emit("chain-created", selectedChain);
            this.$parent.close();
      }
  },
};
</script>