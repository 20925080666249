import auth0 from 'auth0-js';
import Axios from 'axios';
import * as Sentry from '@sentry/browser';
import config from '../config';
import store from '../store';

const webAuth = new auth0.WebAuth({
  domain: config.domain,
  redirectUri: `${window.location.origin}/callback`,
  clientID: process.env.VUE_APP_AUTH_CLIENT_ID,
  responseType: 'token id_token',
  scope: 'openid profile email',
  audience: 'https://api.mallshoplink.com/',
});

const loginStatusKey = 'loggedIn';
const loginNameKey = 'loggedInName';
const dataFromTokenKey = 'dataFromToken';
const tokenLoginPath = '/auth/login';
const tokenLogoutPath = '/auth/logout';

class AuthService {
  idToken = null;

  accessToken = null;

  profile = null;

  tokenExpiry = null;

  accessTokenExpiry = null;

  login(customState) {
    webAuth.authorize({
      appState: customState,
    });
  }

  sendEmailLoginLink(email) {
    return new Promise((resolve, reject) => {
      webAuth.passwordlessStart({
        connection: 'email',
        send: 'link',
        email,
      }, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  sendSmsLoginCode(phoneNumber) {
    return new Promise((resolve, reject) => {
      webAuth.passwordlessStart({
        connection: 'sms',
        send: 'code',
        phoneNumber,
      }, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  loginWithSms(phoneNumber, code) {
    return new Promise((resolve, reject) => {
      webAuth.passwordlessLogin(
        {
          connection: 'sms',
          phoneNumber,
          verificationCode: code,
        },
        (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        },
      );
    });
  }

  logout() {
    localStorage.removeItem(loginStatusKey);

    this.idToken = null;
    this.accessToken = null;
    this.tokenExpiry = null;
    this.profile = null;
    this.accessTokenExpiry = null;
    this.isAlternateToken = false;

    store.commit('resetState');
    if (this.isAlternateLogin()) {
      this.logoutAlternate();
    } else {
      webAuth.logout({
        returnTo: `${window.location.origin}`,
      });
    }
     localStorage.removeItem(loginNameKey);
      localStorage.removeItem(loginStatusKey);
  }

  logoutAlternate() {
    return Axios.post(process.env.VUE_APP_REVENUE_API_URL + tokenLogoutPath, null, { withCredentials: true });
  }

  handleAuthentication(alternateToken, memberId) {
    return new Promise((resolve, reject) => {
      if (alternateToken && memberId) {
        localStorage.setItem(loginStatusKey, 'alternate');
        Axios.post(process.env.VUE_APP_REVENUE_API_URL + tokenLoginPath,
          { token: alternateToken, identifier: memberId },
          { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
          .then(() => {
            this.localLogin({ memberId });
            sessionStorage.setItem(dataFromTokenKey, alternateToken.substr(0, 6));
            resolve({ memberId });
          })
          .catch((err) => {
            Sentry.captureException(err);
            if (this.isAuthenticated()) {
              this.logout();
            }

            reject(err);
          });
      } else {
        webAuth.parseHash((err, authResult) => {
          if (err) {
            Sentry.captureException(err);
            reject(err);
          } else {
            this.logoutAlternate().then(() => {
              this.localLogin(authResult);
              resolve(authResult);
            });
          }
        });
      }
    });
  }

  isAuthenticated() {
    if (localStorage.getItem(loginNameKey)) {
      this.profile = JSON.parse(localStorage.getItem(loginNameKey));

    }
    if (this.isAlternateLogin()) {
      store.commit('setAuthenticatedUser', { user: this.profile });
      return true;
    }
    return (
      localStorage.getItem(loginStatusKey) === 'true'
    );
  }

  isIdTokenValid() {
    return (
      this.idToken
      && this.tokenExpiry
      && Date.now() < this.tokenExpiry
    );
  }

  isAccessTokenValid() {
    return (
      this.accessToken
      && this.accessTokenExpiry
      && Date.now() < this.accessTokenExpiry
    );
  }

  getUser() {
    return this.profile;
  }

  getAccessToken() {
    return new Promise((resolve) => {
      if (this.isAccessTokenValid()) {
        resolve(this.accessToken);
      } else if (this.isAlternateLogin() === false) {
        this.renewTokens().then((authResult) => {
          resolve(authResult.accessToken);
        }, () => {});
      }
    });
  }

  localLogin(authResult) {
    if (authResult.memberId) {
      this.profile = { name: authResult.memberId, expires: new Date(Date.now() + 1000 * 60 * 60) };
      localStorage.setItem(loginStatusKey, 'alternate');
      localStorage.setItem(loginNameKey, JSON.stringify(this.profile));
      store.commit('setAuthenticatedUser', { user: this.profile });
    } else {
      this.idToken = authResult.idToken;
      this.profile = authResult.idTokenPayload;
      this.accessToken = authResult.accessToken;

      // Convert the expiry time from seconds to milliseconds,
      // required by the Date constructor
      this.tokenExpiry = new Date(this.profile.exp * 1000);

      // Convert expiresIn to milliseconds and add the current time
      // (expiresIn is a relative timestamp, we want an absolute time)
      this.accessTokenExpiry = new Date(Date.now() + authResult.expiresIn * 1000);
      sessionStorage.removeItem(dataFromTokenKey);
      localStorage.setItem(loginStatusKey, 'true');
      store.commit('setAuthenticatedUser', { user: authResult.idTokenPayload });
    }
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      const loginStatus = localStorage.getItem(loginStatusKey);
      if (loginStatus !== 'true') {
        this.isAuthenticated();
        reject('Not logged in');
      } else {
        webAuth.checkSession({}, (err, authResult) => {
          if (err) {
            Sentry.withScope((scope) => {
              scope.setExtra('authResult', authResult);
              scope.setExtra(loginStatusKey, loginStatus);
              Sentry.captureException(err);
            });
            this.logout();
            reject(err);
          } else if (loginStatus !== 'true') {
            reject(`Not logged in. loginStatus is ${loginStatus}`);
          } else {
            this.localLogin(authResult);
            resolve(authResult);
          }
        });
      }
    });
  }

  isAlternateLogin() {

    if (this.profile && this.profile.expires && Date.now() > this.profile.expires) {
      localStorage.removeItem(loginStatusKey);
      localStorage.removeItem(loginNameKey);
    }
    return localStorage.getItem(loginStatusKey) === 'alternate';
  }

  async loginExpiration() {
    try {
      var isloginAlternate = this.isAlternateLogin()
      if (isloginAlternate) {
        let loginStorageKey = localStorage.getItem(loginNameKey);
        let storageObj = JSON.parse(loginStorageKey);
        var msToExpire = storageObj.expires;
      } else {  
        let accessToken = await this.getAccessToken()
        let parsedToken = JSON.parse(atob(accessToken.split('.')[1]));
        msToExpire = parsedToken.exp * 1000;
      }
      return new Date(msToExpire);
    } catch(e) { 
      Sentry.withScope((scope) => {
        scope.setExtra('isAlternateLogin', isloginAlternate);
        Sentry.captureException(e);
      });
    }
  }

}

const AuthEngine = new AuthService();
export default AuthEngine;
