import request from '@/utils/request';
import store from '@/store';
import validationUtil from "@/utils/validationUtil";

const notificationService = {
  async getNotificationGroups() {
    const path = '/notification/GetNotificationGroups';
    const result = await request.get(process.env.VUE_APP_REVENUE_API_URL + path);

    const filteredResult = result.map(shop => {
      if (shop.shopIds[0] == 0) shop.shopIds.pop();
      return shop;
    }).filter(shop => shop.groupType !== 'ShoppingCenter');

    store.commit('setNotificationGroups', {
      notificationGroups: filteredResult,
    });
  },
  async postGroup(body) {
    const path = '/notification/UpdateNotificationGroup';
    const result = await request.post(process.env.VUE_APP_REVENUE_API_URL + path, body);
    return result;
  },
  validateGroupFields(fileds) {
    const filedsValidationResult = validationUtil.checkFieldValidity(fileds);
    const errors = [];
    if(!filedsValidationResult){
      errors.push('FormInvalid');
    }
    return errors;
  },
  getStrategies() {
    const result = [
        { name: "Level1", id: 1 },
        { name: "Level2", id: 2 },
        { name: "Level3", id: 3 },
        { name: "NoNotifications", id: 9 },
    ];
    store.commit('setStrategies', {
      strategies: result,
    });
    return result;
  },
  canCreateGroup(notificationsGroupList) {
    return notificationsGroupList?.some(group => group.canUpdateNotificationGroup);
  },
  prepareNotificationDetails(notificationGroupList, memberList) {
    if (notificationGroupList) {
      const distinctGroupList = [];
      notificationGroupList.forEach(element => {
        const relatedMembers = memberList?.filter(
          x => x.groupId == element.groupId
        );
        const groupExists = distinctGroupList.find(
          g => g.groupId === element.groupId
        );
        const relatedShops = element.shopIds
          ? element.shopIds.map(shopId => ({ shopId: shopId }))
          : [];
        if (groupExists === undefined) {
          distinctGroupList.push({
            groupId: element.groupId,
            groupName: element.groupName,
            groupDescription: element.groupDescription,
            canUpdateNotificationGroup: element.canUpdateNotificationGroup,
            shopIds: relatedShops,
            members: relatedMembers ?? 0
          });
        }
      });
      return distinctGroupList;
    } else {
      return [];
    }
  },
 addNewGroup(groupToAdd) {
   store.commit('createGroup', { newGroup: groupToAdd });
  },
};

export default notificationService;
