import Vue from 'vue';
import Router from 'vue-router';
import AuthEngine from '@/plugins/msal';
import ShopRevenueInput from './views/ShopRevenueInput.vue';
import HomePage from './views/HomePage.vue';
import AuthCallback from './components/AuthCallback.vue';
import ContactUs from './views/ContactUs.vue';
import MemberAddUpdate from './views/Admin/MemberAddUpdate';
import ShopAdministration from './views/Admin/ShopAdministration';
import ShopAdministrationEdit from './views/Admin/ShopAdministrationEdit';
import RevenueResponseModal from './components/revenueResponse/RevenueResponseModal';
import MemberAdministration from './views/Admin/MemberAdministration';
import NotificationGroupAdministration from './views/Admin/NotificationGroupAdministration';
import NotificationGroupsUpdate from './views/Admin/NotificationGroupsUpdate';
import ChainAdministration from './views/Admin/ChainAdministration';
import ChainAdministrationEdit from './views/Admin/ChainAdministrationEdit';
import termsAndConditions from './views/TermsAndConditions.vue';
import SectionAdministration from './views/Admin/SectionAdministration.vue';
import SectionsAdministrationEdit from './views/Admin/SectionsAdministrationEdit.vue'

Vue.use(Router);

function memberAdminEditProps(route){
return {id: parseInt(route.params.id), returnTo: route.params.returnTo}
}
function groupAdminEditProps(route) {
  return { id: parseInt(route.params.id), returnTo: route.params.returnTo }
}
function shopAdminEditProps(route) {
  return { id: parseInt(route.params.id), returnTo: route.params.returnTo }
}
function chainAdminEditProps(route){
  return { id: parseInt(route.params.id), returnTo: route.params.returnTo }
}
function sectionAdminEditProps(route){
  return { id: parseInt(route.params.id), returnTo: route.params.returnTo }
}
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'homepage',
      component: HomePage,
    },
    {
      path: '/login',
      name: 'login',
      component: HomePage,
    },
    {
      path: '/shops',
      name: 'shopRevenue',
      component: ShopRevenueInput,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/contactus',
      component: ContactUs,
    },
    {
      path: '/callback',
      name: 'callback',
      component: AuthCallback,
    },
    {
      path: '/shops/submitSummary',
      name: 'submitSummary',
      component: RevenueResponseModal,
      props: true,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/shops/admin',
      name: 'shopAdmin',
      component: ShopAdministration,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/shops/admin/edit/:id',
      name: 'shopAdminEdit',
      component: ShopAdministrationEdit,
      props: shopAdminEditProps,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/shops/admin/create',
      name: 'shopAdminCreate',
      component: ShopAdministrationEdit,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/members/admin',
      name: 'memberAdmin',
      component: MemberAdministration,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/members/admin/edit/:id',
      name: 'memberAdminEdit',
      component: MemberAddUpdate,
      props: memberAdminEditProps,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/members/admin/create',
      name: 'memberAdminCreate',
      component: MemberAddUpdate,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/notificationGroup/admin',
      name: 'notificationGroupAdmin',
      component: NotificationGroupAdministration,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/notificationGroup/admin/edit/:id',
      name: 'notificationGroupAdminEdit',
      component: NotificationGroupsUpdate,
      props: groupAdminEditProps,
      meta: {
        authorize: true,
      },
    },
    {
      path: '/tandc',
      name: 'termsAndConditions',
      component : termsAndConditions
    },
    {
      path:'/chains/admin',
      name: 'ChainAdministration',
      component: ChainAdministration,
      meta:{
        authorize: true,
      },
    },
    {
      path:'/chains/admin/edit/:id',
      name:'ChainAdministrationEdit',
      component: ChainAdministrationEdit,
      props: chainAdminEditProps,
      meta:{
        authorize: true,
      },
    },
    {
      path:'/chains/admin/create',
      name:'ChainAdministrationCreate',
      component:ChainAdministrationEdit,
      meta:{
        authorize: true
      },
    },
    {
      path:'/sections/admin',
      name:'SectionsAdministration',
      component:SectionAdministration,
      meta:{
        authorize: true,
      },
    },
    {
      path:'/sections/admin/edit/:id',
      name:'SectionsAdministrationEdit',
      component: SectionsAdministrationEdit,
      props: sectionAdminEditProps,
      meta:{
        authorize: true,
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authorize) && !(AuthEngine.isAuthenticated())) {
    next({
      path: '/login',
      params: { target: to.fullPath },
    });
  }
  next();
});

export default router;
