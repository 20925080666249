const textHelpers = {
  getPascalCase(string) {
    return `${string}`
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
        new RegExp(/\s+(.)(\w+)/, 'g'),
        ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`,
      )
      .replace(new RegExp(/\s/, 'g'), '')
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  },
  groupBy(list, key) {
    return list.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },
  emailValidator(email) {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  },
  numericValidator(value){
    const re = /^(0|[1-9][0-9]*)$/;
    return re.test(value);
  }
};

export default textHelpers;
