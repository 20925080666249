<template>
  <b-navbar
   class="navbar"
   >
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/tandc' }">
        <img src="/assets/logo_horizontal.png" alt="MallShopLink logo" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item v-if="user" tag="router-link" :to="{ path: '/Shops' }"
      :active="path == '/Shops'">{{
        $t("ImportRevenues")
      }}</b-navbar-item>
      <b-navbar-item
        v-if="user"
        tag="router-link"
        :to="{ path: '/Shops/Admin' }"
        :active="path == '/Shops/Admin'"
        >{{ $t("Shops") }}</b-navbar-item>
        <b-navbar-item
        v-if="user"
        tag="router-link"
        :to="{ path: '/Members/Admin' }"
        :active="path == '/Members/Admin'"
        >{{ $t("Members") }}</b-navbar-item
      >
      <b-navbar-item
        v-if="user"
        tag="router-link"
        :to="{ name: 'notificationGroupAdmin' }"
        :active="path == '/notificationGroup/admin'"
        >{{ $t("NotificationGroups") }}</b-navbar-item
      >
      <b-navbar-item
        v-if="user"
        tag="router-link"
        :to="{ name: 'SectionsAdministration' }"
        :active="path == '/sections/admin'"
        >{{ $t("Sections") }}</b-navbar-item
      >
        <b-navbar-item
        v-if="user"
        tag="router-link"
        :to="{ path: '/Chains/Admin' }"
        :active="path == '/Chains/Admin'"
        >{{ $t("Chain") }}</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/ContactUs' }"
      :active="path == '/ContactUs'">{{
        $t("ContactUs")
      }}</b-navbar-item>
    </template>
    <template slot="end">
      <localeSwitch/>
      <b-navbar-item tag="div" v-if="user">{{ user.name }}</b-navbar-item>
      <b-navbar-item  v-if="!user" tag="div">
        <b-button
          class="loginButton"
          data-test-id="loginButton"
          @click="login"
        >
          <strong>{{ $t("SignIn") }}</strong>
        </b-button>
      </b-navbar-item>
      <b-navbar-item>
        <b-button
          class="logoutButton"
          type="is-text"
          data-test-id="logoutButton"
          @click="logout"
          v-if="user"
        >
          <strong>{{ $t("LogOut") }}</strong>
        </b-button>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import AuthEngine from "@/plugins/msal";
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/browser";
import localeSwitch from "@/components/localeSwitch";

export default {
  name: "NavigationBar",
  props: {
    // msg: String,
  },
  components:{
    localeSwitch
  },
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    login() {
      AuthEngine.login();
    },
    logout() {
      AuthEngine.logout();
    }
  },
  computed: {
    ...mapGetters({
      user: "getAuthenticatedUser"
    }),
    path() {
      return this.$route && this.$route.path;
    }
  },
  watch: {
    user: {
      handler() {
        if (this.user) {
          Sentry.setUser({
            username: this.user.name
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
//logout button color fx on a darker background added to avoid bringing !important that comes with is-white class
//to easily change the color on hover to a darker
.navbar .logoutButton.is-text{
  color: white;
}
.navbar .navbar-item:hover .logoutButton{
  color: inherit;
}
//end logout btn color fx

//start overflow treat on smaller dispays
@media screen and(min-width:1024px) and(max-width:1260px){
  .navbar{
   flex-wrap: wrap;
    .navbar-item{
     padding: 0.5rem;
    }
  }
  .navbar-brand{
   flex-basis: 100%;
   flex-grow: 1;
    a{
    margin: 0 auto;
    }
  }
  .navbar-menu {
    flex-grow: 1;
    flex-basis: 100%;
  }
}
//end overflow treat on smaller dispays

//start rtl -ltr mirroring fx
@media screen and(min-width:1024px){
 .navbar .navbar-start,
 .navbar .navbar-end{
   margin: 0;
  }
  .navbar-menu{
   justify-content: space-between;
  }
}
@media screen and(max-width:1024px){
  .navbar-brand{
    justify-content: space-between;
    .burger{
      margin: 0;
    }
  }
}
//end rtl -ltr mirroring fx
</style>
