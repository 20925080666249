<template>
  <section>
    <b-loading :is-full-page="true" :active="isLoading" :can-cancel="false"></b-loading>
    <b-notification
      :active="isError"
      indefinite="true"
      type="is-danger"
      role="alert"
      :message="error"
      :closable="false"
    ></b-notification>
  </section>
</template>

<script>
import AuthEngine from '@/plugins/msal';
import * as Sentry from '@sentry/browser';

export default {
  methods: {},
  computed: {
    isError() {
      return this.error.length > 0;
    },
  },
  mounted() {
    let { token, memberId, t, m } = this.$route.query;
    token ??= t;
    memberId ??= m;
    if (
      token === undefined
      && memberId === undefined
      && AuthEngine.isAuthenticated()
    ) {
      this.$router.push('/');
      return;
    }
    AuthEngine.handleAuthentication(token, memberId)
      .then((authResult) => {
        Sentry.setUser({ username: authResult.memberId || authResult.idTokenPayload });
        if (authResult.appState) {
          this.$router.push(authResult.appState.target || '/');
        } else {
          this.$router.push('/shops');
        }
      })
      .catch((e) => {
        this.isLoading = false;
        this.error = this.$t('AuthGeneralError');
        Sentry.captureException(e);
      });
  },
  data() {
    return {
      isLoading: true,
      error: '',
    };
  },
};
</script>

<style>
.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.media-content {
  text-align: center;
}
</style>
