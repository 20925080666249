<template>
  <div 
  class="modal-card" 
  style="width: 100%"
  data-test-id="notificationGroupsModal"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("RelevantGroups", {callerEntityDetails: this.titleString}) }}</p>
      <b-button icon-pack="fas" icon-left="times" @click="$parent.close()" />
    </header>
    <section class="modal-card-body">
      <notificationGroupDetails
      :notificationGroups="this.groupsList"
      :showRelatedItems="this.showRelatedItems"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t("Close") }}</b-button>
    </footer>
  </div>
</template>
<script>
import notificationGroupDetails from "@/components/notifications/notificationGroupDetails";

export default {
  components: {
    notificationGroupDetails
  },
  props: ["groupsList", "showRelatedItems", "titleString"]
};
</script>
