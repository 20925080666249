<template>
  <section>
    <b-table
      :data="shopsData"
      :columns="shopsColumns"
      default-sort="shoppingCenterGroupName"
      default-sort-direction="asc"

    ></b-table>
  </section>
</template>
<script>
export default {
  props: ['shops'],
  data() {
    return {
      shopsColumns: [
        {
          cellClass: 'has-text-right',
          field: 'shoppingCenterGroupName',
          label: this.$t('ShoppingCenterGroupName'),
          sortable: true,
        },
        {
          cellClass: 'has-text-right',
          field: 'shoppingCenterName',
          label: this.$t('ShoppingCenterName'),
          sortable: true,
        },
        {
          cellClass: 'has-text-right',
          field: 'shopName',
          label: this.$t('ShopName'),          
          sortable: true,
        },
        {
          cellClass: 'has-text-right',
          field: 'sectionName',
          label: this.$t('SectionName'),
          sortable: true,
        },
        {
          cellClass: 'has-text-right',
          field: 'revenueValue',
          label: this.$t('Revenue'),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    shopsData() {
      const flattenShops = this.shops
        .map((shop) => shop.sections
          .filter((section) => section.updateSucceed === true)
          .map((shopSection) => ({
            shoppingCenterGroupName: shop.shoppingCenterGroupName,
            shoppingCenterName: shop.shoppingCenterName,
            shopName: shop.shopName,
            sectionName: shopSection.sectionName,
            revenueValue: shopSection.revenueValue,
          })))
        .reduce((acc, val) => acc.concat(val), []);

      return flattenShops;
    },
  },
};
</script>
