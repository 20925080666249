<template>
	<b-field :label="$t('MemberEmail')">
		<b-input
			expanded
			type="email"
			v-model="memberEmail"
			:placeholder="canEdit ? $t('MemberEmailInput') : ''"
			:disabled="canEdit === false"
			:validation-message="$t('FieldIsInvalid', { fieldName: $t('MemberEmail') })"
			data-test-id="memberEmail"
			ref="memberEmail"
		></b-input>
	</b-field>
</template>
<script>
export default {
	props: ["value", "canEdit"],
	data() {
		return {
			memberEmail: this.value,
		};
	},
	watch: {
		memberEmail: {
			handler() {
				this.$emit("input", this.memberEmail);
			},
		},
	},
	methods:{
		checkHtml5Validity(){
			return this.$refs.memberEmail.checkHtml5Validity();
		}
	},
};
</script>
