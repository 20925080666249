<template>
  <div>
    <div class="hero has-text-centered">
      <h1 class="is-big title">
        <p>{{ title }}</p>
      </h1>
      <b-button @click="printPage" class="no-print" type="is-primary">
        <b-icon pack="fas" icon="print"></b-icon>
      </b-button>
    </div>
    <div class="no-print">
      <b-collapse
        v-if="this.showConflicts"
        class="card"
        animation="slide"
        :open="isOpen === 'conflicts' || isOpen === 'all'"
        @open="isOpen = 'conflicts'"
      >
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
          <p class="card-header-title">{{ $t('ConflictedRevenueHeader') }}</p>
          <a class="card-header-icon">
            <b-icon pack="fas" :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <conflictList :conflicts="shoppingCentersList" />
        </div>
      </b-collapse>
      <b-collapse
        v-if="this.showMissing"
        class="card"
        animation="slide"
        :open="isOpen === 'missingRevenues' || isOpen === 'all'"
        @open="isOpen = 'missingRevenues'"
      >
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
          <p class="card-header-title">{{ $t('MissingRevenueHeader') }}</p>
          <a class="card-header-icon">
            <b-icon pack="fas" :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <missingRevenuesList :missingRevenues="shoppingCentersList" />
        </div>
      </b-collapse>
      <b-collapse
        v-if="this.showSucceed"
        class="card"
        animation="slide"
        :open="isOpen === 'succeed' || isOpen === 'all'"
        @open="isOpen = 'succeed'"
      >
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
          <p class="card-header-title">{{ $t('SuccessRevenueHeader') }}</p>
          <a class="card-header-icon">
            <b-icon pack="fas" :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
          </a>
        </div>
        <div class="card-content">
          <successList :shops="shoppingCentersList" />
        </div>
      </b-collapse>
      <footer class="hero no-print">
        <b-button type="is-primary" @click="closeResponseModal">{{ $t('Close') }}</b-button>
      </footer>
    </div>
    <div class="print">
      <div v-if="this.showConflicts">
        <h1 class="title">{{ $t('ConflictedRevenueHeader') }}</h1>
        <conflictList :conflicts="shoppingCentersList" />
      </div>
      <div v-if="this.showMissing">
        <h1 class="title">{{ $t('MissingRevenueHeader') }}</h1>
        <missingRevenuesList :missingRevenues="shoppingCentersList" />
      </div>
      <div v-if="this.showSucceed">
        <h1 class="title">{{ $t('SuccessRevenueHeader') }}</h1>
        <successList :shops="shoppingCentersList" />
      </div>
    </div>
  </div>
</template>
<script>
import conflictList from '@/components/revenueResponse/ConflictList.vue';
import successList from '@/components/revenueResponse/SuccessList.vue';
import missingRevenuesList from '@/components/revenueResponse/MissingRevenues.vue';
import revenueService from '@/services/revenueService';

export default {
  props: ['shoppingCentersList', 'date'],
  components: {
    conflictList,
    successList,
    missingRevenuesList,
  },
  // Because the filter is done inside the specific list then the visibility is always true
  computed: {
    title() {
      let revenueMonth = '';
      if (this.date) {
        revenueMonth = this.$t('MonthNames')[this.date.getMonth()];
      }
      return `${this.$t('RevenueResponseSummaryHeader')} ${revenueMonth}`;
    },
    conflicts() {
      return this.shoppingCentersList;
    },
    succeeded() {
      return this.shoppingCentersList;
    },
    missingRevenues() {
      return this.shoppingCentersList;
    },
    showConflicts() {
      if (this.shoppingCentersList == null) return false;
      return this.shoppingCentersList.some((shop) => shop.sections.some((section) => section.updateSucceed === false));
    },
    showMissing() {
      if (this.shoppingCentersList == null) return false;
      return this.shoppingCentersList.some((shop) => shop.sections.some(
        (section) => revenueService.revenueExistsForShop(shop.shopId, shop.sectionId)
              === false && section.updateSucceed !== true,
      ));
    },
    showSucceed() {
      if (this.shoppingCentersList == null) return false;
      return this.shoppingCentersList.some((shop) => shop.sections.some((section) => section.updateSucceed === true));
    },
  },
  methods: {
    closeResponseModal() {
      revenueService.postRevenueDone();
      this.$router.push('/shops');
    },
    printPage() {
      window.print();
    },
  },
  data() {
    return {
      isOpen:''
    };
  },
  mounted() {
    if (this.shoppingCentersList == null) {
      this.$router.push({ name: 'shopRevenue' });
    }
    this.isOpen = this.showConflicts ? 'conflicts': 'succeed';
  },
};
</script>
<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print {
    display: block !important;
  }
}

.print {
  display: none;
}
</style>
