<template>
  <div class="top10">
    <div class="columns is-centered">
      <div class="column is-half"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>
<style lang="less">
.top10 {
  margin-top: 10px;
}
</style>
