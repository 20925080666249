<template>
  <section>
    <b-button
      size="is-small"
      type="is-primary"
      @click="downloadFile"
      :disabled="disabled"
    >
      <b-icon icon="file-download"></b-icon>
      <span>{{ $t("DownloadTemplate") }}</span>
    </b-button>
  </section>
</template>
<script>
import revenueService from "@/services/revenueService";

export default {
  props: ["content", "fileName", "disabled"],
  methods: {
    downloadFile() {
      revenueService.generateFile(this.fileName, this.content);
    },
  },
};
</script>
