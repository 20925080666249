<template>
  <form class="container">
      <areYouSureBox
        has-modal-card
        :active="this.showAreYouSure"
        @response="areYouSureResponseHandler"
      />
      <b-field :label="$t('GroupId')">
        <b-input
          :value="this.isNew ? $t('SystemGenerated') : selectedGroup.groupId"
          disabled
        />
      </b-field>
      <b-field :label="$t('GroupName')">
        <b-input
          required
          expanded
          v-model="selectedGroup.groupName"
          :placeholder="canEditNotificationGroup ? $t('GroupNameInput') : ''"
          :validation-message="
            $t('FieldIsRequired', { fieldName: $t('GroupName') })
          "
          data-test-id="groupNameInput"
          :disabled="canEditNotificationGroup === false"
          ref="groupName"
        />
      </b-field>
      <b-field :label="$t('GroupDescription')">
        <b-input
          expanded
          v-model="selectedGroup.groupDescription"
          :placeholder="
            canEditNotificationGroup ? $t('GroupDescriptionInput') : ''
          "
          :disabled="canEditNotificationGroup === false"
          data-test-id="groupDescriptionInput"
        />
      </b-field>
      <b-field grouped position="is-right">
        <b-field>
          <b-button @click="cancelUpdate">
            {{ canEditNotificationGroup ? $t("Cancel") : $t("Return") }}
          </b-button>
        </b-field>
        <b-field v-if="canEditNotificationGroup">
          <b-button
            @click="startProcessing"
            :disabled="isProcessing"
            data-test-id="groupDetailsSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
        </b-field>
      </b-field>
  </form>
</template>
<script>
import * as Sentry from "@sentry/browser";
import { mapGetters } from "vuex";
import areYouSureBox from "@/components/AreYouSureBox";
import notificationService from "@/services/notificationService";

export default {
  props: ["id", "canEditNotificationGroup", "shopId"],
  components: {
    areYouSureBox,
  },
  data() {
    return {
      selectedGroup: {},
      originalGroup: {},
      showAreYouSure: false,
      isNew: false,
      processing: false,
      groupUpdated: false,
    };
  },
  mounted() {
    if (this.notificationGroupList === null) {
      notificationService.getNotificationGroups();
    }
  },
  computed: {
    isProcessing() {
      return this.processing;
    },
    ...mapGetters({
      notificationGroupList: "getNotificationGroups",
    }),
  },
  methods: {
    async areYouSureResponseHandler(action) {
      if (this.showAreYouSure === true) {
        this.showAreYouSure = false;
        try {
          if (action === "yes") {
            await this.sendUpdate();
          }
        } finally {
          this.processing = false;
        }
      }
    },
    sendUpdate() {
      const distinctGroup = {
        roleId: this.selectedGroup.groupId,
        roleName: this.selectedGroup.groupName,
        roleDescription: this.selectedGroup.groupDescription ?? "",
      };
      notificationService
        .postGroup(distinctGroup)
        .then((result) => {
          const groupId = result.request.response;
          this.groupUpdated = true;
          if (this.isNew) {
            this.selectedGroup.groupId = parseInt(groupId);
            this.selectedGroup.groupType = "Shop";
            this.selectedGroup.shopIds = [this.shopId ?? 0];
            notificationService.addNewGroup(this.selectedGroup);
          }
          this.openNotification(this.$t("SuccessGroupUpdated"), "is-success");
          this.$emit("group-update-complete");
          return Promise.resolve("done");
        })
        .catch((error) => {
          if (error?.response && error.response.status === 409) {
            Object.getOwnPropertyNames(error.response.data?.errors).forEach(
              (err) => {
                this.openNotification(this.$t(err), "is-danger");
              }
            );
          } else {
            this.openNotification(this.$t("ErrorContactUs"), "is-danger");
            Sentry.captureException(error);
          }
          return Promise.resolve("done");
        });
    },
    cacheOriginalGroup(selectedGroup) {
      if (selectedGroup) {
        this.originalGroup = { ...selectedGroup };
      }
    },
    openNotification(message, type) {
      let autoCloseProps = {};
      if (type === "is-success") {
        autoCloseProps = {
          autoClose: true,
          duration: 5000,
        };
      }
      this.$buefy.notification.open({
        message,
        type,
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        ...autoCloseProps,
        "data-test-id": "groupValidationMessage",
      });
    },
    revertToOriginalGroup() {
      Object.assign(this.selectedGroup, this.originalGroup);
    },
    resetGroup() {
      (this.selectedGroup = {}),
        ((this.isNew = false), (this.groupUpdated = false));
    },
    cancelUpdate() {
      this.$emit("cancel-update");
    },

    startProcessing() {
        if (this.processing === false) {
            this.processing = true;
            const validationResults = notificationService.validateGroupFields([this.$refs.groupName]);
            if (validationResults.length > 0) {
                this.processing = false;
                const translatedErrors = validationResults
                .map((validator) => this.$t(validator))
                .join(", ");
                this.openNotification(translatedErrors, "is-danger");
            } else {
             this.showAreYouSure = true;
            }
        }
    },
    createNewGroup() {
      this.isNew = true;
      this.selectedGroup = {
        groupId: 0,
        groupName: "",
        groupDescription: "",
      };
    },
  },
  beforeDestroy() {
    if (!this.groupUpdated) {
      this.revertToOriginalGroup();
    }
    this.resetGroup();
  },
  watch: {
    notificationGroupList: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.id) {
          this.selectedGroup = this.notificationGroupList
            ? this.notificationGroupList.find((x) => x.groupId == this.id)
            : [];
          this.cacheOriginalGroup(this.selectedGroup);
        } else {
          this.createNewGroup();
        }
      },
    },
  },
};
</script>