<template>
		<b-navbar-dropdown
		data-test-id="localeSwitch"
    class="locale_switch"
    :label="$i18n.locale"
		collapsible
		hoverable
    >
		<b-navbar-item
		@click="switchLanguage"
		href="#"
		>
		en
		</b-navbar-item>
		<b-navbar-item
		data-test-id="localeHe"
		@click="switchLanguage"
		>
		he
		</b-navbar-item>
	</b-navbar-dropdown>
</template>
<script>
export default {
	methods: {
		switchLanguage(e){
			e.preventDefault()
			let clickedValue = e.currentTarget.textContent.trim();
			this.$i18n.switchLocale(clickedValue);
		}
	},
};
</script>
<style lang="scss">
@import "@/assets/styles/_variables.scss";

.locale_switch {
  text-transform: uppercase;
}
.navbar .navbar-link:not(.is-arrowless)::after {
	border-color: white;
}
.locale_switch.navbar-item.has-dropdown:focus .navbar-link:not(.is-arrowless)::after,
.locale_switch.navbar-item.has-dropdown:hover .navbar-link:not(.is-arrowless)::after,
.locale_switch.navbar-item.has-dropdown.is-active .navbar-link:not(.is-arrowless)::after{
	border-color: $darkBlue;
}
@media screen and(min-width:1024px){
	.locale_switch .navbar-item{
		color: $darkBlue
	}
}
@media screen and(max-width:1024px){
	.locale_switch .button.is-text {
		color: white
	}
}

</style>
