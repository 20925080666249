<template>
  <section class="section">
    <div class="column is-half container">
      <b-field expanded>
        <b-input
          expanded
          :disabled="this.shouldDisplayLoading"
          :loading="this.shouldDisplayLoading"
          v-model="searchText"
          icon-pack="fas"
          icon="search"
          :placeholder="$t('Search')"
          data-test-id="sectionsSearch"
        />
      </b-field>
    </div>
    <div class="container">
      <sectionsDetails
        :sectionsList="this.filteredSectionsList"
        :loading="this.shouldDisplayLoading"
        :canEdit="this.canCreateShops"
      />
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import shopsService from "@/services/shopsService";
import sectionsDetails from "@/components/sectionsDetails";
export default {
  components: {
    sectionsDetails,
  },
  mounted() {
    if (!this.shopsList) {
      shopsService.getAllowedShopsList();
    }
    if (!this.sectionsList) {
      shopsService.getSections();
    }
  },
  data() {
    return {
      searchText: "",
    };
  },
  computed: {
    ...mapGetters({
      sectionsList: "getSections",
      shopsList: "getAllowedShopsList",
    }),
    shouldDisplayLoading() {
      return !this.sectionsList;
    },
    shoppingCenterGroupList() {
      let map = [];
      if (this.shopsList) {
        map = [
          ...new Map(
            this.shopsList.map((shop) => [shop.shoppingCenterGroupName, shop])
          ).values(),
        ];
      }
      return map;
    },
    sectionsListDetails() {
      return shopsService.prepareSectionDetails(
        this.sectionsList,
        this.shoppingCenterGroupList
      );
    },
    filteredSectionsList() {
      return this.sectionsListDetails
        ? this.sectionsListDetails.filter((section) =>
            this.searchText
              .toUpperCase()
              .split(/[ ]+/u)
              .filter((el) => el)
              .every((phase) =>
                section.sectionName
                  .concat(section.sectionId)
                  .concat(section.sectionExternalId)
                  .concat(section.shoppingCenterGroupName)
                  .toUpperCase()
                  .includes(phase)
              )
          )
        : [];
    },
    canCreateShops() {
      return shopsService.canCreateShops(this.shopList);
    },
  },
};
</script>