<template>
  <div class="modal-card" style="width: 100%">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("RelevantMembers", {callerEntityDetails: this.titleString}) }}</p>
      <b-button icon-pack="fas" icon-left="times" @click="$parent.close()" />
    </header>
    <section class="modal-card-body">
      <memberAdministrationDetails
        :membersList="this.membersList"
        :loading="this.loading"
        :showRelatedItems="false"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t("Close") }}</b-button>
    </footer>
  </div>
</template>
<script>
import memberAdministrationDetails from "@/components/members/memberAdministrationDetails";

export default {
  components: {
    memberAdministrationDetails
  },
  props: ["membersList", "loading", "titleString"]
};
</script>