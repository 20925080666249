<template>
  <div
  class="modal-card"
  style="width: 100%"   
  data-test-id="shopsDetailsModal"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("RelevantShops", {callerEntityDetails: this.titleString}) }}</p>
      <b-button icon-pack="fas" icon-left="times" @click="$parent.close()" />
    </header>
    <section class="modal-card-body">
      <shopAdministrationDetails
        :shopsList="this.shopsList"
        :loading="this.loading"
        :showRelatedItems="false"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t("Close") }}</b-button>
    </footer>
  </div>
</template>
<script>
import shopAdministrationDetails from "@/components/shopAdministrationDetails";

export default {
  components: {
    shopAdministrationDetails
  },
  props: ["shopsList", "loading", "titleString"]
};
</script>