<template>
  <div>
    <areYouSureBox
      has-modal-card
      :active="this.showAreYouSure"
      @response="areYouSureResponseHandler"
    />
    <div class="container columns">
      <form class="column is-two-fifths">
        <b-field :label="$t('SectionId')">
          <b-input :value="this.sectionIdValue" disabled />
        </b-field>
        <b-field :label="$t('SectionName')">
          <b-input
            required
            expanded
            v-model="selectedSection.sectionName"
            :placeholder="$t('SectionNameInput')"
            :validation-message="
              $t('FieldIsRequired', { fieldName: $t('SectionName') })
            "
            :disabled="canEditShop === false"
            data-test-id="sectionNameInput"
            ref="sectionName"
          />
        </b-field>
        <b-field :label="$t('SectionExternalId')">
          <b-input
            required
            expanded
            v-model="selectedSection.sectionExternalId"
            :placeholder="$t('SectionExternalIdInput')"
            :validation-message="
              $t('FieldIsRequired', { fieldName: $t('SectionExternalId') })
            "
            :disabled="canEditShop === false"
            data-test-id="sectionExternalIdInput"
            ref="sectionExternalId"
          />
        </b-field>
        <b-field grouped position="is-right">
          <b-field>
            <b-button @click="cancelUpdate">
              {{ canEditShop ? $t("Cancel") : $t("Return") }}
            </b-button>
          </b-field>
          <b-field v-if="canEditShop">
            <b-button
              @click="startProcessing"
              :disabled="isProcessing"
              data-test-id="sectionDetailsSubmit"
            >
              {{ $t("Submit") }}
            </b-button>
          </b-field>
        </b-field>
      </form>
    </div>
  </div>
</template>
<script>
import * as Sentry from "@sentry/browser";
import areYouSureBox from "@/components/AreYouSureBox";
import shopsService from "@/services/shopsService";
import { mapGetters } from "vuex";

export default {
  // Need to handle undefined/null props
  props: ["shoppingCenterGroupId", "canEditShop", "id"],
  components: {
    areYouSureBox,
  },
  data() {
    return {
      selectedSection: {},
      originalSection: {},
      isNew: false,
      processing: false,
      showAreYouSure: false,
      sectionUpdated: false,
    };
  },
  async mounted() {
    if (this.id) {
      if (!this.sectionsList) {
        await shopsService.getSections();
      }
      this.getSectionToEdit();
    } else {
      this.createselectedSection();
    }
  },
  computed: {
    isProcessing() {
      return this.processing;
    },
    ...mapGetters({
      sectionsList: "getSections",
    }),
    sectionIdValue() {
      return this.isNew
        ? this.$t("SystemGenerated")
        : this.selectedSection.sectionId;
    },
  },
  methods: {
    createselectedSection() {
      this.selectedSection = {
        sectionId: 0,
        sectionName: this.selectedSection?.sectionName || "",
        shoppingCenterGroupId: this.shoppingCenterGroupId,
        sectionExternalId: this.selectedSection?.sectionExternalId || "",
      };
      this.isNew = true;
    },
    startProcessing() {
      if (this.processing === false) {
        this.processing = true;
        const validationResults = shopsService.validateSectionData([
          this.$refs.sectionExternalId,
          this.$refs.sectionName,
        ]);
        if (validationResults.length > 0) {
          this.processing = false;
          const translatedErrors = validationResults
            .map((validator) => this.$t(validator))
            .join(", ");
          this.openNotification(translatedErrors, "is-danger");
        } else {
          this.showAreYouSure = true;
        }
      }
    },
    async areYouSureResponseHandler(action) {
      if (this.showAreYouSure === true) {
        this.showAreYouSure = false;
        try {
          if (action === "yes") {
            await this.sendUpdate();
          }
        } finally {
          this.processing = false;
        }
      }
    },
    openNotification(message, type) {
      let autoCloseProps = {};
      if (type === "is-success") {
        autoCloseProps = {
          autoClose: true,
          duration: 5000,
        };
      }
      this.$buefy.notification.open({
        message,
        type,
        hasIcon: true,
        indefinite: true,
        closeable: true,
        position: "is-top",
        ...autoCloseProps,
        "data-test-id": "sectionValidationMessage",
      });
    },
    sendUpdate() {
      shopsService
        .postSection(this.selectedSection)
        .then((result) => {
          this.sectionUpdated = true;
          if (this.isNew) {
            this.selectedSection.sectionId = result.data;
            shopsService.createSection(this.selectedSection);
          }
          this.openNotification(this.$t("SuccessSectionUpdated"), "is-success");
          return Promise.resolve("done");
        })
        .then(() => {
          this.sectionUpdateComplete();
        })
        .catch((error) => {
          if (error?.response && error.response.status === 409) {
            Object.getOwnPropertyNames(error.response.data?.errors).forEach(
              (err) => {
                this.openNotification(this.$t(err), "is-danger");
              }
            );
          } else {
            this.openNotification(this.$t("ErrorContactUs"), "is-danger");
            Sentry.captureException(error);
          }
          return Promise.resolve("done");
        });
    },
    sectionUpdateComplete() {
      if (this.isNew) {
        this.$emit("section-create-completed", this.selectedSection);
      } else {
        this.$emit("section-update-completed", this.selectedSection);
      }
    },
    cancelUpdate() {
      if (this.isNew) {
        this.$emit("section-create-canceled");
      } else {
        this.$emit("section-update-canceled");
      }
    },
    getSectionToEdit() {
      this.selectedSection = this.sectionsList
        ? this.sectionsList.find((section) => section.sectionId === this.id)
        : {};
      this.originalSection = { ...this.selectedSection };
    },
    revertToOriginalSection() {
      Object.assign(this.selectedSection, this.originalSection);
    },
    resetSection() {
      this.selectedSection = {};
      this.sectionUpdated = false;
      this.isNew = false;
    },
  },
  beforeDestroy() {
    if (!this.sectionUpdated) {
      this.revertToOriginalSection();
    }
    this.resetSection();
  },
};
</script>
