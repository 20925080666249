<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <b-button icon-pack="fas" icon-left="times" @click="$parent.close()" />
    </header>
    <section class="modal-card-body">
      <notificationGroupsEdit
        @group-update-complete="$parent.close"
        :canEditNotificationGroup="this.canCreateNotificationGroups"
        @cancel-update="$parent.close"
        :shopId="this.id"
      />
    </section>
    <footer class="modal-card-foot">
      <b-button @click="$parent.close()">{{ $t("Close") }}</b-button>
    </footer>
  </div>
</template>
<script>
import notificationService from "@/services/notificationService";
import notificationGroupsEdit from "@/components/notifications/notificationGroupsEdit.vue"
import { mapGetters } from "vuex";
export default {
  props: {
    id: Number
  },
  components:{
    notificationGroupsEdit
  },
  mounted() {
    if (
      this.notificationGroupList === null ||
      this.notificationGroupList.length === 0
    ) {
      notificationService.getNotificationGroups();
    }
  },
  computed: {
    ...mapGetters({
      notificationGroupList: "getNotificationGroups"
    }),
    canCreateNotificationGroups() {
      return notificationService.canCreateGroup(this.notificationGroupList);
    }
  }
};
</script>