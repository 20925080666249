<template>
  <b-table
    :data="this.sectionsList"
    :striped="true"
    :loading="loading"
    :paginated="showPagination"
    :per-page="rowsPerPage"
    default-sort="sectionId"
    default-sort-direction="asc"
    pagination-position="both"
    height="65vh"
    focusable
    scrollable
    sticky-header
  >
    <b-table-column
      v-slot="props"
      sortable
      field="sectionId"
      :label="$t('SectionId')"
      centered
    >
      {{ props.row.sectionId }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="SectionExternalId"
      :label="$t('SectionExternalId')"
      centered
    >
      {{ props.row.sectionExternalId }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="sectionName"
      :label="$t('SectionName')"
      centered
    >
      {{ props.row.sectionName }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      sortable
      field="shoppingCenterGroupName"
      :label="$t('ShoppingCenterGroupName')"
      centered
    >
      {{ props.row.shoppingCenterGroupName }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      :label="$t('Edit')"
      :visible="this.canEdit"
      centered
    >
      <b-button
        type="is-warning"
        icon-pack="fas"
        icon-right="pencil-alt"
        tag="router-link"
        :to="{
          name: `SectionsAdministrationEdit`,
          params: { id: props.row.sectionId, returnTo: $route.path },
        }"
      />
    </b-table-column>
  </b-table>
</template>
<script>
import localStorageAccess from "@/resourceAccess/localStorageAccess";

export default {
  props: ["sectionsList", "loading", "canEdit"],
  data() {
    return {
      rowsPerPage: 10,
    };
  },
  mounted() {
    this.rowsPerPage =
      localStorageAccess.getRowsPerPage("sectionsList") || this.rowsPerPage;
  },
  computed: {
    showPagination() {
      return this.sectionsList ? this.sectionsList.length > 10 : null;
    },
  },
};
</script>