<template>
    <div id="app" :class="this.appClass" :dir="this.direction" >
    <NavigationBar />
    <main>
      <b-dialog
      v-if="shouldShowExpireUserMessage"
      role="alert"
      type="is-white"
      :canCancel='false'
      :message="$t('LogoutWarningText')"
      :confirmText="$t('LogoutWarningAction')"
      class="dialog-warning"
      data-test-id="logoutWarningMessage"
    />
      <router-view class="view"></router-view>
    </main>
  </div>
</template>

<script>
import AuthEngine from '@/plugins/msal';
import NavigationBar from './components/NavigationBar.vue';
 import { mapGetters } from "vuex";
 
export default {
  name: 'app',
  components: {
    NavigationBar,
  },
  created() {
    if (this.$route.path.toLowerCase() !== '/callback') {
      AuthEngine.renewTokens().catch(() => {});
    }
    // Supress the 'not logged in' error as we can illegitimately get that
    // when processing the callback url
  },
  computed: {
    appClass() {
      return process.env.VUE_APP_ENV.concat('Bg');
    },
    direction(){
       return this.$i18n.isLocale('he') ? 'rtl' : 'ltr'
    },
  ...mapGetters({
   userMsToExpire: 'getUserMsToExpire'
  }),
  shouldShowExpireUserMessage() {
    let showMessage = false;
    let remainingMsForLogoutWarning = 5 * 60 * 1000;
    let msToLogoutWarning = (this.userMsToExpire - remainingMsForLogoutWarning) ;
    if (this.userMsToExpire && msToLogoutWarning <= 0) {
      showMessage = true;
    }
    return (showMessage) ? true : false;
    }
  },
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
@import "@/assets/styles/_variables.scss";

$primary: $darkBlue;
$primary-invert: findColorInvert($primary);
$blue: $lightBlue;
$blue-invert: findColorInvert($blue);
$info: $lightBlue;
$info-invert: findColorInvert($info);
$red: $deepRed;
$red-invert: findColorInvert($red);
$green: $defaultGreen;
$green-invert: findColorInvert($green);
$danger: $deepRed;
$danger-invert: findColorInvert($danger);
$twitter: $twitterBlue;
$twitter-invert: findColorInvert($twitter);
$navbar-background-color: $primary;
$navbar-item-color: $lightGray;
$navbar-item-active-background-color: $lightGray;
$modal-background-background-color: $darkGray;
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  ),
  "red": (
    $red,
    $red-invert
  ),
  "blue": (
    $blue,
    $blue-invert
  ),
  "green": (
    $green,
    $green-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
//tablet breakpoint extention
$tablet:1024px;
// Import Bulma and Buefy styles
@import "~bulma";
@import "@/assets/styles/rightToLeft.scss";
@import "~buefy/src/scss/buefy";
@import "@/assets/styles/main.scss";

.developmentBg{
  background-color: moccasin;
}

.stagingBg{
  background-color: magenta;
}

  .dialog-warning{
    .modal-card {
      background: $red;
      .modal-card-foot , .modal-card-body{
        background: transparent;
      }
      .modal-card-body{
        color: white;
        padding: 20px 20px 0 20px;
        font-size: 1.15em;
      }
      .modal-card-foot{
        justify-content: center;
        border: 0;
      }
      button{
       border-radius: 0;
      }
    }
  }
@media screen and(max-width:1024px){
  .dialog-warning .modal-card{
    max-width: 90%;
  }
}
</style>
