<template>
	<b-field :label="$t('NotificationGroups')" v-if="showComponent" data-test-id="NotificationGroupsMultiContainer">
		<b-taginput
			ref="taginput"
			v-model="selectedGroups"
			:data="filteredGroupList"
			field="groupDisplay"
			autocomplete
			:allow-new="false"
			:open-on-focus="true"
			:closable="canEdit"
			icon-pack="fas"
			icon="search"
			:placeholder="canEdit ? $t('ChooseGroup') : ''"
			@remove="onGroupRemoved"
			:disabled="canEdit === false"
			@typing="getFilteredGroupList"
			data-test-id="NotificationGroupsMulti"
			:loading="this.shouldDisplayLoading"
		>
			<template slot="header">
				<a
					class="has-text-info is-size-6"
					@click="createGroup"
					data-test-id="groupCreation"
				>
					{{ $t("CreateGroup") }}
				</a>
			</template>
			<template slot-scope="props">
				<strong>{{ props.option.groupId }}</strong>
				: {{ props.option.groupName }}
			</template>
			<template slot="empty">{{ $t("NoItemsToShow") }}</template>
			<template slot="selected" slot-scope="props">
				<b-tag
					v-for="(tag, index) in props.tags"
					:key="index"
					:type="getType(tag)"
					ellipsis
					closable
					@close="$refs.taginput.removeTag(index, $event)"
				>
					<strong>{{ tag.groupId }}</strong>
					: {{ tag.groupName }}
				</b-tag>
			</template>
		</b-taginput>
	</b-field>
</template>
<script>
import { mapGetters } from "vuex";
import notificationService from "@/services/notificationService";

export default {
	props: ["value", "canEdit", "editableTypes"],
	mounted() {
		if (
			this.notificationGroupList === null ||
			this.notificationGroupList.length === 0
		) {
			notificationService.getNotificationGroups();
		}
	},
	data() {
		return {
			searchText: "",
		};
	},
	computed: {
		...mapGetters({
			notificationGroupList: "getNotificationGroups",
		}),
		selectedGroups: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
		filteredGroupList() {
			let filteredGroups = this.groupsList?.filter(
				(option) =>
					option.groupName
						.toString()
						.concat(option.groupId.toString())
						.toLowerCase()
						.indexOf(this.searchText.toLowerCase()) >= 0
			);
			return filteredGroups ?? this.groupsList;
		},
		shouldDisplayLoading() {
			return (
				!Array.isArray(this.notificationGroupList) ||
				!this.notificationGroupList.length
			);
		},
		groupsList() {
			if (this.notificationGroupList) {
				const distinctGroupList = [];
				this.notificationGroupList.forEach((element) => {
					const groupExists = distinctGroupList.find(
						(g) => g.groupId === element.groupId
					);
					if (groupExists === undefined) {
						distinctGroupList.push({
							groupId: element.groupId,
							groupName: element.groupName,
							groupType: "Shop",
							groupDisplay: element.groupId
								.toString()
								.concat(" ")
								.concat(element.groupName),
						});
					}
				});
				return distinctGroupList.filter(
					(group) =>
						this.selectedGroups.some((g) => g.groupId === group.groupId) ===
						false
				);
			}
			return [];
		},
		showComponent() {
			return this.selectedGroups.length > 0 || this.canEdit;
		},
	},
	methods: {
		getFilteredGroupList(text) {
			this.searchText = text;
		},
		onGroupRemoved(value) {
			if (this.editableTypes.some((t) => t === value.groupType) === false) {
				this.$emit("unauthorize", value);
				this.selectedGroups.unshift(value);
			}
		},
		getType(tag) {
			if (tag.groupType === "Shop") {
				return "is-blue";
			}
			if (tag.groupType === "Chain") {
				return "is-green";
			}
			if (tag.groupType === "ShoppingCenter") {
				return "is-red";
			}
			return "is-primary";
		},
		createGroup() {
			this.$emit("create-new-group");
		},
	},
};
</script>
