<template>
	<b-table
		:data="this.chainsList"
		:striped="true"
		:loading="loading"
		:paginated="showPagination"
		:per-page="rowsPerPage"
		pagination-position="both"
		default-sort="chainName"
		default-sort-direction="asc"
		height=65vh
		focusable
		scrollable
		sticky-header
	>
		<b-table-column
			v-slot="props"
			sortable
			field="chainId"
			:label="$t('ChainId')"
			centered
		>
			{{ props.row.chainId }}
		</b-table-column>
		<b-table-column
			v-slot="props"
			sortable
			field="chainName"
			:label="$t('ChainName')"
			centered
		>
			{{ props.row.chainName }}
		</b-table-column>
		<b-table-column
			v-slot="props"
			sortable
			field="shopsRelated"
			:label="$t('Shops')"
			:visible="this.showRelatedItems"
			centered
		>
			<b-button 
			type="is-text"
			@click="showRelatedShops(props.row.chainId)"
			data-test-id="chainShopsCount"
			>
				{{ props.row.shopsRelated.length }}
			</b-button>
		</b-table-column>
		<b-table-column
			v-slot="props"
			sortable
			field="groupsRelated"
			:label="$t('NotificationGroups')"
			:visible="this.showRelatedItems"
			centered
		>
			<b-button
				type="is-text"
				@click="showRelatedGroups(props.row.chainId)"
				data-test-id="chainGroupsCount"
			>
				{{ props.row.groupsRelated.length }}
			</b-button>
		</b-table-column>
		<b-table-column
			v-slot="props"
			:label="$t('Edit')"
			:visible="this.canEdit"
			centered
		>
			<b-button
				type="is-warning"
				icon-pack="fas"
				icon-right="pencil-alt"
				tag="router-link"
				:to="{
					name: `ChainAdministrationEdit`,
					params: { id: props.row.chainId, returnTo: $route.path },
				}"
			/>
		</b-table-column>
		<template slot="top-left">
			<b-field :label="$t('ShopsPerPage')" type="is-blue">
				<b-select v-model="rowsPerPage" expanded @input="setPageSize">
					<option value="10">10</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="75">75</option>
					<option value="100">100</option>
				</b-select>
			</b-field>
		</template>
		<template slot="empty">
			<div v-if="this.loading" class="content has-text-grey has-text-centered">
				<p>{{ $t("Loading") }}</p>
			</div>
			<div v-else class="content has-text-grey has-text-centered">
				<p><b-icon pack="fas" icon="frown-open" size="is-large" /></p>
				<p>{{ $t("NoDataToShow") }}</p>
			</div>
		</template>
	</b-table>
</template>
<script>
import localStorageAccess from "@/resourceAccess/localStorageAccess";
export default {
	props: ["chainsList", "loading", "showRelatedItems", "canEdit"],
	mounted() {
		this.rowsPerPage =
			localStorageAccess.getRowsPerPage("chainsList") || this.rowsPerPage;
	},
	data() {
		return {
			rowsPerPage: 10,
		};
	},
	computed: {
		showPagination() {
			return this.chainsList ? this.chainsList.length > 10 : null;
		},
	},
	methods: {
		setPageSize() {
			localStorageAccess.saveRowsPerPage("chainsList", this.rowsPerPage);
		},
		showRelatedShops(chainId) {
			this.$emit("shop-count-clicked", chainId);
		},
		showRelatedGroups(chainId) {
			this.$emit("notification-group-count-clicked", chainId);
		},
	},
};
</script>
