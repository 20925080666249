<template>
		<b-field
		:label="label"
		:type="this.errorMessage !== '' ? 'is-danger' : ''" 
		:message="this.errorMessage"
		>
		<b-input
		data-test-id="phoneNumberInput"
		expanded
		v-model="phoneNumber"
		:placeholder="canEdit ? placeholder : ''"
		:disabled="canEdit === false"
		/>
		</b-field>
</template>
<script>
import {
	AsYouType,
	parsePhoneNumberFromString,
  isValidPhoneNumber
} from "libphonenumber-js/mobile";

export default {
	props: ["value", "canEdit", "label", "placeholder"],
	data() {
		return {
			phoneNumberValue: this.canEdit ? parseInt(this.value): this.value,
		};
	},		
	computed: {
		errorMessage(){
			if (this.canEdit === false) {
				return ""
			}
      const isValid =  this.phoneNumber ?  isValidPhoneNumber(this.phoneNumber, "IL") : null;
			if(isValid === false){
				return this.$t('FieldIsInvalid', { fieldName: this.label });
			}else{
				return ""
			}
		},
		phoneNumber: {
			get() {
				if (this.phoneNumberValue && this.canEdit) {
					const asYouType = new AsYouType("IL");
					asYouType.input(this.phoneNumberValue.toString());
					return asYouType.getNumber().formatNational();
				}
				return this.phoneNumberValue  ?? 0;
			},
			set(editedPhoneNumber) {
				editedPhoneNumber = parsePhoneNumberFromString(editedPhoneNumber, "IL");
				this.phoneNumberValue = editedPhoneNumber ? editedPhoneNumber.number : 0;
			},
		},
	},
	watch: {
		phoneNumberValue: {
			handler() {
				this.$emit("input", parseInt(this.phoneNumberValue));
			},
		},
	},
};
</script>
