<template>
  <b-field class="file">
    <b-tooltip :label="$t('UploadFileToolTip')" type="is-info">
      <b-button size="is-small" @click="openHelpPage">
        <b-icon icon="question-circle"></b-icon>
      </b-button>
    </b-tooltip>
    <b-upload
      v-model="file"
      @input="revenueData"
      data-test-id="fileAttachtButton"
      :disabled="disabled"
      accept=".xlsx"
      expanded
    >
      <a class="button is-primary is-small">
        <b-icon icon="file-upload"></b-icon>
        <span>{{ $t("ClickToUpload") }}</span>
      </a>
    </b-upload>
    <span class="file-name" v-if="file">{{ file.name }}</span>
  </b-field>
</template>
<script>
import revenueService from "@/services/revenueService";

export default {
  props: ["shopsList", "disabled"],
  data() {
    return {
      file: null,
      fileContent: [],
      errors: [],
    };
  },

  methods: {
    revenueData() {
      if (this.file) {
        revenueService.transformFileToObject(this.file).then((data) => {
          this.setFileContent(data);
        });
      } else {
        this.errors.push("No File");
        this.fileContent = [];
      }
    },
    setFileContent(result) {
      this.fileContent = result;
    },
    setErrors(error) {
      this.errors.push(error);
    },
    validateRevenueData(revenues) {
      const validationResult = revenueService.validateRevenueData(
        revenues,
        this.shopsList
      );
      if (validationResult.success === false) {
        this.errors.push(validationResult.errors);
      }
      return validationResult.success;
    },
    openHelpPage() {
      window.open(
        "https://www.mallshoplink.com/report-via-file?lang=he",
        "_blank"
      );
    },
  },
  watch: {
    fileContent: {
      handler() {
        if (this.fileContent) {
          if (this.validateRevenueData(this.fileContent)) {
            this.$emit("file-uploaded", this.fileContent);
          } else {
            this.file = null;
            this.$emit("error", this.errors);
            this.errors = [];
          }
        }
      },
    },
  },
};
</script>

