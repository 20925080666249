<template>
  <section>
    <b-table
      :data="conflictsData"
      :columns="conflictColumns"
      default-sort="shoppingCenterGroupName"
      default-sort-direction="asc"
      :row-class="(row, index) => 'is-danger'"

    ></b-table>
  </section>
</template>
<script>
export default {
  props: ['conflicts'],
  data() {
    return {
      conflictColumns: [
        {
          field: 'shoppingCenterGroupName',
          label: this.$t('ShoppingCenterGroupName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'shoppingCenterName',
          label: this.$t('ShoppingCenterName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'shopName',
          label: this.$t('ShopName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'sectionName',
          label: this.$t('SectionName'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'revenueValue',
          label: this.$t('Revenue'),
          cellClass: 'has-text-right',
          sortable: true,
        },
        {
          field: 'failureReason',
          label: this.$t('Reason'),
          cellClass: 'has-text-right',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    conflictsData() {
      const flattenConflicts = this.conflicts
        .map((shop) => shop.sections
          .filter((section) => section.updateSucceed === false)
          .map((shopSection) => ({
            shoppingCenterGroupName: shop.shoppingCenterGroupName,
            shoppingCenterName: shop.shoppingCenterName,
            shopName: shop.shopName,
            sectionName: shopSection.sectionName,
            revenueValue: shopSection.revenueValue,
            failureReason: this.$t(shopSection.failureReason, {
              relatedFailureParameter: shopSection.relatedFailureParameter,
              identifierDescription:
                    `${shop.shoppingCenterName} - ${shop.shopName}`,
            }),
          })))
        .reduce((acc, val) => acc.concat(val), []);
      return flattenConflicts;
    },
  },
};
</script>
<style lang="scss">
tr.is-danger {
        background: #FF5758;
        color: #fff;
    }
</style>
